import { CustomVariable, MultiValueVariable, sceneGraph, SceneObject, SceneVariable } from '@grafana/scenes';
import { DetectState, DetectStateObject } from './stateObject';
import { VariableHide } from '@grafana/schema';

export function getDetectStores(sceneObject: SceneObject): DetectState {
  const node = sceneGraph.findObject(sceneObject, (obj) => obj instanceof DetectStateObject);
  return node?.useState() as DetectState;
}

export const searchParams = () => {
  return new URLSearchParams(window.location.search);
};

export const getUrlSearchParam = (name: string): string => {
  const val = searchParams().get(`var-${name}`);

  return val ?? '';
};
