import { Configuration as DetectServiceConfiguration } from '../api/detect-service';
import { PLUGIN_ID } from './constants';
import { Configuration as SigmaAPIConfiguration } from '../api/sigma-api';

export const DetectServiceConfig = new DetectServiceConfiguration({
  basePath: `/api/plugins/${PLUGIN_ID}/resources/service/v1/db`,
});

export const SigmaAPIConfig = new SigmaAPIConfiguration({
  basePath: `/api/plugins/${PLUGIN_ID}/resources/sigma`,
});
