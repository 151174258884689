import React, { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import { AsyncSelect, FieldSet, InlineField } from '@grafana/ui';
import { AlertingConfig } from 'shared/alertingTypes';
import { SelectableValue } from '@grafana/data';
import { FolderListResponse } from 'shared/requests/alerts';

export type AlertingFormProps = {
  alertingConfig: AlertingConfig;
  setAlertingConfig: Dispatch<SetStateAction<AlertingConfig>>;
  listFolders: (search: string) => Promise<Array<FolderListResponse>>;
};

export const AlertingForm = (props: AlertingFormProps) => {
  const { alertingConfig, setAlertingConfig, listFolders } = props;

  const [value, setValue] = useState<SelectableValue<string>>({
    value: alertingConfig.folderUid,
    label: alertingConfig.namespace,
  });
  const [defaultOptions, setDefaultOptions] = useState<Array<SelectableValue<string>>>([]);
  const loadFolders = async (search: string): Promise<Array<SelectableValue<string>>> => {
    const folders = await listFolders(search);
    return folders.map((folder) => ({ value: folder.uid, label: folder.title }));
  };

  // Added this effect due to some flaky behavior with the 11.1.0 update
  // this means that we manually fetch the default options when the component mounts
  useEffect(() => {
    listFolders('').then((folders) => {
      setDefaultOptions(folders.map((folder) => ({ value: folder.uid, label: folder.title })));
    });
  }, [listFolders]);

  return (
    <FieldSet label="Grafana integration">
      <InlineField
        label="Folder/Namespace"
        htmlFor="select-folder"
        grow
        tooltip={
          <>The Grafana folder/namespace the alert rules and dashboards created via this plugin will be stored in</>
        }
      >
        <AsyncSelect
          loadOptions={(search) => loadFolders(search)}
          defaultOptions={defaultOptions}
          inputId="select-folder"
          value={value}
          onChange={(v) => {
            setValue(v);
            setAlertingConfig((config) => ({
              ...config,
              ...{ namespace: v.label, folderUid: v.value },
            }));
          }}
        />
      </InlineField>
    </FieldSet>
  );
};
