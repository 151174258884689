import { css } from '@emotion/css';
import { Alert, Button, MultiSelect, Modal, useStyles2 } from '@grafana/ui';
import React, { useState } from 'react';

export interface LabelSelectorModalProps {
  labels?: Array<string> | null;
  isOpen: boolean;
  onSelect: (selectLabels: Array<string>) => void;
  onCancel: () => void;
  onDismiss: () => void;
}

const getStyles = () => ({
  modal: css`
    overflow-wrap: break-word;
  `,
});

export const LabelSelectorModal = (props: LabelSelectorModalProps) => {
  const styles = useStyles2(getStyles);
  const [values, setValues] = useState<Array<string>>([]);
  const options = props.labels?.map((label) => ({ label, value: label })) ?? [];

  return (
    <Modal
      className={styles.modal}
      title="Select labels to create aggregate panels by"
      isOpen={props.isOpen}
      onDismiss={props.onDismiss}
      onClickBackdrop={props.onDismiss}
    >
      <>
        <p>
          Select labels to create a row of panels to see how the query works if aggregated by the selected labels. Leave
          empty and just click on Select if you don&apos;t want aggregates.
        </p>
        <Alert title="Warning" severity="warning" key="warning">
          This will <b>overwrite</b> an existing dashboard with the same name.
        </Alert>
        <MultiSelect
          options={options}
          value={values}
          onChange={(v) => {
            setValues(v.map((item) => item.value ?? ''));
          }}
        />
      </>
      <Modal.ButtonRow>
        <Button variant="secondary" fill="outline" onClick={props.onCancel}>
          Cancel
        </Button>
        <Button onClick={() => props.onSelect(values)}>Select</Button>
      </Modal.ButtonRow>
    </Modal>
  );
};
