import { getBackendSrv } from '@grafana/runtime';
import { useGrafanaState } from 'hooks/useGrafanaState';
import { DetectSettings } from 'pages/AppConfig';
import { useCallback } from 'react';
import {
  AccessPolicyToken,
  Configured0,
  Configured1,
  Configured2,
  DEFAULT_RULES_FOLDER_NAME,
  GrafanaServiceAccountToken,
  PLUGIN_ID,
} from 'shared/constants';
import { DashboardCreateResponse } from 'shared/requests/alerts';
import { installAlertingDashboardPanels } from './dashboards/alertingDashboard';

interface Folder {
  uid: string;
  id: number;
  title: string;
}

/**
 * ensureFolderExists ensures that folder exists, creates it if it doesn't and returns the UID
 *
 * @param {string} folderName The name of the folder to check for
 */
export const ensureFolderExists = async (folderName: string) => {
  const folders = await getBackendSrv().get<Array<Folder>>(`/api/search?query=${folderName}&type=dash-folder`, {}, '', {
    showErrorAlert: true,
  });
  if (folders.filter((folder) => folder.title === folderName).length > 0) {
    return folders[0].uid;
  } else {
    const newFolder = await getBackendSrv().post<Folder>('/api/folders', {
      title: folderName,
    });
    return newFolder.uid;
  }
};

const createAlertingDashboard = async (folderUid: string, incidentDSUid?: string, alertingHistoryDSUid?: string) => {
  const mainDashboard = await getBackendSrv().post<DashboardCreateResponse>('/api/dashboards/db', {
    dashboard: {
      title: 'Security Metrics',
      editable: true,
      refresh: '',
      schemaVersion: 0,
      version: 0,
      panels: installAlertingDashboardPanels(folderUid, incidentDSUid, alertingHistoryDSUid),
      time: {
        from: 'now-90d',
        to: 'now',
      },
    },
    folderUid: folderUid,
    message: 'Initial version of Grafana Detect main dashboard',
    overwrite: false,
  });
  return mainDashboard.uid;
};

export const useDoInstall = (
  serviceURL: string,
  sigmaURL: string,
  serviceAccessToken?: string,
  incidentDSUid?: string,
  alertingHistoryDSUid?: string,
  existingAlertingDashboard?: string
) => {
  const [hasAuth, , , getToken, getServiceAccountToken] = useGrafanaState();

  /**
   * _doInstall is the internal function that does the actual installation of the plugin
   * @param token - Optional Cloud Access Policy token for interacting with the Cloud components of Grafana Cloud
   */
  const _doInstall = useCallback(
    (token?: string, serviceAccountToken?: string) => {
      ensureFolderExists(DEFAULT_RULES_FOLDER_NAME).then(async (folderUID) => {
        // TODO: enable updating an existing dashboard
        const alertingDashboardUid =
          existingAlertingDashboard ?? (await createAlertingDashboard(folderUID, incidentDSUid, alertingHistoryDSUid));
        getBackendSrv()
          .post(`/api/plugins/${PLUGIN_ID}/settings`, {
            enabled: true,
            jsonData: {
              service: {
                url: serviceURL,
              },
              sigmaAPIConfig: {
                url: sigmaURL,
              },
              alerting: {
                folderUid: folderUID,
                namespace: DEFAULT_RULES_FOLDER_NAME,
              },
              dashboardConfig: {
                incidentDSUid: incidentDSUid,
                alertingHistoryDSUid: alertingHistoryDSUid,
                alertingDBUid: alertingDashboardUid,
              },
            } as DetectSettings,
            secureJsonData: {
              [AccessPolicyToken]: token,
              serviceAccessToken: serviceAccessToken,
              [GrafanaServiceAccountToken]: serviceAccountToken,
              [Configured0]: 'true',
              [Configured1]: 'true',
              [Configured2]: 'true',
            },
          })
          .then(() => {
            setTimeout(() => {
              window.location.pathname = `/a/${PLUGIN_ID}`;
            }, 300);
          });
      });
    },
    [serviceURL, sigmaURL, serviceAccessToken, incidentDSUid, alertingHistoryDSUid, existingAlertingDashboard]
  );

  // doInstall wraps the _doInstall function and handles whether we are running the cloud or self-hosted
  // version of Grafana
  const doInstall = useCallback(() => {
    if (hasAuth) {
      getToken().then((token) => {
        getServiceAccountToken().then((serviceAccountToken) => {
          _doInstall(token.token, serviceAccountToken);
        });
      });
    } else {
      getServiceAccountToken().then((serviceAccountToken) => {
        _doInstall(undefined, serviceAccountToken);
      });
    }
  }, [_doInstall, hasAuth, getToken, getServiceAccountToken]);

  return [doInstall] as const;
};
