import { CompletionItem } from './auto';
import { v4 } from 'uuid';

export const getOptionalRules = (): Array<CompletionItem> => {
  return [
    idSuggestion(),
    descriptionSuggestion,
    authorSuggestion,
    statusStableSuggestion,
    statusTestSuggestion,
    statusExperimentalSuggestion,
    statusDeprecatedSuggestion,
    statusUnsupportedSuggestion,
    dateSuggestion(),
    modifiedSuggestion(),
    referencesSuggestion,
    fieldsSuggestion,
    falsePositiveSuggestion,
    multiFalsePositiveSuggestion,
    levelInfoSuggestion,
    levelLowSuggestion,
    levelMediumSuggestion,
    levelHighSuggestion,
    levelCriticalSuggestion,
  ];
};

const idSuggestion = (): CompletionItem => ({
  insertText: `id: ${v4()}`,
  kind: 17,
  label: 'id',
  regex: /id:/gim,
  regexLine: {
    notStartWith: /^\s/,
  },
});

const descriptionSuggestion: CompletionItem = {
  insertText: 'description: ',
  kind: 17,
  label: 'description',
  regex: /description:/gim,
  regexLine: {
    notStartWith: /^\s/,
  },
};

const authorSuggestion: CompletionItem = {
  insertText: 'author: ',
  kind: 17,
  label: 'author',
  regex: /author:/gim,
  regexLine: {
    notStartWith: /^\s/,
  },
};

const statusStableSuggestion: CompletionItem = {
  insertText: 'status: stable',
  kind: 17,
  label: 'status (stable)',
  regex: /status:/gim,
  regexLine: {
    notStartWith: /^\s/,
  },
};

const statusTestSuggestion: CompletionItem = {
  insertText: 'status: test',
  kind: 17,
  label: 'status (test)',
  regex: /status:/gim,
  regexLine: {
    notStartWith: /^\s/,
  },
};

const statusExperimentalSuggestion: CompletionItem = {
  insertText: 'status: experimental',
  kind: 17,
  label: 'status (experimental)',
  regex: /status:/gim,
  regexLine: {
    notStartWith: /^\s/,
  },
};

const statusDeprecatedSuggestion: CompletionItem = {
  insertText: 'status: deprecated',
  kind: 17,
  label: 'status (deprecated)',
  regex: /status:/gim,
  regexLine: {
    notStartWith: /^\s/,
  },
};

const statusUnsupportedSuggestion: CompletionItem = {
  insertText: 'status: unsupported',
  kind: 17,
  label: 'status (unsupported)',
  regex: /status:/gim,
  regexLine: {
    notStartWith: /^\s/,
  },
};

const getFormattedDate = (): string => {
  const d = new Date();
  const year = d.getUTCFullYear();
  const month = `${d.getUTCMonth() + 1}`.padStart(2, '0');
  const day = `${d.getUTCDate()}`.padStart(2, '0');

  return `${year}/${month}/${day}`;
};

const dateSuggestion = (): CompletionItem => ({
  insertText: `date: ${getFormattedDate()}`,
  kind: 17,
  label: 'date',
  regex: /date:/gim,
  regexLine: {
    notStartWith: /^\s/,
  },
});

const modifiedSuggestion = (): CompletionItem => ({
  insertText: `modified: ${getFormattedDate()}`,
  kind: 17,
  label: 'modified',
  regex: /modified:/gim,
  regexLine: {
    notStartWith: /^\s/,
  },
});

const referencesSuggestion: CompletionItem = {
  insertText: 'references:\n  -',
  kind: 17,
  label: 'references',
  regex: /references:/gim,
  regexLine: {
    notStartWith: /^\s/,
  },
};

const fieldsSuggestion: CompletionItem = {
  insertText: 'fields:\n  - ',
  kind: 17,
  label: 'fields',
  regex: /fields:/gim,
  regexLine: {
    notStartWith: /^\s/,
  },
};

const falsePositiveSuggestion: CompletionItem = {
  insertText: 'falsepositives: ',
  kind: 17,
  label: 'falsepositives (str)',
  regex: /falsepositives:/gim,
  regexLine: {
    notStartWith: /^\s/,
  },
};

const multiFalsePositiveSuggestion: CompletionItem = {
  insertText: 'falsepositives:\n  - ',
  kind: 17,
  label: 'falsepositives (list)',
  regex: /falsepositives:/gim,
  regexLine: {
    notStartWith: /^\s/,
  },
};

const levelInfoSuggestion: CompletionItem = {
  insertText: 'level: informational',
  kind: 17,
  label: 'level (info)',
  regex: /level:/gim,
  regexLine: {
    notStartWith: /^\s/,
  },
};

const levelLowSuggestion: CompletionItem = {
  insertText: 'level: low',
  kind: 17,
  label: 'level (low)',
  regex: /level:/gim,
  regexLine: {
    notStartWith: /^\s/,
  },
};

const levelMediumSuggestion: CompletionItem = {
  insertText: 'level: medium',
  kind: 17,
  label: 'level (medium)',
  regex: /level:/gim,
  regexLine: {
    notStartWith: /^\s/,
  },
};

const levelHighSuggestion: CompletionItem = {
  insertText: 'level: high',
  kind: 17,
  label: 'level (high)',
  regex: /level:/gim,
  regexLine: {
    notStartWith: /^\s/,
  },
};

const levelCriticalSuggestion: CompletionItem = {
  insertText: 'level: critical',
  kind: 17,
  label: 'level (critical)',
  regex: /level:/gim,
  regexLine: {
    notStartWith: /^\s/,
  },
};
