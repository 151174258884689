import { RuntimeDataSource } from '@grafana/scenes';
import { BackendDataSourceResponse, getBackendSrv, getTemplateSrv, TemplateSrv } from '@grafana/runtime';
import { DataQueryRequest, DataQueryResponse, MetricFindValue } from '@grafana/data';
import { Observable, lastValueFrom } from 'rxjs';
import { backendResponseToDataFrame, fetchResponseToBackendResponse } from '../sigmarules/helper';
import { map } from 'rxjs/operators';
import { LogSourceListQuery } from '.';
import { PLUGIN_ID } from 'shared/constants';
import { ComponentType } from 'react';

export const fetchRemoteLogSourceConfigListData = (request: DataQueryRequest<LogSourceListQuery>) => {
  return getBackendSrv().fetch<BackendDataSourceResponse>({
    url: `/api/plugins/${PLUGIN_ID}/resources/v1/datasource/log-source-config-list`,
    method: 'POST',
    data: {
      app: request.app,
      filters: request.filters,
      Queries: request.targets,
    },
  });
};

export class LogSourceConfigListSource extends RuntimeDataSource {
  constructor(
    public pluginId: string,
    public uid: string,
    public readonly templateSrv: TemplateSrv = getTemplateSrv()
  ) {
    super(pluginId, uid);
    this.templateSrv = templateSrv;
  }

  query(request: DataQueryRequest<LogSourceListQuery>): Observable<DataQueryResponse> {
    return fetchRemoteLogSourceConfigListData(request).pipe(fetchResponseToBackendResponse, backendResponseToDataFrame);
  }

  metricFindQuery(query: any, options?: any): Promise<Array<MetricFindValue>> {
    const request = {
      requestId: 'A',
      app: 'grafana-detect-app',
      targets: [query],
    } as unknown as DataQueryRequest<LogSourceListQuery>;
    return lastValueFrom(
      this.query(request).pipe(
        map((response) => {
          return response.data;
        })
      )
    );
  }

  testDatasource() {
    return Promise.resolve({ status: 'success', message: 'OK' });
  }
}
