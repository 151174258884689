import React, { createContext } from 'react';
import { DashboardConfig } from 'shared/dashboardTypes';

export const DashboardContext = createContext<DashboardConfig | undefined>({});

export interface DashboardProviderProps {
  children?: React.ReactNode;
  dashboardConfig?: DashboardConfig;
}

export const DashboardProvider = (props: DashboardProviderProps) => {
  return <DashboardContext.Provider value={props.dashboardConfig}>{props.children}</DashboardContext.Provider>;
};
