import { RuntimeDataSource } from '@grafana/scenes';
import { BackendDataSourceResponse, getBackendSrv, getTemplateSrv, TemplateSrv } from '@grafana/runtime';
import {
  CustomVariableSupport,
  dataFrameFromJSON,
  DataQueryRequest,
  DataQueryResponse,
  DataSourceJsonData,
  FieldType,
  LoadingState,
  MetricFindValue,
  QueryEditorProps,
  ScopedVars,
  TimeRange,
} from '@grafana/data';
import { Observable, lastValueFrom } from 'rxjs';
import {
  backendResponseToDataFrame,
  fetchRemoteData,
  fetchResponseToBackendResponse,
  mapResponseToDataFrames,
  reduceFrameToStat,
} from '../sigmarules/helper';
import { map } from 'rxjs/operators';
import { RepoListQuery } from '.';
import { PLUGIN_ID } from 'shared/constants';
import { ComponentType } from 'react';

export const fetchRemoteRepoListData = (request: DataQueryRequest<RepoListQuery>) => {
  return getBackendSrv().fetch<BackendDataSourceResponse>({
    url: `/api/plugins/${PLUGIN_ID}/resources/v1/datasource/repo-list`,
    method: 'POST',
    data: {
      app: request.app,
      filters: request.filters,
      Queries: request.targets,
    },
  });
};

export class RepoListDataSource extends RuntimeDataSource {
  constructor(
    public pluginId: string,
    public uid: string,
    public readonly templateSrv: TemplateSrv = getTemplateSrv()
  ) {
    super(pluginId, uid);
    this.templateSrv = templateSrv;
  }

  query(request: DataQueryRequest<RepoListQuery>): Observable<DataQueryResponse> {
    return fetchRemoteRepoListData(request).pipe(fetchResponseToBackendResponse, backendResponseToDataFrame);
  }

  metricFindQuery(query: any, options?: any): Promise<Array<MetricFindValue>> {
    const request = {
      requestId: 'A',
      app: 'grafana-detect-app',
      targets: [query],
    } as unknown as DataQueryRequest<RepoListQuery>;
    return lastValueFrom(
      this.query(request).pipe(
        map((response) => {
          return response.data;
        })
      )
    );
  }

  testDatasource() {
    return Promise.resolve({ status: 'success', message: 'OK' });
  }
}
