import { TimeRange, dateTime } from '@grafana/data';

export const getDefaultTimeRange = (): TimeRange => {
  return {
    from: dateTime().subtract(1, 'day'),
    to: dateTime(),
    raw: {
      from: 'now-1d',
      to: 'now',
    },
  };
};
