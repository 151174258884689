import { useEffect, useState } from 'react';
import { PLUGIN_ID } from 'shared/constants';
import { DateTime } from '@grafana/data';
import { getBackendSrv } from '@grafana/runtime';

// Structure taken from response schema for https://docs.github.com/en/rest/repos/repos?apiVersion=2022-11-28#get-a-repository
export interface LicenseInfo {
  key: string;
  name: string;
  url: string | null;
  /* eslint-disable camelcase */
  spdx_id: string | null;
  node_id: string;
  html_url?: string | undefined;
  text?: string | null;
}

export interface RepoInfo {
  fullName: string;
  defaultBranch: string;
  id: string;
  created_at: DateTime;

  updated_at: DateTime;
  name: string;
  owner: string;
  repo: string;
  folder: string;
  license: LicenseInfo;
}

export const useRepoInfo = (repoId: string) => {
  const [repoInfo, setRepoInfo] = useState<RepoInfo>({} as RepoInfo);
  useEffect(() => {
    getBackendSrv()
      .fetch<RepoInfo>({
        url: `/api/plugins/${PLUGIN_ID}/resources/service/v1/db/github-repos/${repoId}`,
      })
      .subscribe((d) => {
        let licenseText;
        if (typeof d.data.license === 'string') {
          licenseText = d.data.license ?? '';
        } else {
          licenseText = d.data.license?.text ?? '';
        }
        const defaultBranch = d.data.defaultBranch ?? 'master';
        setRepoInfo({
          ...d.data,
          fullName: d.data.owner + '/' + d.data.repo,
          defaultBranch: defaultBranch,
          license: {
            key: '',
            name: '',
            spdx_id: '',
            node_id: '',
            text: licenseText,
            url: `https://github.com/${d.data.owner}/${d.data.repo}/blob/${defaultBranch}/LICENSE`,
          },
        });
      });
  }, [repoId]);

  return [repoInfo] as const;
};
