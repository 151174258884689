import { css } from '@emotion/css';
import { ConfirmModal, useStyles2 } from '@grafana/ui';
import React from 'react';

interface DeleteCustomRepoModalProps {
  repoName?: string;
  isOpen: boolean;

  dismiss(deleted: boolean): void;

  onDelete: any;
}

const getStyles = () => ({
  modal: css`
    overflow-wrap: break-word;
  `,
});

export const DeleteCustomRepoModal = (props: DeleteCustomRepoModalProps) => {
  const styles = useStyles2(getStyles);

  return (
    <ConfirmModal
      isOpen={props.isOpen}
      body={
        <>
          Are you sure you want to delete this custom repository?
          <br />
          <br />
          {props.repoName}
        </>
      }
      title="Delete Custom repository"
      confirmText="Permanently Delete"
      onConfirm={() => {
        props.onDelete();
        props.dismiss(true);
      }}
      onDismiss={() => props.dismiss(false)}
      modalClass={styles.modal}
    />
  );
};
