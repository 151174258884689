import { css } from '@emotion/css';
import React from 'react';

interface PageContainerProps {
  children: React.ReactNode;
}

export const PageContainer = (props: PageContainerProps) => {
  return (
    <div
      className={css`
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        width: 100%;
        max-width: 1200px;
        margin: 0 auto;
      `}
    >
      {props.children}
    </div>
  );
};
