import React, { useEffect, useMemo, useState } from 'react';
import { PluginPage } from '@grafana/runtime';
import { DetectErrorDisplay } from 'components/DetectErrorDisplay';
import { StatefulRuleEditor } from '../components/ruleEditor/Editor';
import { StatefulRuleEditorButtonSave } from '../components/ruleEditor/ButtonSave';

export const RuleEditorPage = () => {
  return (
    <PluginPage
      subTitle={'Sigma Rule editor for maintaining rules in this Grafana instance'}
      actions={
        <>
          <StatefulRuleEditorButtonSave />
        </>
      }
    >
      <DetectErrorDisplay />
      <StatefulRuleEditor />
    </PluginPage>
  );
};
