import { DetectError, DetectErrorCode } from './generic';

export const ServiceCreateSigmaRuleSuccess = (): DetectError => {
  const err = new DetectError(DetectErrorCode.SERVICE_CREATE_SIGMA_RULE_SUCCESS);
  err.level = 'success';
  err.stack = undefined;
  return err;
};

export const ServiceCreateSigmaRuleFail = (msg: string): DetectError => {
  const err = new DetectError(DetectErrorCode.SERVICE_CREATE_SIGMA_RULE_ERROR);
  err.level = 'error';
  err.message = msg;
  return err;
};
