import {
  CustomVariable,
  EmbeddedScene,
  SceneAppPage,
  SceneControlsSpacer,
  SceneFlexLayout,
  sceneGraph,
  SceneRefreshPicker,
  SceneVariableSet,
  VariableValueSelectors,
} from '@grafana/scenes';
import { PLUGIN_ROOT } from 'shared/constants';
import { VariableHide } from '@grafana/schema';
import { LogSourceLayout } from './LogSourceLayout';
import { GenericButton } from '../generic.button';

const getLayout = () => {
  return new LogSourceLayout({});
};

const getScene = (): EmbeddedScene => {
  const logsourceVariable = new CustomVariable({
    name: 'logsource',
    hide: VariableHide.hideVariable,
    isMulti: true,
  });

  const editButton = new GenericButton({
    locationTemplate: PLUGIN_ROOT + '/scenes/logsource/editor?var-logsource=${logsource}',
    currentTab: true,
    text: 'Edit',
    variant: 'secondary',
    disabled:
      Array.isArray(logsourceVariable.getValue()) && (logsourceVariable.getValue() as Array<string>).length !== 1,
  });

  const scene = new EmbeddedScene({
    key: 'logsource-overview',
    body: new SceneFlexLayout({
      children: [getLayout()],
    }),
    controls: [new VariableValueSelectors({}), new SceneControlsSpacer(), new SceneRefreshPicker({}), editButton],
    $variables: new SceneVariableSet({
      variables: [logsourceVariable],
    }),
  });

  scene.addActivationHandler(() => {
    let unsub = logsourceVariable.subscribeToState((variable) => {
      editButton.setState({
        disabled: (variable.value as Array<string>).length !== 1,
      });
    });

    return () => {
      unsub.unsubscribe();
      logsourceVariable.changeValueTo([]);
    };
  });

  return scene;
};

export const getLogsourceOverviewScene = () =>
  new SceneAppPage({
    title: 'Log Source Configs',
    url: `${PLUGIN_ROOT}/scenes/logsource/overview`,
    preserveUrlKeys: ['var-logsource'],
    titleIcon: 'eye',
    getScene,
  });
