import React, { useEffect, useMemo, useState } from 'react';
import { applyFieldOverrides, DataFrame, DataSourceSettings, FieldConfigSource, ScopedVars } from '@grafana/data';
import { css } from '@emotion/css';
import { useStyles2, useTheme2 } from '@grafana/ui';
import { FolderPanelOptions } from '../types';
import { dataFrameToRow } from '../utils/dataFrameToRow';
import { ListItem } from './ListItem';
import { ErrorNotifier } from './ErrorNotifier';
import { getTemplateSrv } from '@grafana/runtime';

const getStyles = () => ({
  container: css`
    flex: 1;
    overflow-y: auto;
    padding-left: 1rem;
    width: 100%;
  `,
});

interface ListProps {
  dataFrame?: DataFrame;
  options: FolderPanelOptions;
  fieldConfig: FieldConfigSource;
  selectedValue: string | null;
  dataSourceList: Array<DataSourceSettings>;

  offset?: boolean;
}

export const List = (props: ListProps) => {
  const styles = useStyles2(getStyles);
  const theme = useTheme2();

  const df = useMemo(() => {
    const templateSrv = getTemplateSrv();

    let df = props.dataFrame ? [props.dataFrame] : [];
    df = applyFieldOverrides({
      data: df,
      theme,
      fieldConfig: props.fieldConfig,
      replaceVariables: (value: string, scopedVars?: ScopedVars, format?: string | Function) => {
        return templateSrv.replace(value, scopedVars, format);
      },
    });
    return df[0] ?? undefined;
  }, [props.dataFrame, props.fieldConfig, theme]);

  const series = useMemo(() => {
    if (df) {
      return dataFrameToRow(df, props.options);
    }
    return undefined;
  }, [df, props.options]);

  const hasNamedData = useMemo(() => {
    if (df && df.fields) {
      return df.fields.find((e) => e.name === props.options.fieldName) !== undefined;
    }

    return false;
  }, [df, props.options.fieldName]);

  const openValue = useMemo(() => {
    let pathParams = props.options.selection.split('/');
    return {
      current: pathParams.shift(),
      next: pathParams.join('/') ?? '',
    };
  }, [props.options.selection]);

  if (!hasNamedData) {
    return <ErrorNotifier errorText={`No data found in response for field ${props.options.fieldName}`} />;
  }

  return (
    <div className={styles.container}>
      {series &&
        series.map((row, index) => {
          return (
            <ListItem
              key={`row-${index}`}
              row={props.offset ? index + 1 : index}
              data={row}
              nameField={props.options.fieldName}
              filters={props.options.fieldLabels}
              useFilters={props.options.showLabels}
              options={{ ...props.options, selection: openValue.next }}
              selectedValue={props.selectedValue}
              fieldConfig={props.fieldConfig}
              open={row[0] === openValue.current}
              dataSourceList={props.dataSourceList}
            />
          );
        })}
    </div>
  );
};
