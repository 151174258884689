import { Field, FieldConfig, FieldType, PanelPlugin } from '@grafana/data';
import { RulePanelOptions } from './types';
import { RulePanel } from './components/RulePanel';
import { sceneUtils } from '@grafana/scenes';

export const plugin = new PanelPlugin<RulePanelOptions, FieldConfig>(RulePanel)
  .useFieldConfig()
  .setPanelOptions((builder) => {
    return builder.addFieldNamePicker({
      name: 'File Path',
      path: 'fieldPath',
      defaultValue: 'value',
      settings: {
        filter: (field: Field) => field.type === FieldType.string,
      },
    });
  });

try {
  sceneUtils.registerRuntimePanelPlugin({ pluginId: 'grafana-detect-app-rule-panel', plugin });
} catch (e) {
  console.error(e);
}
