import { css } from '@emotion/css';
import { ConfirmModal, useStyles2 } from '@grafana/ui';
import React, { useContext, useEffect, useState } from 'react';
import { GetPipelineResponse } from 'shared/requests/pipelines';
import { PipelineContext } from 'stores';

interface DeletePipelineModalProps {
  pipelineName?: string;
  pipelineID: string;
  isOpen: boolean;

  dismiss(deleted: boolean): void;

  onDelete(ID: string): void;
}

const getStyles = () => ({
  modal: css`
    overflow-wrap: break-word;
  `,
});

export const DeletePipelineModal = (props: DeletePipelineModalProps) => {
  const styles = useStyles2(getStyles);

  return (
    <ConfirmModal
      isOpen={props.isOpen}
      body={
        <>
          Are you sure you want to delete this pipeline?
          <br />
          <br />
          {props.pipelineName}
        </>
      }
      title="Delete Pipeline"
      confirmText="Permanently Delete"
      onConfirm={() => {
        props.onDelete(props.pipelineID);
        props.dismiss(true);
      }}
      onDismiss={() => props.dismiss(false)}
      modalClass={styles.modal}
    />
  );
};

interface StatefulDeletePipelineModalProps {
  isOpen: boolean;

  dismiss(deleted: boolean): void;
}

export const StatefulDeletePipelineModal = (props: StatefulDeletePipelineModalProps) => {
  const [pipeline, setPipeline] = useState<GetPipelineResponse>({} as GetPipelineResponse);

  const {
    data: { get },
    operations: { delete: doDeletePipeline },
  } = useContext(PipelineContext);

  useEffect(() => {
    get?.subscribe(setPipeline);
  }, [get]);

  return (
    <DeletePipelineModal {...props} onDelete={doDeletePipeline} pipelineID={pipeline.id} pipelineName={pipeline.name} />
  );
};
