import React from 'react';
import {
  CustomVariable,
  MultiValueVariableState,
  SceneComponentProps,
  sceneGraph,
  SceneObjectBase,
  SceneObjectState,
  VariableDependencyConfig,
} from '@grafana/scenes';
import { DetectScenesVariables } from '../../../variables';
import { DataSourcePicker } from '@grafana/runtime';
import { Field } from '@grafana/ui';
import { DataSourceRef } from '@grafana/schema';
import { DataSourceInstanceSettings } from '@grafana/data';

interface DatasourceState extends SceneObjectState {}

interface DatasourceStateInternal extends DatasourceState {
  datasource?: string;
}

export class Datasource extends SceneObjectBase<DatasourceStateInternal> {
  protected static Component = DatasourceRenderer;

  _variableDependency = new VariableDependencyConfig(this, {
    variableNames: [DetectScenesVariables.LogSourceDataSource],
    onReferencedVariableValueChanged: () => {
      this.getDatasource();
    },
  });

  constructor(state: Partial<DatasourceState>) {
    super({ ...state });
    this.addActivationHandler(this.getDatasource.bind(this));
  }

  getDatasource() {
    const datasource = sceneGraph.lookupVariable(DetectScenesVariables.LogSourceDataSource, this) as CustomVariable;
    datasource.subscribeToState(this.processState.bind(this));
    this.processState(datasource.state);
  }

  private processState(state: MultiValueVariableState) {
    if (state.value === '') {
      this.setState({ datasource: undefined });
      return;
    }
    this.setState({ datasource: state.value.toString() });
  }

  update(value: DataSourceInstanceSettings) {
    const datasourceVariable = sceneGraph.lookupVariable(
      DetectScenesVariables.LogSourceDataSource,
      this
    ) as CustomVariable;
    datasourceVariable.changeValueTo(value.uid);
  }
}

function DatasourceRenderer({ model }: SceneComponentProps<Datasource>) {
  const { datasource } = model.useState();

  return (
    <Field label="Data Source">
      <DataSourcePicker current={datasource} onChange={(ref) => model.update(ref)} />
    </Field>
  );
}
