import { sceneUtils } from '@grafana/scenes';
import { DataQuery } from '@grafana/schema';
import { LogSourceConfigListSource } from './list';

export interface LogSourceListQuery extends DataQuery {}

try {
  console.log('Registering Runtime DS repo-list');
  sceneUtils.registerRuntimeDataSource({
    dataSource: new LogSourceConfigListSource('log-source-config-list', 'log-source-config-list'),
  });
} catch (e) {
  console.error(e);
}
