import {
  DataQueryRequest,
  DataQueryResponse,
  DataQueryResponseData,
  DataSourceApi,
  Field,
  FieldDTO,
  SelectableValue,
  TimeRange,
} from '@grafana/data';
import { DataSourcePicker, getDataSourceSrv } from '@grafana/runtime';
import {
  Button,
  Field as FieldComponent,
  Icon,
  Segment,
  SegmentInput,
  SegmentSection,
  Stack,
  TagList,
  Tooltip,
} from '@grafana/ui';
import { DataQuery, DataSourceRef } from '@grafana/schema';
import React, { useContext, useEffect, useMemo, useReducer, useState } from 'react';
import { LabelFilters } from './LabelFilters';
import { QueryBuilderLabelFilter } from 'shared/types';
import { css } from '@emotion/css';
import { LokiParserAndLabelKeys, LokiQuery } from 'shared/lokiTypes';
import { GREY_COLOR_INDEX, LIGHT_BLUE_COLOR_INDEX, PLUGIN_ID, RED_COLOR_INDEX } from 'shared/constants';
import { getDefaultTimeRange } from 'utils/datetime';
import { ConversionContext } from 'stores/conversion';
import { Set } from 'immutable';

export interface DataSourceParserConfig {
  parser: SelectableParser | null | undefined;
  params: string | null | undefined;
  additionalExpr: string | null;
}

export interface DataSourceConfigurationProps {
  current: DataSourceRef | null;
  target: string;
  onDatasourceChange?: (datasource: DataSourceApi | null | undefined) => void | null;
  onLabelFiltersChange?: (labelFilters: Array<QueryBuilderLabelFilter>) => void | null;
  onParserConfigChange?: (parserConfig: DataSourceParserConfig) => void | null;
}

export interface QueryExpression {
  stream?: string;
  parser?: string;
}

export interface ResultsSummary {
  count: number;
  fields: Record<string, string | undefined>;
  errors: Array<string>;
  lines: Array<string>;
}

interface SelectableParser extends SelectableValue<string | null> {
  hasParams?: 'Required' | 'Optional' | null;
  quote?: boolean;
}

export default function DataSourceConfiguration(props: DataSourceConfigurationProps) {
  const [_, setDatasourceRef] = useState<DataSourceRef | null>(props.current);
  const [datasource, setDatasource] = useState<DataSourceApi>();
  const [filters, setFilters] = useState<Array<QueryBuilderLabelFilter>>([]);
  const [tags, setTags] = useState<Array<string>>([]);
  const [fields, setFields] = useState<Array<string>>([]);

  const {
    data: {
      lokiParserAndLabelKeys: stateLokiParserAndLabelKeys,
      fields: stateFields,
      filters: stateFilters,
      parserConfig: stateParserConfig,
    },
    operations: {
      getLokiParserAndLabelKeys: doGetLokiParserAndLabelKeys,
      setStreamSelectors: doSetStreamSelectors,
      setFilters: doSetFilters,
    },
  } = useContext(ConversionContext);

  const loadDatasource = async (dsRef: DataSourceRef) => {
    if (props.current !== dsRef) {
      const dataSourceServ = getDataSourceSrv();
      const ds = await dataSourceServ.get(dsRef);
      if (ds !== datasource) {
        setDatasource(ds);
        if (props.onDatasourceChange) {
          props.onDatasourceChange(ds);
        }
      }
    }
  };

  const lokiParsers: Array<SelectableParser> = useMemo(
    () => [
      { label: 'Default', value: null },
      { label: 'json', value: 'json', hasParams: 'Optional', quote: false },
      { label: 'logfmt', value: 'logfmt' },
      { label: 'pattern', value: 'pattern', hasParams: 'Required', quote: true },
      { label: 'regexp', value: 'regexp', hasParams: 'Required', quote: true },
      { label: 'unpack', value: 'unpack' },
    ],
    []
  );
  const [parser, setParser] = useState<SelectableParser>(lokiParsers[0]);
  const [parserManuallySet, setParserManuallySet] = useState<boolean>(false);

  useEffect(() => {
    stateFilters.subscribe((e) => {
      setFilters(e);
      doSetStreamSelectors(e);
    });
  }, [stateFilters, doSetStreamSelectors]);

  useEffect(() => {
    if (!stateLokiParserAndLabelKeys) {
      return;
    }

    const subscription = stateLokiParserAndLabelKeys.subscribe((filtered: LokiParserAndLabelKeys | null) => {
      if (filtered) {
        // Update the list of tags.
        setTags(filtered.extractedLabelKeys);

        // Only update the parser if it hasn't been manually set by the user.
        if (!parserManuallySet) {
          // Update the parser to matches the data.
          if (filtered.hasJSON) {
            setParser(lokiParsers[1]);
          } else if (filtered.hasLogfmt) {
            setParser(lokiParsers[2]);
          } else if (filtered.hasPack) {
            setParser(lokiParsers[5]);
          } else {
            setParser(lokiParsers[0]);
          }
        }
      }
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [stateLokiParserAndLabelKeys, parserManuallySet, lokiParsers]);

  useEffect(() => {
    if (!stateFields) {
      return;
    }

    const subscription = stateFields.subscribe((fields: Array<Array<string>> | null) => {
      if (fields) {
        setFields(Set(fields.flat()).sort().toArray());
      }
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [stateFields]);

  const [parserConfig, setParserConfig] = useState<DataSourceParserConfig>({
    parser: parser,
    params: '',
    additionalExpr: null,
  });

  useEffect(() => {
    stateParserConfig.subscribe((e) => {
      if (e) {
        setParser(e.parser ?? lokiParsers[0]);
        setParserConfig(e);
      }
    });
  }, [stateParserConfig, setParser, setParserConfig, lokiParsers]);

  if (props.current) {
    loadDatasource(props.current);
  } else {
    // If no default is set, list all the datasources and pick the first one
    const dsList = getDataSourceSrv().getList();
    if (dsList.length > 0) {
      loadDatasource(dsList[0]);
    }
  }

  let target = 'loki';
  switch (props.target) {
    case 'splunk':
      target = 'grafana-splunk-datasource';
      break;
    case 'qradar':
      target = 'grafana-qradar-datasource';
      break;
  }

  return (
    <Stack direction="column">
      <Stack direction="row">
        <DataSourcePicker
          type={target}
          current={props.current}
          onChange={async (dsRef) => {
            setDatasourceRef(dsRef);
            loadDatasource(dsRef);
          }}
        />
        <LabelFilters
          labelsFilters={filters}
          onChange={(newFilters) => {
            doSetFilters(newFilters);
          }}
          onGetLabelNames={(forLabel) => {
            return new Promise(async (resolve) => {
              if (datasource && datasource.languageProvider) {
                // Workaround for older versions of Grafana (<9.5.0)
                if ('fetchLabels' in datasource.languageProvider) {
                  await datasource.languageProvider.fetchLabels();
                } else if ('refreshLogLabels' in datasource.languageProvider) {
                  await datasource.languageProvider.refreshLogLabels();
                }
                const keys: Array<string> = await datasource.languageProvider.getLabelKeys();
                resolve(keys.map((k) => ({ label: k, value: k })));
              }
              resolve([]);
            });
          }}
          onGetLabelValues={(forLabel) => {
            return new Promise(async (resolve) => {
              if (datasource && datasource.languageProvider && forLabel.label) {
                // Workaround for older versions of Grafana (<10.3.0)
                let vals: Array<string> = [];
                if ('fetchLabelValues' in datasource.languageProvider) {
                  vals = await datasource.languageProvider.fetchLabelValues(forLabel.label);
                } else if ('getLabelValues' in datasource.languageProvider) {
                  vals = await datasource.languageProvider.getLabelValues(forLabel.label);
                }
                resolve(vals.map((v) => ({ label: v, value: v })));
              }
              resolve([]);
            });
          }}
        />
      </Stack>
      <SegmentSection label="Parser Expr:">
        <Segment
          placeholder="Choose a custom parser"
          value={parser}
          width={0}
          options={lokiParsers}
          onChange={(val) => {
            setParser(val);
            props.onParserConfigChange?.call(props, { ...parserConfig, parser: val });
            setParserManuallySet(true);
          }}
        />
        <SegmentParserParam
          {...props}
          selectedParser={parser}
          parserConfig={parserConfig}
          updateConfig={(e) => props.onParserConfigChange?.call(props, { ...parserConfig, ...e })}
        />
        <SegmentAdditionalExpr
          {...props}
          selectedParser={parser}
          parserConfig={parserConfig}
          updateConfig={(e) => props.onParserConfigChange?.call(props, { ...parserConfig, ...e })}
        />
        <button
          type="button"
          hidden={parser.value === null}
          className="gf-form-label query-part"
          onClick={(evt) => {
            if (parserConfig.additionalExpr === null) {
              props.onParserConfigChange?.call(props, { ...parserConfig, additionalExpr: '' });
            } else {
              props.onParserConfigChange?.call(props, { ...parserConfig, additionalExpr: null });
            }
          }}
        >
          <Icon name={parserConfig.additionalExpr === null ? 'plus-circle' : 'minus-circle'} />
        </button>
        <Button
          variant="secondary"
          onClick={(e) => {
            e.preventDefault();
            doGetLokiParserAndLabelKeys();
          }}
        >
          Fetch Labels
        </Button>
      </SegmentSection>
      <div
        className={css`
          border-left: 4px solid #e2e2e2;
          border-image-slice: 1;
          padding: 10px 10px 0px 10px;
          margin-top: 8px;
          border-image-source: linear-gradient(0.01deg, rgb(245, 95, 62) 0.01%, rgb(255, 136, 51) 99.99%);
        `}
      >
        {tags && (
          <Tooltip
            placement="top"
            content="Gray: the detected label doesn't have an equivalent field in the detected fields. Blue: the detected label has a matching field."
          >
            <FieldComponent
              label="Detected Labels"
              description="Labels detected within logs can be used to filter the logs"
            >
              <TagList
                tags={tags}
                getColorIndex={(tag) => {
                  if (fields.includes(tag)) {
                    return LIGHT_BLUE_COLOR_INDEX;
                  }
                  return GREY_COLOR_INDEX;
                }}
                className={css`
                  justify-content: flex-start;
                `}
              />
            </FieldComponent>
          </Tooltip>
        )}
        {fields && (
          <Tooltip
            placement="bottom"
            content="Blue: the detected field has a matching label. Red: the detected field doesn't have an equivalent label in the detected labels."
          >
            <FieldComponent
              label="Detected Fields"
              description="Fields detected from generated queries for matching labels in logs"
            >
              <TagList
                tags={fields}
                getColorIndex={(field) => {
                  if (tags.includes(field)) {
                    return LIGHT_BLUE_COLOR_INDEX;
                  }
                  return RED_COLOR_INDEX;
                }}
                className={css`
                  justify-content: flex-start;
                `}
              />
            </FieldComponent>
          </Tooltip>
        )}
      </div>
    </Stack>
  );
}

interface ParserConfigProps extends DataSourceConfigurationProps {
  selectedParser: SelectableParser;
  parserConfig: DataSourceParserConfig;
  updateConfig: (e: Partial<DataSourceParserConfig>) => void;
}

function SegmentParserParam(props: ParserConfigProps) {
  if (props.selectedParser.hasParams) {
    return (
      <SegmentInput
        placeholder={props.selectedParser.hasParams}
        value={props.parserConfig.params || ''}
        autofocus
        onChange={(val) => {
          props.updateConfig({ params: val.toString() });
        }}
      />
    );
  }
  return null;
}

function SegmentAdditionalExpr(props: ParserConfigProps) {
  if (props.parserConfig.additionalExpr !== null) {
    return (
      <>
        <div
          className={css`
            line-height: 32px;
            padding: 0 4px 0 0;
          `}
        >
          |
        </div>
        <SegmentInput
          value={props.parserConfig.additionalExpr || ''}
          autofocus
          onChange={(val) => {
            props.updateConfig({ additionalExpr: val.toString() });
          }}
        />
      </>
    );
  }
  return null;
}

export const fetchQuerySummary = async (datasource: DataSourceApi, expr: QueryExpression): Promise<ResultsSummary> => {
  const targets: Array<LokiQuery> = [
    {
      refId: 'Test query from Detect Plugin',
      expr: expr.stream + (expr.parser ? ' | ' + expr.parser : ''),
    },
  ];
  const query = createTestQuery(targets);
  const output = datasource?.query(query);
  let result: ResultsSummary = { count: 0, fields: {}, lines: [], errors: [] };
  const summariseResponse = (response: DataQueryResponse) => {
    if (response.errors) {
      response.errors.forEach((error) => {
        if (error.type !== 'cancelled' && error.message) {
          console.error(error.message);
          result.errors.push(error.message);
        }
      });
    } else {
      response.data.forEach((results: DataQueryResponseData) => {
        result.count += results.length;
        results.fields.forEach((field: Field | FieldDTO) => {
          if (field.name === 'labels' && field.values) {
            let values: Array<any>;
            values = field.values;
            values.forEach((value) => {
              if (typeof value === 'object') {
                Object.entries(value).forEach(([label, value]) => {
                  if (!(label in result.fields)) {
                    result.fields[label] = String(value);
                  }
                });
              }
            });
          } else if (!(field.name in result.fields)) {
            if (field.values && field.values.length > 0) {
              result.fields[field.name] = String(field.values[0]);
              if (field.name === 'Line') {
                result.lines = result.lines.concat(...field.values);
              }
            } else {
              result.fields[field.name] = undefined;
            }
          }
        });
        if (results.meta?.custom?.error) {
          console.warn(results.meta.custom.error);
          result.errors.push(results.meta.custom.error);
        }
      });
    }
    // TODO?: potentially summarise stats to provide performance info
  };
  if ('forEach' in output) {
    await output.forEach(summariseResponse);
  } else {
    output.then(summariseResponse);
  }
  return result;
};

export const createTestQuery = (
  targets: Array<DataQuery>,
  timeRange: TimeRange = getDefaultTimeRange(),
  timeZone = 'UTC'
): DataQueryRequest => {
  return {
    app: PLUGIN_ID,
    requestId: 'detect-query-test',
    targets: targets,
    interval: '',
    // FIXME: hardcoded to 5 minutes (can ths be calculated from the time range?)
    intervalMs: 300000,
    range: timeRange,
    scopedVars: {},
    timezone: timeZone,
    // 1000 seems to be a reasonable number of points to get a good idea of the data
    maxDataPoints: 1000,
    startTime: timeRange.from.toDate().getTime(),
  };
};
