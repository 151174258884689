import { CompletionItem } from './auto';

export const getRequiredRules = (): Array<CompletionItem> => {
  return [
    titleSuggestion,
    logsourceSuggestion,
    logsourceCategorySuggestion,
    logsourceProductSuggestion,
    logsourceServiceSuggestion,
    logsourceDefinitionSuggestion,
    detectionSuggestion,
    detectionConditionSuggestion,
  ];
};

const titleSuggestion: CompletionItem = {
  insertText: 'title: ',
  kind: 17,
  label: 'title',
  regex: /title:/gim,
  regexLine: {
    notStartWith: /^\s/,
  },
};

const logsourceSuggestion: CompletionItem = {
  insertText: 'logsource:',
  kind: 17,
  label: 'logsource',
  regex: /logsource:/gim,
  regexLine: {
    notStartWith: /^\s/,
  },
};

const logsourceCategorySuggestion: CompletionItem = {
  insertText: 'category: ',
  kind: 17,
  label: 'category',
  regex: /category:/gim,
  isChild: true,
  regexLine: {
    previousKeyword: /logsource:/gim,
  },
};

const logsourceProductSuggestion: CompletionItem = {
  insertText: 'product: ',
  kind: 17,
  label: 'product',
  regex: /product:/gim,
  isChild: true,
  regexLine: {
    previousKeyword: /logsource:/gim,
  },
};

const logsourceServiceSuggestion: CompletionItem = {
  insertText: 'service: ',
  kind: 17,
  label: 'service',
  regex: /service:/gim,
  isChild: true,
  regexLine: {
    previousKeyword: /logsource:/gim,
  },
};

const logsourceDefinitionSuggestion: CompletionItem = {
  insertText: 'definition: ',
  kind: 17,
  label: 'definition',
  regex: /definition:/gim,
  isChild: true,
  regexLine: {
    previousKeyword: /logsource:/gim,
  },
};

const detectionSuggestion: CompletionItem = {
  insertText: 'detection:',
  kind: 17,
  label: 'detection',
  regex: /detection:/gim,
  isChild: true,
  regexLine: {
    notStartWith: /^\s/,
  },
};

const detectionConditionSuggestion: CompletionItem = {
  insertText: 'selection:\n  - \ncondition: selection',
  kind: 17,
  label: 'condition (str)',
  regex: /condition:/gim,
  isChild: true,
  regexLine: {
    previousKeyword: /detection:/gim,
  },
};
