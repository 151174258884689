import { Alert, Button, InlineField, Legend, SecretInput } from '@grafana/ui';
import { useGrafanaState } from 'hooks/useGrafanaState';
import React from 'react';

interface CloudAccessPolicyProps {
  configured: boolean;
  onToken?(token: string): void;
}

export const CloudAccessPolicy = (props: CloudAccessPolicyProps) => {
  const [hasAuth, , , generateToken] = useGrafanaState();
  if (!hasAuth) {
    return null;
  }

  return (
    <div className="gf-form-group">
      <Legend>Configure Cloud Access Policy</Legend>
      {!props.configured && (
        <Alert title="Token Required" severity="warning">
          Please generate an access token for Grafana Detect (Cloud) to authenticate with backend services.
        </Alert>
      )}
      <InlineField label="Access Token" disabled>
        <SecretInput
          isConfigured={props.configured}
          // This is a required field, but as this will be disabled, we can just pass an empty function
          onReset={() => {}}
        />
      </InlineField>
      <Button
        onClick={() => {
          generateToken()?.then((token) => {
            props.onToken && props.onToken(token.token);
          });
        }}
      >
        {props.configured ? 'Re-g' : 'G'}enerate Access Token
      </Button>
    </div>
  );
};
