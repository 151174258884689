import React, { useContext, useEffect, useState } from 'react';
import { Button, ButtonCascader, LinkButton } from '@grafana/ui';
import { ConversionContext } from 'stores/conversion';
import { combineLatest } from 'rxjs';
import { useTranslation } from 'react-i18next';

interface DashboardCreateButtonProps {
  disabled: boolean;
  dashboardID: string;

  onCreateDashboard(event: any): void;
}

interface StatefulDashboardCreateButtonProps {
  onCreateDashboard(event: any): void;
}

export const DashboardCreateButton = (props: DashboardCreateButtonProps) => {
  const { t } = useTranslation();

  if (props.dashboardID) {
    return (
      <ButtonCascader
        options={[
          {
            label: t('converter.dashboards.see-dashboard'),
            value: 'see-dashboard',
          },
          {
            label: t('converter.dashboards.update'),
            value: 'update-dashboard',
          },
        ]}
        icon="gf-landscape"
        variant="secondary"
        disabled={props.disabled}
        onChange={(groupnames, options) => {
          switch (options[0].value) {
            case 'see-dashboard':
              window.open(`/d/${props.dashboardID}`, '_blank');
              break;
            case 'update-dashboard':
              props.onCreateDashboard({});
              break;
          }
        }}
      >
        {t('converter.dashboards.button')}
      </ButtonCascader>
    );
  }
  return (
    <Button disabled={props.disabled} icon="gf-landscape" variant="secondary" onClick={props.onCreateDashboard}>
      {t('converter.dashboards.createButton')}
    </Button>
  );
};

export const StatefulDashboardCreateButton = (props: StatefulDashboardCreateButtonProps) => {
  const [disabled, setDisabled] = useState<boolean>(true);
  const [dashboardID, setDashboardID] = useState('');

  const {
    data: { queries: stateQueries, datasource: stateDatasource, dashboardID: stateDashboardID },
  } = useContext(ConversionContext);

  // Effect for state subscription
  useEffect(() => {
    // Combine latest ensures that all observables provide a value (through next) before emitting
    combineLatest({
      stateQueries,
      stateDatasource,
    }).subscribe((e) => {
      setDisabled(!e.stateQueries || !e.stateDatasource);
    });
    stateDashboardID.subscribe(setDashboardID);
  }, [stateQueries, stateDatasource, stateDashboardID]);

  return <DashboardCreateButton {...props} disabled={disabled} dashboardID={dashboardID} />;
};
