import { ConfirmModal, QueryField } from '@grafana/ui';
import { dump } from 'js-yaml';
import React, { useContext, useEffect } from 'react';
import { AlertContext } from 'stores/alerts';
import { ConversionContext } from 'stores/conversion';

interface OverrideAlertGroupModalProps {
  alertGroup: {
    ruleConflict: boolean;
    overwriteAlerts: Array<string>;
    alertGroup: {
      name: string;
      content: string;
    };
  };
  setAlertGroup: () => void;
  dismiss: () => void;
}

export const OverrideAlertGroupModal = ({ alertGroup, setAlertGroup, dismiss }: OverrideAlertGroupModalProps) => {
  return (
    <ConfirmModal
      isOpen={alertGroup.ruleConflict}
      title={`Overwrite alert group ${alertGroup.alertGroup.name}?`}
      body={
        <>
          Do you want to overwrite the existing alert group with the same name?
          <QueryField query={alertGroup.alertGroup.content} portalOrigin="" />
        </>
      }
      confirmText="Overwrite"
      onConfirm={() => setAlertGroup()}
      onDismiss={() => dismiss()}
    />
  );
};

export const StatefulOverrideAlertGroupModal = () => {
  const [alertGroup, setAlertGroup] = React.useState<{ name: string; content: string }>({
    name: '',
    content: '',
  });
  const [hasConflict, setHasConflict] = React.useState<boolean>(false);

  const {
    data: { parsedGroups: stateParsedGroups, hasConflict: stateHasConflict },
    operations: { forceCreateAlert: doForceCreateAlert, abort: doAbort },
  } = useContext(AlertContext);

  const {
    data: { datasource: stateDatasource },
  } = useContext(ConversionContext);

  useEffect(() => {
    stateParsedGroups.subscribe((group) => {
      setAlertGroup({
        name: group.name,
        content: dump(group),
      });
    });
    stateHasConflict.subscribe(setHasConflict);
  }, [stateParsedGroups, stateHasConflict]);

  return (
    <OverrideAlertGroupModal
      alertGroup={{
        ruleConflict: hasConflict,
        overwriteAlerts: [alertGroup.name],
        alertGroup: alertGroup,
      }}
      setAlertGroup={() => doForceCreateAlert(stateDatasource)}
      dismiss={() => doAbort()}
    />
  );
};
