import React, { useEffect, useState } from 'react';
import { css } from '@emotion/css';
import { Modal, Button, InlineField } from '@grafana/ui';
import { findLastIndex, set } from 'lodash';

interface ModalModel {
  title: string;
  message: string;
  isModalOpen: boolean;
  hideModal: () => void;
}

export const GenericModal = (props: ModalModel) => {
  return (
    <>
      <Modal
        title={props.title}
        isOpen={props.isModalOpen}
        trapFocus={true}
        closeOnEscape={true}
        closeOnBackdropClick={true}
      >
        <div
          className={css`
            white-space: pre-wrap;
          `}
        >
          {props.message}
        </div>
        <br />
        <Modal.ButtonRow>
          <Button onClick={() => props.hideModal()}>OK</Button>
        </Modal.ButtonRow>
      </Modal>
    </>
  );
};
