import React, { useCallback, useMemo, useState } from 'react';
import { DataSourceSettings, FieldConfigSource, GrafanaTheme2 } from '@grafana/data';
import { css, cx } from '@emotion/css';
import { Checkbox, DataLinksContextMenu, Icon, IconButton, IconName, Tag, useStyles2 } from '@grafana/ui';
import { FieldDataSourceInner, FieldValue } from '../utils/dataFrameToRow';
import { List } from './List';
import { FolderPanelOptions } from '../types';
import { locationService } from '@grafana/runtime';
import { DataSourceIcon } from './DataSourceIcon';

const getStyles = (theme: GrafanaTheme2) => {
  return {
    name: css`
      flex: 1;
      display: flex;
    `,
    htmlList: css`
      display: flex;
      gap: 6px;
    `,
    htmlListItem: css`
      list-style: none;
    `,
    listItem: css`
      width: 100%;
      min-height: 3rem;
      line-height: 2rem;
      padding: 0.5rem;
      padding-right: 0;
      padding-left: 0;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      & > * {
        padding-left: 1rem;
        padding-right: 1rem;
      }
    `,
    listItemPrimary: css`
      background-color: ${theme.colors.background.secondary};
    `,
    listItemSecondary: css`
      background-color: ${theme.colors.background.primary};
    `,
    icon: css`
      line-height: inherit;
    `,
    cellLink: css({
      cursor: 'pointer',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      userSelect: 'text',
      whiteSpace: 'nowrap',
      color: theme.colors.text.link,
      fontWeight: theme.typography.fontWeightMedium,
      '&:hover': {
        textDecoration: 'underline',
        color: theme.colors.text.link,
      },
    }),
  };
};

export interface ListItemProps {
  row: number;
  data: FieldValue;
  options: FolderPanelOptions;
  fieldConfig: FieldConfigSource;

  nameField: string;
  showLabels?: boolean;
  useFilters?: boolean;
  filters?: Array<string>;
  selectedValue: string | null;
  open?: boolean;
  dataSourceList: Array<DataSourceSettings>;
}

export const ListItem = (props: ListItemProps) => {
  const styles = useStyles2(getStyles);
  const [open, setOpen] = useState(props.open || props.options.defaultOpenChildren);
  const shownTargets: Array<string> = [];

  const secondStyle = useMemo(
    () => (props.row % 2 === 0 ? styles.listItemPrimary : styles.listItemSecondary),
    [props.row, styles]
  );

  const [name, directory, value, tags, children, links, myDataSources, icons] = useMemo(() => {
    const [name, directory, value, tags, children, links, myDataSources, icons] = props.data;

    return [name, directory, value, tags, children, links, myDataSources, icons] as const;
  }, [props.data]);

  const select = useCallback(() => {
    locationService.partial({ 'var-folderSelection': value }, true);
  }, [value]);

  const renderDataSourceIcons = (ds: FieldDataSourceInner, index: number) => {
    if (!shownTargets.includes(ds.target)) {
      shownTargets.push(ds.target);
      return (
        <li key={`datasource-${index}`} className={styles.htmlListItem}>
          <DataSourceIcon uid={ds.uid} target={ds.target} dataSourceList={props.dataSourceList} />
        </li>
      );
    } else {
      return null;
    }
  };

  const rowIcons = Array.from(icons ?? [])
    .sort()
    .map((icon, index) => (
      <li key={`icon-${index}`} className={styles.htmlListItem}>
        <Icon name={icon} title={props.options.iconTitles?.get(icon) ?? ''} />
      </li>
    ));

  return (
    <div className={cx(styles.listItem, secondStyle)}>
      <div
        className={styles.name}
        onClick={() => props.options.wideClick && directory && setOpen(!open)}
        role={props.options.wideClick ? 'button' : ''}
      >
        {props.options.allowSelect && value ? (
          <>
            <Checkbox onClick={select} checked={props.selectedValue === value} />
            &nbsp;{' '}
          </>
        ) : null}
        <span className={styles.icon}>
          <Icon name={directory ? (open ? 'folder-open' : 'folder') : 'file-blank'} />
        </span>
        &nbsp;
        <span>
          {links && links.length ? (
            <DataLinksContextMenu links={() => links}>
              {() => <div className={styles.cellLink}>{name}</div>}
            </DataLinksContextMenu>
          ) : (
            name
          )}
        </span>
      </div>
      {directory ? (
        <>
          <div style={{ height: '100%' }}>
            <IconButton
              name={open ? 'angle-down' : 'angle-right'}
              style={{ height: '100%' }}
              onClick={() => setOpen(!open)}
              aria-label={`Open folder ${name}`}
            />
          </div>
          <div style={{ width: '100%', paddingRight: 0 }}>
            <div></div>
            {open ? (
              <List
                offset={props.row % 2 === 0}
                options={props.options}
                dataFrame={children ?? undefined}
                selectedValue={props.selectedValue}
                fieldConfig={props.fieldConfig}
                dataSourceList={props.dataSourceList}
              />
            ) : null}
          </div>
        </>
      ) : (
        <>
          <ul className={styles.htmlList}>
            {myDataSources.map((ds, index) => renderDataSourceIcons(ds, index)).concat(rowIcons)}
          </ul>
          <ul className={styles.htmlList}>
            {tags.map((tag, index) => (
              <li key={`tag-${index}`} className={styles.htmlListItem}>
                <Tag
                  name={tag}
                  colorIndex={
                    tag === 'Deleted'
                      ? 0
                      : undefined /* If the tag is 'Deleted', we force the color to red, otherwise it would be green by default */
                  }
                />
              </li>
            ))}
          </ul>
        </>
      )}
    </div>
  );
};
