import { css } from '@emotion/css';
import { Button, Form, InlineField, Input, Modal } from '@grafana/ui';
import React, { ChangeEvent, useState } from 'react';
import { NullID } from '../../shared/constants';

export interface SigmaCustomRuleModalProps {
  isOpen?: boolean;

  onCreate?(filename: string): void;

  onCancel?(): void;
}

export interface SigmaCustomRuleModalButtonProps {
  onCreate?(filename: string): void;
}

export const SigmaCustomRuleModal = (props: SigmaCustomRuleModalProps) => {
  const [filename, setFilename] = useState('');
  return (
    <Modal
      title="Create Custom Rule"
      isOpen={props.isOpen}
      closeOnEscape
      closeOnBackdropClick
      onDismiss={() => {
        setFilename('');
        if (props.onCancel) {
          props.onCancel();
        }
      }}
    >
      <Form
        onSubmit={() => {
          if (props.onCreate) {
            // This enables us to de-conflict if a user decides to save the rule to the DB
            props.onCreate(`${NullID}/${filename}`);
            setFilename('');
          }
        }}
        className={css`
          width: 100%;
          max-width: 100%;
        `}
      >
        {() => {
          return (
            <>
              <InlineField label="Filename" grow>
                <Input
                  value={filename}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => setFilename(e.target.value)}
                  autoFocus
                />
              </InlineField>
              For guidance with the filename see the{' '}
              <a
                href="https://sigmahq.github.io/sigma-specification/sigmahq/Sigmahq_filename_rule.html"
                target="_blank"
                rel="noreferrer"
              >
                SigmaHQ website
              </a>
              <Modal.ButtonRow>
                <Button type="submit">Create</Button>
                <Button
                  variant="secondary"
                  onClick={() => {
                    setFilename('');
                    if (props.onCancel) {
                      props.onCancel();
                    }
                  }}
                >
                  Cancel
                </Button>
              </Modal.ButtonRow>
            </>
          );
        }}
      </Form>
    </Modal>
  );
};

export const SigmaCustomRuleModalButton = (props: SigmaCustomRuleModalButtonProps) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <SigmaCustomRuleModal
        isOpen={isOpen}
        onCancel={() => setIsOpen(!isOpen)}
        onCreate={(filename: string) => {
          setIsOpen(!isOpen);
          if (props.onCreate) {
            props.onCreate(filename);
          }
        }}
      />
      <Button variant="secondary" icon="plus" onClick={() => setIsOpen(true)}>
        Custom Rule
      </Button>
    </>
  );
};
