import { DataSourceSettings } from '@grafana/data';
import React from 'react';
import { css } from '@emotion/css';

export type DataSourceIconProps = {
  dataSourceList: Array<DataSourceSettings>;
  uid: string;
  target: string;
};

export const DataSourceIcon = (props: DataSourceIconProps) => {
  const { dataSourceList: dataSources, uid, target } = props;
  const dataSource = dataSources.find((ds) => ds.uid === uid);
  const imgFile = dataSource?.typeLogoUrl ?? '/public/app/plugins/datasource/grafana/img/icn-grafanadb.svg';
  const name = target; // TODO: If we display only one icon per datasource target type, we might want to get all similar DS with a conversion and show all their names in the tooltip

  return (
    <img
      src={imgFile}
      className={css`
        width: 16px;
        height: 16px;
        margin-right: 0.5em;
      `}
      alt={`Converted to ${name}`}
    />
  );
};
