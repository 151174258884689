import { useEffect, useState } from 'react';

export const useBreakPoint = (breakPoint: number) => {
  const [size, setSize] = useState(window.matchMedia(`(min-width: ${breakPoint}px)`).matches);

  useEffect(() => {
    window.matchMedia(`(min-width: ${breakPoint}px)`).addEventListener('change', (e) => {
      setSize(e.matches);
    });
  }, [breakPoint]);

  return [size] as const;
};
