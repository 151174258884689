import { SceneDataTransformer, SceneQueryRunner } from '@grafana/scenes';

export const directoryQueryRunner = () =>
  new SceneQueryRunner({
    datasource: {
      type: 'log-source-config-list',
      uid: 'log-source-config-list',
    },
    queries: [
      {
        refId: 'A',
        JSON: {},
      },
    ],
    queryCachingTTL: 0,
  });

export const logsourceQueryRunner = (logsource: string) =>
  new SceneDataTransformer({
    key: 'logsource-transform',
    $data: directoryQueryRunner(),
    transformations: [
      {
        id: 'filterByValue',
        options: {
          filters: [
            {
              config: {
                id: 'equal',
                options: {
                  value: logsource,
                },
              },
              fieldName: 'id',
            },
          ],
          match: 'any',
          type: 'include',
        },
      },
    ],
  });
