import { Checkbox, IconButton, useStyles2 } from '@grafana/ui';
import React, { ReactNode } from 'react';
import { css } from '@emotion/css';
import { GrafanaTheme2 } from '@grafana/data';

interface TableChildProps {
  children: ReactNode | Array<ReactNode>;
}

interface TableHeaderProps {
  onSelect: () => void;
}

export const Table = (props: TableChildProps) => {
  const styles = useStyles2(getStyles);

  return (
    <div role="table" className={styles.table}>
      {props.children}
    </div>
  );
};

export const TableHeader = ({ onSelect }: TableHeaderProps) => {
  const styles = useStyles2(getStyles);

  return (
    <>
      <IconButton
        name={'times'}
        className={styles.headerCheckbox}
        onClick={() => onSelect()}
        aria-label="clear selection"
        tooltip="Clear selection"
      />
      <div>Name</div>
      <div>Source</div>
      <div>Config</div>
      <div>Actions</div>
    </>
  );
};

export const TableRow = (props: TableChildProps) => {
  return <>{props.children}</>;
};

const getStyles = (theme: GrafanaTheme2) => ({
  table: css({
    display: 'grid',
    gridTemplateColumns: `${theme.spacing.x6} auto 150px auto 120px`,
    gridRowGap: theme.spacing.x2,
  }),
  headerCheckbox: css({
    padding: theme.spacing.x0_25,
    margin: theme.spacing.x0_25,
  }),
});
