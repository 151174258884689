import React, { ChangeEvent, useState } from 'react';
import { AppRootProps, OrgRole, PageLayoutType } from '@grafana/data';
import { DataSourcePicker, PluginPage } from '@grafana/runtime';
import { css } from '@emotion/css';
import { useRole } from 'hooks/useRole';
import { InstallCollapsibleSection } from 'components/install/InstallCollapsibleSection';
import { Button, Field, Input, LoadingPlaceholder, Modal, SecretInput } from '@grafana/ui';
import { DetectSettings } from './AppConfig';
import { useGrafanaState } from 'hooks/useGrafanaState';
import { useDoInstall } from 'hooks/install/useDoInstall';
import { PLUGIN_ID, PRODUCT_NAME } from 'shared/constants';
import { useTranslation } from 'react-i18next';

export const InstallPage = (props: AppRootProps<DetectSettings>) => {
  const [role] = useRole();
  const [hasAuth, hasIncident] = useGrafanaState();
  const { jsonData, secureJsonFields } = props.meta;
  const [init, setInit] = useState(false);
  const [t] = useTranslation();

  /**
   * Settings that need to be configured by the user, or will be pulled from the backend.
   */
  const [sigmaURL, setSigmaURL] = useState<string>(jsonData?.sigmaAPIConfig?.url ?? 'localhost:3020');
  const [serviceURL, setServiceURL] = useState<string>(jsonData?.service?.url ?? 'localhost:3019');
  const [serviceAccessToken, setServiceAccessToken] = useState<string>();
  const [serviceAccessTokenConfigured, setServiceAccessTokenConfigured] = useState<boolean>(
    secureJsonFields?.serviceAccessToken ?? false
  );
  // State for managing integrated dashboards
  const [incidentDSUid, setIncidentDSUid] = useState<string | undefined>(jsonData?.dashboardConfig?.incidentDSUid);
  const [alertingHistoryDSUid, setAlertingHistoryDSUid] = useState<string | undefined>(
    jsonData?.dashboardConfig?.alertingHistoryDSUid
  );

  /**
   * This hook enables the plugin to self install
   */
  const [doInstall] = useDoInstall(
    serviceURL,
    sigmaURL,
    serviceAccessToken,
    incidentDSUid,
    alertingHistoryDSUid,
    jsonData?.dashboardConfig?.alertingDBUid
  );

  const loadingTexts = t('installPage.loadingTexts', { returnObjects: true }) as Array<string>;

  return (
    <PluginPage
      pageNav={{
        text: 'Install',
        subTitle: `Install ${PRODUCT_NAME}`,
        pluginId: PLUGIN_ID,
        active: true,
        isCreateAction: true,
      }}
      layout={PageLayoutType.Custom}
    >
      <div
        className={css`
          margin: 2em auto;
          width: 100%;
          max-width: 800px;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          height: 100%;
          gap: 0.5em;
          & > div {
            width: 100%;
          }
        `}
      >
        <span
          className={css`
            display: flex;
            flex-direction: row;
          `}
        >
          <img
            src="/public/plugins/grafana-detect-app/img/logo.svg"
            className={css`
              width: 32px;
              height: 32px;
              margin-right: 0.5em;
            `}
          />
          <h1>{t('installPage.title')}</h1>
        </span>
        <p>{t('installPage.introParagraph')}</p>
        {role !== OrgRole.Admin ? <p>{t('installPage.requireAdminParagraph')}</p> : null}
        {role === OrgRole.Admin ? (
          <>
            <Modal title={t('installPage.modalTitle')} isOpen={init} trapFocus>
              <LoadingPlaceholder text={loadingTexts[Math.floor(Math.random() * 100) % loadingTexts.length]} />
            </Modal>
            <p>{t('installPage.adminIntro')}</p>
            {!hasAuth ? (
              <InstallCollapsibleSection
                label={t('installPage.sigmaAPIConfig.title')}
                configured={jsonData?.sigmaAPIConfig?.url !== undefined}
              >
                <Field
                  label={t('installPage.sigmaAPIConfig.addressLabel')}
                  description={t('installPage.sigmaAPIConfig.addressDescription')}
                  className={css`
                    width: 100%;
                  `}
                  disabled={hasAuth}
                >
                  <Input
                    value={sigmaURL}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => setSigmaURL(e.target.value)}
                  />
                </Field>
              </InstallCollapsibleSection>
            ) : null}
            {!hasAuth ? (
              <InstallCollapsibleSection
                label={t('installPage.serviceConfig.title')}
                configured={jsonData?.service?.url !== undefined}
              >
                <Field label={t('installPage.serviceConfig.addressLabel')} disabled={hasAuth}>
                  <Input
                    value={serviceURL}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => setServiceURL(e.target.value)}
                  />
                </Field>
                <Field label={t('installPage.serviceConfig.accessTokenLabel')}>
                  <SecretInput
                    value={serviceAccessToken}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => setServiceAccessToken(e.target.value)}
                    isConfigured={serviceAccessTokenConfigured}
                    onReset={() => setServiceAccessTokenConfigured(false)}
                  />
                </Field>
              </InstallCollapsibleSection>
            ) : null}
            {!hasAuth ? (
              <InstallCollapsibleSection
                label={t('installPage.dashboardConfig.title')}
                configured={jsonData?.dashboardConfig?.alertingDBUid !== undefined}
              >
                {hasIncident ? (
                  <Field
                    label={t('installPage.dashboardConfig.incidentDSLabel')}
                    disabled={hasAuth}
                    description={t('installPage.dashboardConfig.incidentDSDetail')}
                  >
                    <DataSourcePicker
                      type="grafana-incident-datasource"
                      current={incidentDSUid}
                      onChange={async (dsRef) => {
                        if (dsRef.type === 'grafana-incident-datasource') {
                          setIncidentDSUid(dsRef.uid);
                        }
                      }}
                    />
                  </Field>
                ) : null}
                <Field
                  label={t('installPage.dashboardConfig.alertingHistoryDSLabel')}
                  disabled={hasAuth}
                  description={t('installPage.dashboardConfig.alertingHistoryDetail')}
                >
                  <DataSourcePicker
                    type="loki"
                    current={alertingHistoryDSUid}
                    onChange={async (dsRef) => {
                      if (dsRef.type === 'loki') {
                        setAlertingHistoryDSUid(dsRef.uid);
                      }
                    }}
                  />
                </Field>
              </InstallCollapsibleSection>
            ) : null}
            <Button
              type="submit"
              onClick={() => {
                setInit(true);
                doInstall();
              }}
            >
              {t('installPage.submit')}
            </Button>
          </>
        ) : null}
      </div>
    </PluginPage>
  );
};
