import React from 'react';
import { Field, Icon, TagList, Tooltip } from '@grafana/ui';
import { LIGHT_BLUE_COLOR_INDEX, RED_COLOR_INDEX } from '../../../shared/constants';
import { css } from '@emotion/css';

interface QueryEditorFieldsProps {
  fields: Array<string>;
  tags: Array<string>;
}

export const QueryEditorFields = ({ fields, tags }: QueryEditorFieldsProps) => {
  return (
    <Field
      label="Detected Fields"
      description={
        <>
          Fields detected from generated queries for matching labels in logs{' '}
          <Tooltip content="Blue: the detected field has a matching label. Red: the detected field doesn't have an equivalent label in the detected labels.">
            <Icon name="info-circle" />
          </Tooltip>
        </>
      }
    >
      <TagList
        tags={fields}
        getColorIndex={(field) => {
          if (tags.includes(field)) {
            return LIGHT_BLUE_COLOR_INDEX;
          }
          return RED_COLOR_INDEX;
        }}
        className={css`
          justify-content: flex-start;
          max-height: 6em;
          overflow-y: scroll;
        `}
      />
    </Field>
  );
};
