import { sceneUtils } from '@grafana/scenes';
import { DataQuery } from '@grafana/schema';
import { SigmaRuleDataSource } from './directory';
import { SigmaRuleMetricDataSource } from './metric';
import { SigmaRuleContentDataSource } from './content';
import { TimeRange } from '@grafana/data';

export { SigmaRuleDataSource, SigmaRuleMetricDataSource };

// SigmaRuleQuery is the standard query structure that grafana uses to communicate with the plugin backend
export interface SigmaRuleQuery extends DataQuery {
  JSON: {
    range: TimeRange;
    type: string;
    prefix: string;
    repo_id: string; //eslint-disable-line camelcase
    deployment: string;
    deletion_state: string; //eslint-disable-line camelcase
    alertingStatus: 'all' | 'include' | 'exclude';
    rule_state: string; //eslint-disable-line camelcase
    contains: string;
  };
  type: string;
  selection: string;
  repo_id: string; //eslint-disable-line camelcase
  deployment: string;
  deletion_state: string; //eslint-disable-line camelcase
  alertingStatus: string;
  rule_state: string; //eslint-disable-line camelcase
  contains: string;
}

export interface SigmaRuleContentQuery extends DataQuery {
  JSON: {
    filename: string;
    repoId: string;
    type: string;
    isDirectory: boolean;
  };
  // We expect these to be a templated query
  file: string;
  repoId: string;
  type: string;
}

try {
  console.log('Registering Runtime DS');
  sceneUtils.registerRuntimeDataSource({
    dataSource: new SigmaRuleDataSource('sigmarules', 'sigmarules'),
  });
  sceneUtils.registerRuntimeDataSource({
    dataSource: new SigmaRuleMetricDataSource('sigmarules-metric', 'sigmarules-metric'),
  });
  sceneUtils.registerRuntimeDataSource({
    dataSource: new SigmaRuleContentDataSource('sigmarules-content', 'sigmarules-content'),
  });
} catch (e) {
  console.error(e);
}
