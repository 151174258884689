import React from 'react';
import {
  CustomVariable,
  SceneComponentProps,
  sceneGraph,
  SceneObjectBase,
  SceneObjectState,
  VariableDependencyConfig,
} from '@grafana/scenes';
import { DetectScenesVariables } from '../../../variables';
import { DEFAULT_MAX_LINES, GREY_COLOR_INDEX } from '../../../../shared/constants';
import { DataSourceApi, getDefaultTimeRange } from '@grafana/data';
import { LokiParserAndLabelKeys } from '../../../../shared/lokiTypes';
import { DetectEvent, DetectEventTypes } from '../../../events';
import { parse } from '../../../../components/scenes/logsource/queries/loki';
import { getDataSourceSrv } from '@grafana/runtime';
import { Field, TagList } from '@grafana/ui';
import { css } from '@emotion/css';

interface LabelListState extends SceneObjectState {}

interface LabelListStateInternal extends LabelListState {
  tags: Array<string>;
}

export class LabelList extends SceneObjectBase<LabelListStateInternal> {
  protected static Component = TagListRenderer;
  private datasourceApi?: DataSourceApi;

  _variableDependency = new VariableDependencyConfig(this, {
    variableNames: [DetectScenesVariables.LogSourceDataSource, DetectScenesVariables.LogSourceQuery],
    onReferencedVariableValueChanged: (variable) => {
      switch (variable.state.name) {
        case DetectScenesVariables.LogSourceDataSource:
          this.getDatasource().then(() => {
            this.fetchLabels({ withParser: false });
          });
          break;
        case DetectScenesVariables.LogSourceQuery:
          this.fetchLabels({ withParser: false });
          break;
      }
    },
  });

  constructor(state: Partial<LabelListState>) {
    super({ ...state, tags: [] });

    this.addActivationHandler(this.activationHandler.bind(this));
  }

  activationHandler() {
    this.getDatasource().then(() => {
      this.fetchLabels({ withParser: false });
    });
    let detectEventSubscription = this.getRoot().subscribeToEvent(DetectEvent, (evt) => {
      switch (evt.payload.type) {
        case DetectEventTypes.EditorFetchLabels:
          this.fetchLabels(evt.payload.payload);
      }
    });

    return () => {
      detectEventSubscription.unsubscribe();
    };
  }

  getDatasource() {
    const datasource = sceneGraph.lookupVariable(DetectScenesVariables.LogSourceDataSource, this) as CustomVariable;
    return getDataSourceSrv()
      .get({ uid: datasource.getValueText() })
      .then((ds) => {
        this.datasourceApi = ds;
      });
  }

  fetchLabels(payload?: { withParser: boolean }) {
    if (!this.datasourceApi) {
      return;
    }

    const queryVariable = sceneGraph.lookupVariable(DetectScenesVariables.LogSourceQuery, this) as CustomVariable;

    const query = queryVariable.getValueText() !== '' ? queryVariable.getValueText() : '{job=~".+"}';

    if (this.datasourceApi.languageProvider === undefined) {
      // Datasource doesn't support labels
      this.setState({ tags: [] });
      return;
    }

    this.datasourceApi.languageProvider
      .getParserAndLabelKeys(query, {
        maxLines: DEFAULT_MAX_LINES,
        timeRange: getDefaultTimeRange(),
      })
      .then((unfiltered: LokiParserAndLabelKeys) => {
        let newQuery = query;
        if (query.trim().endsWith('}')) {
          let parser = 'logfmt';

          if (unfiltered.hasJSON) {
            parser = 'json';
          } else if (unfiltered.hasPack) {
            parser = 'pack';
          }

          newQuery = newQuery + ' | ' + parser;
        }

        if (payload && payload.withParser) {
          this.publishEvent(
            new DetectEvent({
              type: DetectEventTypes.EditorUpdateQuery,
              payload: parse(newQuery),
            }),
            true
          );
        }

        return this.datasourceApi?.languageProvider.getParserAndLabelKeys(newQuery, {
          maxLines: DEFAULT_MAX_LINES,
          timeRange: getDefaultTimeRange(),
        });
      })
      .then((filtered: LokiParserAndLabelKeys) => {
        this.setState({ tags: filtered.extractedLabelKeys });
      });
  }
}

function TagListRenderer({ model }: SceneComponentProps<LabelList>) {
  const { tags } = model.useState();
  return (
    <Field label="Labels">
      <TagList
        tags={tags}
        getColorIndex={() => GREY_COLOR_INDEX}
        className={css`
          justify-content: flex-start;
        `}
      />
    </Field>
  );
}
