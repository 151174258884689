import React from 'react';

interface ProviderWrapperProps {
  children?: React.ReactElement;
  providers: Array<React.ReactElement>;
}

export const ProviderWrapper = (props: ProviderWrapperProps) => {
  const render: Array<React.ReactElement> = [...props.providers];
  if (props.children) {
    render.push(props.children);
  }
  return render.reduceRight((child, Provider) => React.cloneElement(Provider, {}, child));
};
