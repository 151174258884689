import { Alert, Collapse } from '@grafana/ui';
import { DetectErrorContext } from 'contexts/errors';
import { DetectError } from 'contexts/errors/generic';
import React, { useContext, useState } from 'react';

export interface DetectErrorAlertProps {
  error: DetectError;
  id: number;

  onDismiss(id: number): void;
}

export const DetectErrorAlert = (props: DetectErrorAlertProps) => {
  const [stackOpen, setStackOpen] = useState<boolean>(false);
  const { error, id, onDismiss } = props;
  return (
    <Alert
      key={'alert-' + id}
      title={error.title}
      onRemove={() => onDismiss(id)}
      buttonContent="Dismiss"
      severity={error.level}
    >
      {error.body}
      {error.stack ? (
        <Collapse label="Stack Trace" isOpen={stackOpen} collapsible={true} onToggle={() => setStackOpen(!stackOpen)}>
          <>
            {error.stack.split('\n').map((line) => (
              <>
                {line}
                <br />
              </>
            ))}
          </>
        </Collapse>
      ) : null}
    </Alert>
  );
};

export const DetectErrorDisplay = () => {
  const { errorList, dismissError } = useContext(DetectErrorContext);

  return (
    <>
      {errorList.map((err, idx) => {
        return (
          <DetectErrorAlert
            key={`detect-error-alert-${idx}`}
            error={err}
            id={idx}
            onDismiss={(id) => dismissError(id)}
          />
        );
      })}
    </>
  );
};
