import {
  CustomVariable,
  EmbeddedScene,
  SceneAppPage,
  SceneControlsSpacer,
  SceneFlexLayout,
  sceneGraph,
  SceneVariableSet,
  VariableValueSelectors,
} from '@grafana/scenes';
import { PLUGIN_ROOT } from 'shared/constants';
import { VariableHide } from '@grafana/schema';
import { LogSourceEditorObject } from './editorScene';
import { EventButton } from '../event.button';
import { DetectScenesVariables } from '../variables';
import { DetectEventTypes } from '../events';

const getLayout = () => {
  return new LogSourceEditorObject({});
};

const getScene = (): EmbeddedScene => {
  const scene = new EmbeddedScene({
    key: 'logsource-editor',
    body: new SceneFlexLayout({
      children: [getLayout()],
    }),
    controls: [
      new VariableValueSelectors({}),
      new SceneControlsSpacer(),
      new EventButton({
        eventName: DetectEventTypes.EditorUpdate,
        text: 'Save',
        requiredVariables: [DetectScenesVariables.LogSource],
      }),
      new EventButton({
        eventName: DetectEventTypes.EditorCreate,
        text: 'Save As New',
        disabled: false,
      }),
    ],
    $variables: new SceneVariableSet({
      variables: [
        new CustomVariable({
          name: DetectScenesVariables.LogSource,
          hide: VariableHide.hideVariable,
          value: '',
        }),
        new CustomVariable({
          name: DetectScenesVariables.LogSourceDataSource,
          hide: VariableHide.hideVariable,
          value: '',
        }),
        new CustomVariable({
          name: DetectScenesVariables.LogSourceQuery,
          hide: VariableHide.hideVariable,
          value: '',
        }),
        new CustomVariable({
          name: DetectScenesVariables.CustomPipelines,
          hide: VariableHide.hideVariable,
          isMulti: true,
          value: [],
        }),
        new CustomVariable({
          name: DetectScenesVariables.ExtraArgs,
          hide: VariableHide.hideVariable,
          value: '',
        }),
        new CustomVariable({
          name: DetectScenesVariables.LogSourceName,
          hide: VariableHide.hideVariable,
          value: '',
        }),
      ],
    }),
  });

  scene.addActivationHandler(() => {
    let logsource = sceneGraph.lookupVariable(DetectScenesVariables.LogSource, scene) as CustomVariable;
    let logsourceQuery = sceneGraph.lookupVariable(DetectScenesVariables.LogSourceQuery, scene) as CustomVariable;
    let logsourceDataSource = sceneGraph.lookupVariable(
      DetectScenesVariables.LogSourceDataSource,
      scene
    ) as CustomVariable;
    let logsourceName = sceneGraph.lookupVariable(DetectScenesVariables.LogSourceName, scene) as CustomVariable;
    let customPipelines = sceneGraph.lookupVariable(DetectScenesVariables.CustomPipelines, scene) as CustomVariable;
    let extraArgs = sceneGraph.lookupVariable(DetectScenesVariables.ExtraArgs, scene) as CustomVariable;

    return () => {
      logsource.changeValueTo('');
      logsourceQuery.changeValueTo('');
      logsourceDataSource.changeValueTo('');
      logsourceName.changeValueTo('');
      customPipelines.changeValueTo([]);
      extraArgs.changeValueTo('');
    };
  });

  return scene;
};

export const getLogSourceEditor = () =>
  new SceneAppPage({
    title: 'Log Source Editor',
    url: `${PLUGIN_ROOT}/scenes/logsource/editor`,
    titleIcon: 'edit',
    preserveUrlKeys: [],
    getScene,
  });
