import { Spinner } from '@grafana/ui';
import React, { useContext } from 'react';
import { SigmaRulesContext } from '../../stores/sigmarules';
import { StatefulRuleEditorFilenameField } from './FieldFilename';
import { StatefulRuleEditorFieldContent } from './FieldContent';

interface RuleEditorFormProps {
  loading: boolean;
}

export const RuleEditor = (props: RuleEditorFormProps) => {
  if (props.loading) {
    return <Spinner />;
  }

  return (
    <>
      <StatefulRuleEditorFilenameField />
      <StatefulRuleEditorFieldContent />
    </>
  );
};

export const StatefulRuleEditor = () => {
  const { loading } = useContext(SigmaRulesContext);

  return <RuleEditor loading={loading} />;
};
