/**
 * To convert this plugin into a proper plugin, rather than just a runtime panel plugin, we need to:
 *
 * 1. Move this full directory into its own repo
 * 2. Initialize as a plugin
 * 3. Rename this file to module.ts and place in the root of the repo
 */

import { Field, FieldConfig, FieldType, PanelPlugin } from '@grafana/data';
import { FolderPanelOptions } from './types';
import { FolderPanel } from './components/FolderPanel';
import { sceneUtils } from '@grafana/scenes';

export const plugin = new PanelPlugin<FolderPanelOptions, FieldConfig>(FolderPanel)
  .useFieldConfig()
  .setPanelOptions((builder) => {
    return builder
      .addFieldNamePicker({
        name: 'Listing Name',
        path: 'fieldName',
        defaultValue: 'name',
        settings: {
          filter: (field: Field) => field.type === FieldType.string,
        },
      })
      .addBooleanSwitch({
        path: 'allowSelect',
        name: 'Allow Selection',
        defaultValue: false,
      })
      .addFieldNamePicker({
        name: 'Directory Data',
        path: 'fieldSelection',
        defaultValue: 'value',
        showIf: (config) => config.allowSelect,
      })
      .addBooleanSwitch({
        path: 'hasChildren',
        name: 'Nested Data',
        defaultValue: false,
      })
      .addBooleanSwitch({
        path: 'defaultOpenChildren',
        name: 'Show Children by Default',
        defaultValue: true,
      })
      .addFieldNamePicker({
        name: 'Nested Field',
        path: 'fieldChildren',
        defaultValue: 'children',
        settings: {
          filter: (field: Field) => field.type === FieldType.frame,
        },
        showIf: (currentOptions) => currentOptions.hasChildren,
      })
      .addBooleanSwitch({
        path: 'showLabels',
        name: 'Show Labels',
        defaultValue: false,
      })
      .addStringArray({
        path: 'fieldLabels',
        name: 'Exclude fields from labels',
        defaultValue: [],
        showIf: (config) => config.showLabels,
      });
  });

try {
  sceneUtils.registerRuntimePanelPlugin({ pluginId: 'grafana-detect-app-folder-panel', plugin });
} catch (e) {
  console.error(e);
}
