import { Button, Checkbox, FieldSet, InlineField, InlineLabel, Input, TextLink } from '@grafana/ui';
import React from 'react';
import { useMainRepo } from 'hooks/useMainRepo';
import { useForceFetchRepo } from 'hooks/useForceFetchRepo';
export const SigmaRepoForm = () => {
  const [repo, owner, folder, ref, auth, repoId, lastCommit, lastError, getMainRepo] = useMainRepo();
  const [forceFetchRepo] = useForceFetchRepo();

  getMainRepo();

  const doForceFetchRepo = () => {
    forceFetchRepo(repoId);
  };

  return (
    <FieldSet label="Main repository">
      <InlineField label="Repository" grow tooltip={<>The main repository used to fetch sigma rules</>}>
        <Input
          type="text"
          value={owner + '/' + repo + '/' + folder}
          readOnly
          contentEditable="false"
          disabled={true}
        ></Input>
      </InlineField>
      <InlineField
        label="Branch"
        grow
        tooltip={<>The branch used to fetch sigma rules (default generally corresponds to master or main)</>}
      >
        <Input type="text" value={ref} readOnly contentEditable="false" disabled={true}></Input>
      </InlineField>
      <InlineField
        label="Last commit fetched"
        grow
        error={`An error occurred while fetching the repository: ${lastError}`}
        invalid={lastError !== ''}
        tooltip={<>Last commit successfully fetched from the repository</>}
      >
        <InlineLabel>
          {lastCommit !== '' ? (
            <TextLink href={`https://www.github.com/${owner}/${repo}/commit/${lastCommit}`} icon="github" external>
              {lastCommit}
            </TextLink>
          ) : (
            'None'
          )}
        </InlineLabel>
      </InlineField>
      <Checkbox label="Uses authentication" value={auth} disabled={true} readOnly />
      <InlineField>
        <Button variant="secondary" onClick={doForceFetchRepo}>
          Fetch rules now
        </Button>
      </InlineField>
    </FieldSet>
  );
};
