import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { AsyncSelect, Button, Field, Icon, Label, LinkButton, LoadOptionsCallback, QueryField } from '@grafana/ui';
import {
  DataSourceConfigApi,
  DataSourceConfigReadModel,
  ListDataSourceConfig200ResponseModel,
} from '../../../api/detect-service';
import { DetectServiceConfig } from '../../../shared/apiConfigs';
import { getAppEvents, getDataSourceSrv } from '@grafana/runtime';
import { AppEvents, SelectableValue } from '@grafana/data';
import { map } from 'rxjs/operators';
import { switchMap } from 'rxjs';
import { render } from './queries/loki';
import { LokiConfiguration } from './queries/types';

interface LogsourceDropdownProps {
  value?: DataSourceConfigReadModel;

  onChange?(value: DataSourceConfigReadModel): void;
}

export const LogsourceDropdown = ({ value, onChange }: LogsourceDropdownProps) => {
  const [source, setSource] = useState<SelectableValue<string> | undefined>(value ? { value: value?.id } : undefined);
  const [baseQuery, setBaseQuery] = useState('');
  const initialized = useRef(false);

  const logsourceApi = useMemo(() => new DataSourceConfigApi(DetectServiceConfig), []);

  const cb = useCallback(
    (query: string, callback: LoadOptionsCallback<any> | undefined) => {
      logsourceApi
        .listDataSourceConfig({})
        .pipe(
          map<ListDataSourceConfig200ResponseModel, Array<SelectableValue<string>>>((result) => {
            return result.data
              .map((item) => ({
                ...item,
                name: item.name ?? `${item.type} - ${render(item.configuration as LokiConfiguration)}`,
              }))
              .filter((item) => item.name?.includes(query))
              .map((item) => ({
                label: item.name ?? 'Unnamed Log Source Config',
                value: item.id,
                imgUrl: item.uid,
              }));
          }),
          switchMap((res) => {
            return Promise.all(
              res.map((item) =>
                getDataSourceSrv()
                  .get(item.imgUrl)
                  .then((ds) =>
                    Promise.resolve({
                      ...item,
                      imgUrl: '/' + ds.meta.info.logos.small,
                    })
                  )
              )
            );
          })
        )
        .subscribe({
          next: (res) => {
            if (callback) {
              callback(res);
            }
          },
          error: (err) => {
            getAppEvents().publish({
              type: AppEvents.alertError.name,
              payload: ['Failed to fetch log sources (' + err.message + ')'],
            });
          },
        });
    },
    [logsourceApi]
  );

  const updateSource = useCallback(
    (value: SelectableValue<string>) => {
      if (!value.value) {
        return;
      }
      setSource(value);

      logsourceApi.readDataSourceConfig({ id: value.value }).subscribe({
        next: (res) => {
          setBaseQuery(render(res.configuration as LokiConfiguration));
          onChange && onChange(res);
        },
        error: (err) => {},
      });
    },
    [setSource, logsourceApi, onChange]
  );

  useEffect(() => {
    if (initialized.current) {
      return;
    }
    if (!!value && !initialized.current) {
      initialized.current = true;
      getDataSourceSrv()
        .get(value.uid)
        .then((ds) => {
          updateSource({
            value: value.id,
            label:
              value.name ??
              `${value.type} - ${render(value.configuration as LokiConfiguration)}` ??
              'Unknown Datasource',
            imgUrl: ds.meta.info.logos.small,
          });
        });
    }
  }, [value, initialized, updateSource]);

  return (
    <>
      <Field label="Log Source Configuration" description="Select a pre-created log source configuration">
        <>
          <AsyncSelect
            onChange={updateSource}
            defaultOptions
            loadOptions={cb}
            createOptionPosition={'first'}
            value={source}
          />
        </>
      </Field>
      <Field>
        <LinkButton
          size="sm"
          icon="external-link-alt"
          href="/a/grafana-detect-app/scenes/logsource/editor"
          target="_blank"
        >
          New Logsource
        </LinkButton>
      </Field>
      <Field label="Base Query">
        <QueryField portalOrigin={'unavailable'} query={baseQuery} disabled />
      </Field>
    </>
  );
};
