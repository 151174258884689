import React from 'react';
import {
  CustomVariable,
  SceneComponentProps,
  sceneGraph,
  SceneObjectBase,
  SceneObjectState,
  VariableDependencyConfig,
  VariableValueSingle,
} from '@grafana/scenes';
import { Field, Input, Label, Link, Pagination, PanelChrome, Table, TextArea, TextLink } from '@grafana/ui';
import { DataFrame } from '@grafana/data';
import { ConversionApi, ConversionSigmaRulesListModel, DataSourceConfigApi } from 'api/detect-service';
import { DetectServiceConfig } from '../../../shared/apiConfigs';
import { switchMap, map, tap, zip } from 'rxjs';
import { PLUGIN_ROOT } from '../../../shared/constants';

export interface LogSourceDisplaySingleState extends SceneObjectState {
  currentLogSource: string;
  page: number;
  pages: number;
  conversions: Array<{ conversion: string; rules: Array<ConversionSigmaRulesListModel> }>;
}

const defaults: LogSourceDisplaySingleState = {
  currentLogSource: '__all__',
  page: 1,
  pages: 0,
  conversions: [],
};

const LogSourceDisplaySingleRenderer = ({ model }: SceneComponentProps<LogSourceDisplaySingle>) => {
  const { currentLogSource, page, pages, conversions } = model.useState();

  // const logSource = sceneGraph.interpolate(model, currentLogSource);
  const variableState = (sceneGraph.lookupVariable('logsource', model) as CustomVariable).useState();
  const logSource = variableState.value as Array<string>;
  const series = sceneGraph.getData(model).useState().data;
  const dataFrame = series?.series[0];

  if (logSource.length !== 1 || logSource[0] === '__all__' || logSource[0] === '') {
    return undefined;
  }

  if (!series || !dataFrame) {
    return <div>Loading...</div>;
  }

  return (
    <div style={{ flex: 1 }}>
      <PanelChrome title="Log Source Information" loadingState={series.state}>
        <Field label="Name">
          <Input readOnly name={'name'} value={model.getRowField(dataFrame, 0, 'name')} />
        </Field>
        <Field label="CreatedAt">
          <Input
            readOnly
            name={'createdAt'}
            value={new Date(model.getRowField(dataFrame, 0, 'createdAt')).toISOString()}
          />
        </Field>
        <Field label="UpdatedAt">
          <Input
            readOnly
            name={'updatedAt'}
            value={new Date(model.getRowField(dataFrame, 0, 'updatedAt')).toISOString()}
          />
        </Field>
        <Field label="Description">
          <TextArea readOnly value={model.getRowField(dataFrame, 0, 'description')} />
        </Field>
        <div>
          <Label>Converted Rules</Label>
          <div>
            {conversions.map((conversion) => {
              return conversion.rules.map((rule) => (
                <div key={`${conversion.conversion}-${rule.filename}`}>
                  <TextLink href={`${PLUGIN_ROOT}/converter?var-conversionId=${conversion.conversion}`} external>
                    {rule.filename}
                  </TextLink>
                </div>
              ));
            })}
          </div>
          {pages >= 2 && (
            <Pagination currentPage={page} numberOfPages={pages} onNavigate={(page) => model.setPage(page)} />
          )}
        </div>
      </PanelChrome>
    </div>
  );
};

export class LogSourceDisplaySingle extends SceneObjectBase<LogSourceDisplaySingleState> {
  protected static Component = LogSourceDisplaySingleRenderer;

  private _logsourceAPI = new DataSourceConfigApi(DetectServiceConfig);
  private _conversionAPI = new ConversionApi(DetectServiceConfig);

  protected _variableDependency = new VariableDependencyConfig(this, {
    statePaths: ['currentLogSource'],
    variableNames: ['logsource'],
    onReferencedVariableValueChanged: (variable) => {
      if (variable.state.name === 'logsource') {
        this.getConversions();
      }
    },
  });

  constructor(args: Partial<LogSourceDisplaySingleState>) {
    super({ ...defaults, ...args });
    this.addActivationHandler(() => {
      this.getConversions();
    });
  }

  getConversions() {
    // We can assume that logsource will be set as we don't render the scene object if it's not set
    const logsource = sceneGraph.lookupVariable('logsource', this)?.getValue() as Array<string>;
    if (!logsource || logsource.length !== 1) {
      return;
    }
    this._logsourceAPI
      .listDataSourceConfigConversions({
        id: logsource[0],
        itemsPerPage: 10,
        page: this.state.page,
      })
      .pipe(
        tap((data) => {
          this.setState({
            pages: Math.ceil(data.count / 10),
          });
        }),
        map((data) => data.data),
        switchMap((data) =>
          zip(
            ...data.map((item) =>
              this._conversionAPI.listConversionSigmaRules({ id: item.id }).pipe(
                map((data) => ({
                  conversion: item.id,
                  rules: data.data,
                }))
              )
            )
          )
        )
      )
      .subscribe({
        next: (data) => {
          this.setState({
            conversions: data,
          });
        },
        error: (err) => {
          console.error('Cannot retrieve conversions', err);
        },
      });
  }

  getRowField(dataFrame: DataFrame, row: number, field: string): any {
    return dataFrame.fields.find((f) => f.name === field)?.values[row];
  }

  public setPage(toPage: number) {
    this.setState({
      page: toPage,
    });
    this.getConversions();
  }
}
