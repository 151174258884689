/**
 * This store will rarely, if ever, have user interaction, it is designed to provide
 * contextual information to the plugin about where it is running. This is in part
 * to determine whether we are running in a cloud environment or on premise. It also
 * provide information about whether or not the service is deployed.
 */

import React, { createContext, useCallback, useEffect, useState } from 'react';
import { AppPluginMeta, PluginExtension } from '@grafana/data';
import { config, getBackendSrv } from '@grafana/runtime';
import { PLUGIN_ID } from 'shared/constants';
import { useLocation } from 'react-router-dom';
import { DetectSettings } from 'pages/AppConfig';

export const EnvironmentContext = createContext<EnvironmentState>({} as EnvironmentState);

// Export the type of the state, this allows us to know what the state looks like elsewheres
export interface EnvironmentState {
  loading: boolean;
  checks: number;
  servicePresent?: boolean;
  cloudPresent?: boolean;
  authStackPresent?: boolean;
  authState?: {
    loading: boolean;
    stackId?: string;
    region?: string;
  };
  role: string;
  queryParams?: URLSearchParams;
  secretFields: { [key: string]: boolean };
  checkService: () => void;
  checkCloud: () => void;
  getAuthStack: () => void;
  checkAll: () => void;
  __setMeta: (meta?: AppPluginMeta<DetectSettings>) => void;
}

export const EnvironmentProvider = (props: { children: React.ReactNode }) => {
  const location = useLocation();
  const [loading, setLoading] = useState<boolean>(true);
  const [checks, setChecks] = useState<number>(0);
  const [servicePresent, setServicePresent] = useState<boolean>();
  const [cloudPresent, setCloudPresent] = useState<boolean>();
  const [authStackPresent, setAuthStackPresent] = useState<boolean>();
  const [authState, setAuthState] = useState<{ loading: boolean; stackId?: string; region?: string }>();
  const [role, setRole] = useState<string>(config.bootData.user.orgRole);
  const [queryParams, setQueryParams] = useState<URLSearchParams>(new URLSearchParams(location.search));
  const [secretFields, setSecretFields] = useState<{ [key: string]: boolean }>({});

  const getAuthStack = useCallback(() => {
    getBackendSrv()
      .get<{ jsonData: { stack_id: string; region: string } }>(
        `/api/plugins/grafana-auth-app/settings`,
        null,
        undefined,
        {}
      )
      .then((e) => {
        setAuthState({ loading: false, stackId: e.jsonData.stack_id, region: e.jsonData.region });
      })
      .catch((e) => {
        setAuthState({ loading: false });
        setAuthStackPresent(false);
      })
      .finally(() => setLoading(false));
  }, []);

  const checkService = useCallback(() => {
    getBackendSrv()
      .get<{ alive: boolean }>(`/api/plugins/${PLUGIN_ID}/resources/service/v1/db/health`, null, undefined, {})
      .then((e) => setServicePresent(e.alive))
      .catch((e) => setServicePresent(false))
      .finally(() => setLoading(false));
  }, []);

  const checkCloud = useCallback(() => {
    getBackendSrv()
      .get<Array<PluginExtension>>('/api/plugins')
      .then((d) => {
        let auth = d.filter((e) => e.id === 'grafana-auth-app');
        setAuthStackPresent(auth.length > 0);
        if (auth.length > 0) {
          getAuthStack();
        }
        let cloud = d.filter((e) => e.id === 'cloud-home-app');
        setCloudPresent(cloud.length > 0);
      })
      .catch((e) => {
        setAuthStackPresent(false);
        setCloudPresent(false);
      })
      .finally(() => setLoading(false));
  }, [getAuthStack]);

  const checkAll = useCallback(() => {
    setChecks(checks + 1);
    setLoading(true);
    setAuthState(undefined);
    checkService();
    checkCloud();
  }, [checks, checkService, checkCloud]);

  const __setMeta = useCallback((meta?: AppPluginMeta<DetectSettings>) => {
    if (!meta || !meta.secureJsonFields) {
      return;
    }

    const { secureJsonFields } = meta;

    let secretFields: { [key: string]: boolean } = {};

    for (let field of Object.keys(secureJsonFields)) {
      secretFields[field] = true;
    }

    setSecretFields(secretFields);
  }, []);

  useEffect(() => {
    setQueryParams(new URLSearchParams(location.search));
  }, [location.search]);

  useEffect(() => {
    setRole(config.bootData.user.orgRole);
  }, []);

  return (
    <EnvironmentContext.Provider
      value={{
        loading: loading,
        checks: checks,
        servicePresent: servicePresent,
        cloudPresent: cloudPresent,
        authStackPresent: authStackPresent,
        authState: authState,
        role: role,
        queryParams: queryParams,
        secretFields: secretFields,
        checkService: checkService,
        checkCloud: checkCloud,
        getAuthStack: getAuthStack,
        checkAll: checkAll,
        __setMeta: __setMeta,
      }}
    >
      {props.children}
    </EnvironmentContext.Provider>
  );
};
