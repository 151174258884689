import React, { useEffect, useMemo, useState } from 'react';
import { DataSourceSettings, PanelProps, SelectableValue } from '@grafana/data';
import { FolderPanelOptions } from '../types';
import { css, cx } from '@emotion/css';
import { Select, useStyles2 } from '@grafana/ui';
import { dataFrameToRow } from '../utils/dataFrameToRow';
import { ErrorNotifier } from './ErrorNotifier';
import { List } from './List';
import { getBackendSrv, locationService } from '@grafana/runtime';
import { lastValueFrom } from 'rxjs';

interface Props extends PanelProps<FolderPanelOptions> {}

const getStyles = () => {
  return {
    wrapper: css`
      display: flex;
      flex-direction: column;
    `,
    textBox: css`
      padding: 10px;
      width: 100%;
    `,
  };
};

export const FolderPanel: React.FC<Props> = (props: Props) => {
  const selection = useMemo(() => props.replaceVariables(props.options.selection), [props]);
  const styles = useStyles2(getStyles);
  const [dataSourceList, setDataSourceList] = useState<Array<DataSourceSettings>>([]);

  const [currentSeries, setCurrentSeries] = useState<SelectableValue<string>>({
    value: props.data.series[0]?.refId ?? 'A',
    label: props.data.series[0]?.refId ?? 'A',
  });

  useEffect(() => {
    lastValueFrom(
      getBackendSrv().fetch<Array<DataSourceSettings>>({
        method: 'GET',
        url: `/api/datasources`,
        showErrorAlert: false,
      })
    )
      .then((response) => {
        setDataSourceList(response.data);
      })
      .catch((err) => {
        console.error('Could not fetch data sources', err);
        setDataSourceList([]);
      });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const df = useMemo(() => {
    return props.data.series.find((e) => e.refId === currentSeries.value);
  }, [props.data, currentSeries]);

  const series = useMemo(() => {
    const s = props.data.series.filter((e) => e.refId === currentSeries.value);
    if (s.length > 0) {
      return dataFrameToRow(s[0], props.options);
    }
    return undefined;
  }, [currentSeries, props]);

  const selectedValue = useMemo(() => {
    return locationService.getSearch().get('var-folderSelection');
  }, []);

  if (props.width < 500) {
    return <ErrorNotifier errorText="Panel not wide enough to show folder data" />;
  }

  if (!series) {
    return <ErrorNotifier errorText="No data found in response" />;
  }

  return (
    <div
      className={cx(
        styles.wrapper,
        css`
          width: ${props.width}px;
          height: ${props.height}px;
        `
      )}
    >
      <List
        dataFrame={df}
        options={{ ...props.options, selection }}
        selectedValue={selectedValue}
        fieldConfig={props.fieldConfig}
        dataSourceList={dataSourceList}
      />
      {props.data.series.length > 1 ? (
        <div className={styles.textBox}>
          <Select
            onChange={(e) => setCurrentSeries(e)}
            defaultValue={currentSeries}
            options={props.data.series.map((e) => ({ value: e.refId, label: e.refId }))}
          />
        </div>
      ) : null}
    </div>
  );
};
