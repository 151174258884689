import { css } from '@emotion/css';
import { GrafanaTheme2 } from '@grafana/data';
import { Button, useStyles2 } from '@grafana/ui';
import React from 'react';

interface EnableButtonProps {
  enabled?: boolean;
  onClick?(state: boolean): void;
}

export const EnableButton = (props: EnableButtonProps) => {
  const s = useStyles2(getStyles);
  // Force an empty callback incase one isn't set.
  let cback = (state: boolean): void => {};
  if (props.onClick) {
    cback = props.onClick;
  }
  if (props.enabled) {
    return (
      <>
        <div className={s.colorWeak}>The plugin is currently enabled.</div>
        <Button className={s.marginTop} variant="destructive" onClick={() => cback(!props.enabled)}>
          Disable plugin
        </Button>
      </>
    );
  }

  return (
    <>
      <div className={s.colorWeak}>The plugin is currently not enabled.</div>
      <Button className={s.marginTop} variant="primary" onClick={() => cback(!props.enabled)}>
        Enable plugin
      </Button>
    </>
  );
};

const getStyles = (theme: GrafanaTheme2) => ({
  colorWeak: css`
    color: ${theme.colors.text.secondary};
  `,
  marginTop: css`
    margin-top: ${theme.spacing(3)};
  `,
});
