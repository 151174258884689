import React from 'react';
import {
  CustomVariable,
  PanelBuilders,
  SceneComponentProps,
  sceneGraph,
  SceneObjectBase,
  SceneObjectState,
  SceneQueryRunner,
  SceneVariableSet,
  SplitLayout,
  VariableDependencyConfig,
} from '@grafana/scenes';
import { LogSourceEditorPanelObject } from './panels/logSourceEditor';
import { directoryQueryRunner, logsourceQueryRunner } from './queryRunners/directory';
import { DrawStyle, Spinner } from '@grafana/ui';
import { DetectScenesVariables } from '../variables';

interface LogSourceEditorState extends SceneObjectState {}

interface LogSourceEditorStateInternal extends LogSourceEditorState {
  view?: SplitLayout;
}

export class LogSourceEditorObject extends SceneObjectBase<LogSourceEditorStateInternal> {
  protected static Component = Renderer;

  _variableDependency = new VariableDependencyConfig(this, {
    variableNames: [
      DetectScenesVariables.LogSource,
      DetectScenesVariables.LogSourceQuery,
      DetectScenesVariables.LogSourceDataSource,
    ],
    onReferencedVariableValueChanged: (variable) => {
      if (
        variable.state.name === DetectScenesVariables.LogSourceQuery ||
        variable.state.name === DetectScenesVariables.LogSourceDataSource
      ) {
        this.changeContent();
      }
    },
  });

  constructor(args: Partial<LogSourceEditorState>) {
    super({
      ...args,
    });
    this.addActivationHandler(this.activationHandler.bind(this));
  }

  activationHandler() {
    const logsource = sceneGraph.lookupVariable(DetectScenesVariables.LogSource, this)?.getValue() as string;
    this.setState({
      view: new SplitLayout({
        direction: 'row',
        initialSize: 0.3,

        primary: new LogSourceEditorPanelObject({
          $data: logsourceQueryRunner(logsource),
          $variables: new SceneVariableSet({
            variables: [],
          }),
        }),
        secondary: new SplitLayout({
          direction: 'column',
          initialSize: 0.33,
          primary: PanelBuilders.timeseries()
            .setData(directoryQueryRunner())
            .setCustomFieldConfig('drawStyle', DrawStyle.Bars)
            .setCustomFieldConfig('fillOpacity', 100)
            .setTitle('Log Volume')
            .build(),
          secondary: PanelBuilders.table().setTitle('Log Sample').setData(directoryQueryRunner()).build(),
        }),
      }),
    });
    this.changeContent();
  }

  changeContent() {
    const logsourceQuery = sceneGraph.lookupVariable(DetectScenesVariables.LogSourceQuery, this)?.getValue() as string;
    const logsourceDatasource = sceneGraph
      .lookupVariable(DetectScenesVariables.LogSourceDataSource, this)
      ?.getValue() as string;

    const layout = this.state.view!.state.secondary as SplitLayout;
    layout.state.primary.setState({
      $data: new SceneQueryRunner({
        datasource: {
          uid: logsourceDatasource,
        },
        maxDataPoints: 100,
        queries: [
          {
            refId: 'A',
            expr: `sum by (level) (count_over_time(${logsourceQuery} [$__auto]))`,
          },
        ],
      }),
    });
    layout.state.secondary?.setState({
      $data: new SceneQueryRunner({
        datasource: {
          uid: logsourceDatasource,
        },
        queries: [
          {
            refId: 'A',
            expr: logsourceQuery,
            maxLines: 100,
          },
        ],
      }),
    });
  }
}

function Renderer({ model }: SceneComponentProps<LogSourceEditorObject>) {
  const { view } = model.useState();
  if (!view) {
    return (
      <>
        Loading...
        <Spinner />
      </>
    );
  }
  return <view.Component model={view} />;
}
