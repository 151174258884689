import { DataFrame } from '@grafana/data';
import { RenderDecisionData } from '../types';

export const renderData = (df: Array<DataFrame>): RenderDecisionData => {
  if (df.length < 1) {
    return {
      canRender: false,
      renderType: 'file',
      reason: 'No series found in selection',
    };
  } else if (df.length > 1) {
    return {
      canRender: false,
      renderType: 'file',
      reason: 'Too many series found in selection',
    };
  }

  const data = df[0];

  if (data.length < 1) {
    return {
      canRender: false,
      renderType: 'file',
      reason: 'Please select a rule or directory',
    };
  } else if (data.length > 1) {
    return {
      canRender: false,
      renderType: 'file',
      reason: 'Please select a rule or directory',
    };
  }

  return {
    canRender: true,
    renderType: data.fields.filter((field) => field.name === 'filename').length === 1 ? 'file' : 'directory',
  };
};
