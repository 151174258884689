import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { enGBTranslation, enTranslation } from './i18n_en';
import Detector from 'i18next-browser-languagedetector';

export const i18nInitialize = () => {
  if (i18n.isInitialized) {
    return;
  }

  i18n
    .use(initReactI18next)
    .use(Detector)
    .init({
      resources: {
        en: { translation: enTranslation },
        'en-GB': { translation: enGBTranslation },
      },
      fallbackLng: 'en',
      interpolation: {
        escapeValue: false,
      },
    })
    .then(() => {
      console.log('i18n successfully loaded');
    });
};
