import * as React from 'react';
import { AppRootProps } from '@grafana/data';
// import { Global, css } from '@emotion/react';
import { PluginPage } from '@grafana/runtime';

export function Infrastructure(props: AppRootProps) {
  return (
    <PluginPage
      pageNav={{ text: 'Cloud Infra', subTitle: 'Identify your cloud infrastructure', hideFromBreadcrumbs: true }}
    >
      <p>Identify your cloud infrastructure!</p>
    </PluginPage>
  );
}
