import React, { useContext, useEffect, useMemo, useState } from 'react';
import { ButtonCascader, CascaderOption, LinkButton } from '@grafana/ui';
import { ConversionContext } from 'stores/conversion';
import { combineLatest } from 'rxjs';
import { useGrafanaAlertGroup } from 'hooks/useGrafanaAlertGroup';
import { AlertGroup } from 'shared/alertingTypes';
import { useTranslation } from 'react-i18next';

interface AlertCreateButtonProps {
  disabled: boolean;
  options: Array<CascaderOption>;
  alertID: string;

  onCreateAlert(event: any): void;
}

interface StatefulAlertCreateButtonProps {
  namespace: string;

  onCreateAlert(event: any): void;
}

export const AlertCreateButton = (props: AlertCreateButtonProps) => {
  const { t } = useTranslation();

  if (props.alertID) {
    const returnPath = encodeURIComponent(window.location.pathname + window.location.search);
    return (
      <ButtonCascader
        options={[
          {
            label: t('converter.alerting.see-alert'),
            value: 'see-alert',
          },
          ...props.options,
        ]}
        icon="bell"
        variant="secondary"
        disabled={props.disabled}
        onChange={(groupnames, options) => {
          switch (options[0].value) {
            case 'see-alert':
              window.open(`/alerting/grafana/${props.alertID}/view?returnTo=${returnPath}`);
              break;
            default:
              props.onCreateAlert({ groupname: groupnames[0], option: options[0] });
              break;
          }
        }}
      >
        {t('converter.alerting.button')}
      </ButtonCascader>
    );
  }
  return (
    <ButtonCascader
      options={props.options}
      disabled={props.disabled}
      icon="bell"
      variant="secondary"
      onChange={(groupnames, options) => props.onCreateAlert({ groupname: groupnames[0], option: options[0] })}
    >
      {t('converter.alerting.createButton')}
    </ButtonCascader>
  );
};

export const StatefulAlertCreateButton = (props: StatefulAlertCreateButtonProps) => {
  const [disabled, setDisabled] = useState<boolean>(true);
  const [namespace] = useState<string>(props.namespace);
  const [groupnames] = useGrafanaAlertGroup(namespace);
  const [alertID, setAlertID] = useState<string>('');

  const { t } = useTranslation();

  const options = useMemo<Array<CascaderOption>>(() => {
    const defaultGroups: Array<AlertGroup> = [
      { name: t('converter.alerting.minute'), interval: '1m', rules: [] },
      { name: t('converter.alerting.fiveMinute'), interval: '5m', rules: [] },
      { name: t('converter.alerting.hour'), interval: '1h', rules: [] },
      { name: t('converter.alerting.sixHours'), interval: '6h', rules: [] },
      { name: t('converter.alerting.day'), interval: '1d', rules: [] },
    ];
    const finalGroups = [...(groupnames ?? [])];
    defaultGroups.forEach((emptyGroup) => {
      if (
        groupnames === undefined ||
        groupnames?.map((group) => group.interval !== emptyGroup.interval).reduce((a, b) => a && b, true)
      ) {
        finalGroups.push(emptyGroup);
      }
    });
    return finalGroups.map((group) => ({
      label: group.name + (group.interval ? ' [' + group.interval + ']' : ''),
      value: group.name,
      interval: group.interval,
    }));
  }, [t, groupnames]);

  const {
    data: { queries: stateQueries, datasource: stateDatasource, alertID: stateAlertID },
  } = useContext(ConversionContext);

  // Effect for state subscription
  useEffect(() => {
    // Combine latest ensures that all observables provide a value (through next) before emitting
    combineLatest({
      stateQueries,
      stateDatasource,
    }).subscribe((e) => {
      setDisabled(!e.stateQueries || !e.stateDatasource);
    });
    stateAlertID.subscribe(setAlertID);
  }, [stateQueries, stateDatasource, stateAlertID]);

  return <AlertCreateButton {...props} options={options} disabled={disabled} alertID={alertID} />;
};
