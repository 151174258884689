import { DataQueryRequest, DataSourceApi, ExploreUrlState, isDateTime, urlUtil } from '@grafana/data';
import { DataQuery } from '@grafana/schema';
import { omit } from 'lodash';

export interface GetExploreUrlArguments {
  request: DataQueryRequest;
  datasource: DataSourceApi;
}

/**
 * Returns an Explore-URL for a specified DataQueryRequest.
 */
export function getExploreUrl(args: GetExploreUrlArguments): string | undefined {
  const { request, datasource } = args;

  /** In Explore, we don't have legend formatter and we don't want to keep
   * legend formatting as we can't change it
   */
  let exploreTargets: Array<DataQuery> = request.targets.map((t) => omit(t, 'legendFormat'));
  let url: string | undefined;

  if (datasource) {
    const range = request.range.raw;

    if (isDateTime(range.from)) {
      range.from = range.from.valueOf().toString();
    }
    if (isDateTime(range.to)) {
      range.to = range.to.valueOf().toString();
    }

    let state: Partial<ExploreUrlState> = { range: { to: range.to, from: range.from } };
    if (datasource.interpolateVariablesInQueries) {
      const scopedVars = request.scopedVars || {};
      state = {
        ...state,
        datasource: datasource.name,
        queries: datasource.interpolateVariablesInQueries(exploreTargets, scopedVars),
      };
    } else {
      state = {
        ...state,
        datasource: datasource.name,
        queries: exploreTargets,
      };
    }

    const exploreState = JSON.stringify(state);
    url = urlUtil.renderUrl('/explore', { left: exploreState });
  }

  return url;
}
