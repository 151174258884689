import React from 'react';
import { SceneComponentProps, sceneGraph, SceneObjectBase, SceneObjectState } from '@grafana/scenes';
import { LoadingPlaceholder } from '@grafana/ui';
import { PipelineList } from '../../shared/requests/pipelines';
import { PipelineEditorMenu } from '../pipelineEditor/PipelineMenu';
import { getDetectStores } from '../../scenes/utils';
import { DetectStateObject } from '../../scenes/stateObject';

export interface PipelineMenuState extends SceneObjectState {
  loading: boolean;
  pipelines: PipelineList;
  pipelineSelection?: string;
}

// Pipeline manages the local state and data attached to the component
export class PipelineMenu extends SceneObjectBase<PipelineMenuState> {
  constructor(state: Partial<PipelineMenuState>) {
    super({
      loading: false,
      pipelines: { page: 1, data: [], count: 1, pageSize: 1, returned: 0 },
      ...state,
    });
    this.addActivationHandler(this._onActivate.bind(this));
  }

  _onActivate() {
    const stateObject: DetectStateObject | null = sceneGraph.findObject(
      this,
      (e) => e instanceof DetectStateObject
    ) as DetectStateObject;
    if (stateObject != null) {
      let psub = stateObject.pipelineState.data.list?.subscribe((p) => {
        this.setPipelines(p);
        this.setLoading(false);
      });
      let ssub = stateObject.pipelineState.data.get?.subscribe((p) => {
        this.setState({ pipelineSelection: p.id });
      });

      if (this.state.pipelines.data === undefined) {
        stateObject.pipelineState.operations.list(1);
      }

      return () => {
        psub?.unsubscribe();
        ssub?.unsubscribe();
      };
    }

    return () => {};
  }

  setLoading = (loading: boolean) => {
    this.setState({ loading });
  };

  setPipelines = (pipelines: PipelineList) => {
    this.setState({ pipelines });
  };

  static Component = PipelineMenuRenderer;
}

// PipelineMenuRenderer is the rendering component for the object
function PipelineMenuRenderer({ model }: SceneComponentProps<PipelineMenu>) {
  if (model.useState().loading) {
    return <LoadingPlaceholder text="Loading Pipelines..." />;
  }

  const DetectState = getDetectStores(model);

  return (
    <PipelineEditorMenu
      scenes
      pipelines={model.useState().pipelines}
      onSelect={(id) => DetectState.pipelineState?.operations.get(id)}
      onCreate={() => console.log('Create')}
    />
  );
}
