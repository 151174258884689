import React, { useContext, useEffect, useState } from 'react';
import { Button, ControlledCollapse, Collapse, Stack } from '@grafana/ui';
import { css } from '@emotion/css';
import { useRulesContent } from 'hooks';
import { StatefulQueryEffectButton } from './converter/QueryEffectButton';
import { useMonaco } from '@monaco-editor/react';
import { suggestions } from 'utils/autocomplete/rules';
import { StatefulConversionEditor } from './converter/ConversionEditor';
import { HorizontalContainer } from './containers/Horizontal';
import { StatefulConversionResult } from './converter/ConversionResult';
import { StatefulOverrideAlertGroupModal } from './converter/OverrideAlertGroupModal';
import { PageContainer } from './containers/PageContainer';
import { StatefulFieldTargets } from './converter/FieldTargets';
import { FieldCustomPipeline } from './converter/FieldCustomPipeline';
import { StatefulFieldActivePipelines } from './converter/FieldActivePipelines';
import { PipelineContext } from 'stores/pipelines';
import { ConversionContext } from 'stores/conversion';
import { AlertContext } from 'stores/alerts';
import { AppEvents, DataQueryError, DataQueryResponseData } from '@grafana/data';
import { EXECUTION_TIME, PRODUCT_NAME, TOTAL_LINES_PROCESSED } from 'shared/constants';
import { TOTAL_BYTES_PROCESSED } from '../shared/constants';
import { QueryResult } from '../shared/types';
import { StatefulTestQueryResult } from './converter/TestQueryResult';
import { StatefulAlertCreateButton } from './converter/AlertCreateButton';
import { StoreConvertResponse } from 'shared/requests/conversions';
import { StatefulDashboardCreateButton } from './converter/DashboardCreateButton';
import { StatefulFieldExtraArgs } from './converter/ExtraArgsField';
import { useForm } from 'react-hook-form';
import { LabelSelectorModal } from './LabelSelectorModal';
import { LokiParserAndLabelKeys } from 'shared/lokiTypes';
import { getAppEvents } from '@grafana/runtime';
import { useTranslation } from 'react-i18next';
import { AlertConfigModal } from './converter/AlertConfigModal';

export default function Converter(args: any) {
  const monaco = useMonaco();
  const { t } = useTranslation();
  const {
    data: pipelineData,
    operations: { list: doListPipelines },
  } = useContext(PipelineContext);
  const {
    data: {
      datasource: stateDatasource,
      conversionId: stateConversionId,
      alertGroup: stateAlertGroup,
      queries: conversionStateQueries,
      queryResults: stateQueryResults,
      queryErrors: stateQueryErrors,
      processedQueryResults: stateProcessedQueryResults,
      rules: stateRules,
      storeConvertResponse: stateConvertResponse,
      lokiParserAndLabelKeys: stateLokiParserAndLabelKeys,
    },
    operations: {
      listTargets: doListTargets,
      appendRules: doAppendRules,
      convert: storeDoConvert,
      getQueryResults: doGetQueryResults,
      setFields: doSetFields,
      getLokiParserAndLabelKeys: doGetLokiParserAndLabelKeys,
      setAlertID: doSetAlertID,
      setAlertGroup: doSetAlertGroup,
      setDashboardID: doSetDashboardID,
    },
  } = useContext(ConversionContext);
  const {
    operations: { createAlert: doCreateAlert, createDashboard: doCreateDashboard },
    config: { namespace: stateNamespace, folderUid: stateFolderUid },
  } = useContext(AlertContext);
  // The first argument normally contains the side-loaded rules, however, in this instance we want to use
  // the notification from the hook which will tell the rule parser that the user has selected new rules.
  // If we can update this in the future to avoid the "notify" then all the better, but for now this is a
  // workaround.
  const [_, loading, requestRules] = useRulesContent({
    notify: doAppendRules,
  });

  const [isOpen, setIsOpen] = useState(false);
  const [isSelectorOpen, setIsSelectorOpen] = useState(false);
  const [isAlertConfigOpen, setIsAlertConfigOpen] = useState(false);
  const [labels, setLabels] = useState<Array<string>>([]);
  const [alertGroup, setAlertGroup] = useState<string>();

  const { register, handleSubmit } = useForm({});

  useEffect(() => {
    doListPipelines(1);
    doListTargets();
  }, [doListPipelines, doListTargets]);

  useEffect(() => {
    if (!monaco) {
      return;
    }
    monaco.languages.registerCompletionItemProvider('yaml', {
      provideCompletionItems: (model, pos) => {
        return {
          suggestions: suggestions(model, pos),
          incomplete: true,
        };
      },
    });
  }, [monaco]);

  useEffect(() => {
    if (!stateConvertResponse) {
      return;
    }

    stateConvertResponse?.subscribe((response: StoreConvertResponse | undefined) => {
      if (!response) {
        return;
      }

      const keys = Object.keys(response.queries || {});
      if (keys.length === 0) {
        getAppEvents().publish({
          type: AppEvents.alertError.name,
          payload: [t('errors.sigma.noQueries')],
        });
        return;
      }

      const fields = keys.map((key: string): Array<string> => {
        if (response.queries?.hasOwnProperty(key) && response.queries?.[key].fields !== undefined) {
          return response.queries[key].fields || [];
        } else {
          return [];
        }
      });

      if (fields.length > 0 && fields !== undefined) {
        doSetFields(fields);
      }
    });
  }, [stateConvertResponse, doSetFields, t]);

  useEffect(() => {
    if (!stateQueryErrors) {
      return;
    }

    stateQueryErrors?.subscribe((errors: Array<DataQueryError>) => {
      if (errors && errors.length > 0) {
        const messages = errors?.map((error) => error.message).join(' AND ');
        getAppEvents().publish({
          type: AppEvents.alertError.name,
          payload: [t('errors.sigma.errorQuery'), messages ?? 'Unknown Error'],
        });
      }
    });
  }, [stateQueryErrors, t]);

  useEffect(() => {
    if (!stateQueryResults) {
      return;
    }

    stateQueryResults?.subscribe((result: Array<DataQueryResponseData>) => {
      if (!result) {
        return;
      }

      if (result && Array.isArray(result) && result.length > 0) {
        doGetQueryResults([TOTAL_LINES_PROCESSED, TOTAL_BYTES_PROCESSED, EXECUTION_TIME]);
      }
    });

    stateProcessedQueryResults?.subscribe((result: Array<QueryResult>) => {
      if (result.length > 0) {
        setIsOpen(true);
      }
    });
  }, [stateQueryResults, doGetQueryResults, stateProcessedQueryResults, setIsOpen]);

  useEffect(() => {
    if (!stateLokiParserAndLabelKeys) {
      return;
    }

    stateLokiParserAndLabelKeys?.subscribe((result: LokiParserAndLabelKeys | null) => {
      if (!result) {
        return;
      }

      setLabels(result.extractedLabelKeys || []);
    });
  }, [stateLokiParserAndLabelKeys]);

  useEffect(() => {
    if (!stateAlertGroup) {
      return;
    }

    stateAlertGroup?.subscribe((result: string) => {
      if (!result) {
        return;
      }

      setAlertGroup(result);
    });
  }, [stateAlertGroup]);

  return (
    <form onSubmit={handleSubmit(() => storeDoConvert(pipelineData.multiContent))}>
      <>
        <StatefulOverrideAlertGroupModal />
        <PageContainer>
          <div
            className={css`
              display: flex;
              flex-direction: row;
              justify-content: center;
              width: 100%;
              max-width: 100%;
            `}
          >
            <div
              className={css`
                width: 100%;
                margin: 0 auto;
                overflow-y: auto;
              `}
            >
              <HorizontalContainer
                breakPoint={1200}
                className={css`
                  width: 100%;
                  overflow-y: auto;
                `}
              >
                <StatefulConversionEditor loading={loading} requestRules={requestRules} />
                <StatefulConversionResult />
              </HorizontalContainer>
            </div>
          </div>
          <Stack direction="column" grow={1}>
            <ControlledCollapse label="Advanced settings">
              <>
                <StatefulFieldTargets />
                <FieldCustomPipeline />
                <StatefulFieldActivePipelines />
                <StatefulFieldExtraArgs />
              </>
            </ControlledCollapse>
          </Stack>
          <Stack direction="row" justifyContent="flex-start" grow={1}>
            <Stack direction="column">
              <Stack direction="row">
                <Button type="submit" variant="primary" icon="sync">
                  Convert
                </Button>
                <StatefulQueryEffectButton
                  type="test-detect"
                  onCreateAlert={() =>
                    doCreateAlert(conversionStateQueries, stateDatasource, stateConversionId, stateRules)
                  }
                />
                <StatefulQueryEffectButton
                  type="test-explore"
                  onCreateAlert={() =>
                    doCreateAlert(conversionStateQueries, stateDatasource, stateConversionId, stateRules)
                  }
                />
                <AlertConfigModal
                  namespace={stateNamespace ?? PRODUCT_NAME}
                  groupname={alertGroup ?? undefined}
                  isOpen={isAlertConfigOpen}
                  onCancel={() => setIsAlertConfigOpen(false)}
                  onDismiss={() => setIsAlertConfigOpen(false)}
                  onSelect={(group, level, lookback) => {
                    setIsAlertConfigOpen(false);
                    doCreateAlert(
                      conversionStateQueries,
                      stateDatasource,
                      stateConversionId,
                      stateRules,
                      group,
                      lookback,
                      level,
                      (data) => {
                        doSetAlertID(data.data.alert_id ?? '');
                        doSetAlertGroup(group.name);
                      }
                    );
                  }}
                />
                <StatefulAlertCreateButton
                  namespace={stateNamespace ?? PRODUCT_NAME}
                  onCreateAlert={(e) => setIsAlertConfigOpen(true)}
                />
                <LabelSelectorModal
                  isOpen={isSelectorOpen}
                  onDismiss={() => setIsSelectorOpen(false)}
                  onCancel={() => setIsSelectorOpen(false)}
                  onSelect={(selectedLabels: Array<string>) => {
                    setIsSelectorOpen(false);
                    doCreateDashboard(
                      conversionStateQueries,
                      stateDatasource,
                      stateConversionId,
                      stateRules,
                      selectedLabels,
                      (data) => {
                        if ('uid' in data.data) {
                          doSetDashboardID(data.data.uid);
                        }
                      }
                    );
                  }}
                  labels={labels}
                />
                <StatefulDashboardCreateButton
                  onCreateDashboard={(e) => {
                    doGetLokiParserAndLabelKeys();
                    setIsSelectorOpen(true);
                  }}
                />
              </Stack>
            </Stack>
          </Stack>
          <div
            className={css`
              margin-top: 10px;
            `}
          >
            <Stack direction="column" grow={1}>
              <Collapse
                label="Test Query Results"
                collapsible
                isOpen={isOpen}
                onToggle={() => {
                  setIsOpen(!isOpen);
                }}
              >
                <StatefulTestQueryResult />
              </Collapse>
            </Stack>
          </div>
        </PageContainer>{' '}
      </>
    </form>
  );
}
