import { CoreApp, DataSourceApi } from '@grafana/data';
import { getDataSourceSrv, DataSourcePicker } from '@grafana/runtime';
import { DataQuery } from '@grafana/schema';
import { Button, Field, Icon, Label, QueryField, TagList, Tooltip } from '@grafana/ui';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { parse, render } from './queries/loki';
import { LokiConfiguration, NewLokiConfiguration } from './queries/types';
import { QueryEditorTags } from './queryEditorTags';
import { QueryEditorFields } from './queryEditorFields';

export interface QueryEditorProps {
  datasource?: string;
  query?: string;

  tags?: Array<string>;
  fields?: Array<string>;
  embedded?: boolean;

  onChange?(val: LokiConfiguration): void;

  onChangeDatasource?(val: DataSourceApi): void;

  onFetchLabels?(): void;
}

export const QueryEditor = ({
  datasource,
  query,
  tags = [],
  fields = [],
  embedded,
  onChange,
  onChangeDatasource,
  onFetchLabels,
}: QueryEditorProps) => {
  const [dataSourceApi, setDataSourceApi] = useState<DataSourceApi>();
  const [mainQuery, setMainQuery] = useState<string | undefined>(query);
  const [_query, _setQuery] = useState<string>('{job=~".+"}');
  const [_mode, _setMode] = useState<'builder' | 'code'>('code');

  const dataQuery = useMemo<DataQuery>(
    () =>
      ({
        refId: 'A',
        expr: mainQuery ?? query ?? _query,
        editorMode: _mode,
      } as DataQuery),
    [mainQuery, query, _query, _mode]
  );

  const parseAndSetQuery = useCallback(
    (query: DataQuery) => {
      // @ts-ignore
      setMainQuery(query.expr);
      // @ts-ignore
      let parsed: NewLokiConfiguration = parse(query.expr);
      // @ts-ignore
      _setQuery(render(parsed));
      // @ts-ignore
      _setMode(query.editorMode);

      if (onChange) {
        onChange(parsed);
      }
    },
    [onChange]
  );

  const setDataSource = useCallback(
    (uid: string) => {
      getDataSourceSrv()
        .get(uid)
        .then((ds) => {
          setDataSourceApi(ds);
          if (onChangeDatasource) {
            onChangeDatasource(ds);
          }
        });
    },
    [onChangeDatasource]
  );

  /**
   * If the query changes outside of the component we need to update the internal state
   */
  useEffect(() => {
    if (query && query !== '{}') {
      setMainQuery(query);
      _setQuery(query);
    }
  }, [query]);

  useEffect(() => {
    if (!datasource) {
      let datasources = getDataSourceSrv().getList({
        type: 'loki',
        pluginId: 'loki',
      });
      if (datasources.length > 0) {
        setDataSource(datasources[0].uid);
      }
      return;
    } else {
      setDataSource(datasource);
    }
  }, [datasource, setDataSource]);

  const fieldSet = useMemo(() => new Set(fields), [fields]);

  // Ensure used tags are ordered first
  const tagList = useMemo(() => {
    return [...tags].sort((a, b) => {
      let gotA = fieldSet.has(a),
        gotB = fieldSet.has(b);
      return gotA < gotB ? 1 : gotA === gotB ? 0 : -1;
    });
  }, [tags, fieldSet]);

  return (
    <>
      {!embedded && (
        <DataSourcePicker
          current={datasource}
          onChange={(instanceSettings) => setDataSource(instanceSettings.uid)}
          pluginId="loki"
          type="loki"
          width={24}
        />
      )}
      {dataSourceApi && dataSourceApi.components && dataSourceApi.components.QueryEditor && (
        <>
          <dataSourceApi.components.QueryEditor
            datasource={dataSourceApi}
            onChange={parseAndSetQuery}
            onRunQuery={() => {}}
            query={dataQuery}
            app={CoreApp.Explore}
          />
          <Label description="The query passed to SigmaHQ for conversion">Effective Query</Label>
          <QueryField portalOrigin="" disabled query={_query}></QueryField>
          {!embedded && (
            <Button
              size="sm"
              onClick={() => {
                onFetchLabels && onFetchLabels();
              }}
            >
              Fetch Labels
            </Button>
          )}
          {!embedded && tags && <QueryEditorTags tags={tagList} fields={fields} />}
          {!embedded && fields && <QueryEditorFields fields={fields} tags={tags} />}
        </>
      )}
    </>
  );
};
