/**
 * FilterNode is either a lineFilter or Matcher that enables us to resolve to a string.
 */
export interface FilterNode {
  get raw(): string;

  get pipe(): string;
}

/**
 * LokiFilterMatch represents a matcher or line filter in the query that can be used to filter logs.
 */
export class LokiFilterMatch implements FilterNode {
  label: string;
  op: string;
  value: string;

  constructor(label: string, op: string, value: string) {
    this.label = label;
    this.op = op;
    this.value = value;
  }

  get raw(): string {
    return `${this.label}${this.op}${this.value}`;
  }

  get pipe(): string {
    return `| ${this.label} ${this.op} ${this.value}`;
  }
}

export class LokiLineFilter implements FilterNode {
  op: string;
  value: string;

  constructor(op: string, value: string) {
    this.op = op;
    this.value = value;
  }

  get raw(): string {
    return `${this.op}${this.value}`;
  }

  get pipe(): string {
    return this.raw;
  }
}

export class LokiParserFilter implements FilterNode {
  parser: string;
  extra: string;

  constructor(parser: string, extra: string) {
    this.parser = parser;
    this.extra = extra;
  }

  get raw(): string {
    return `${this.parser}${this.extra ? ' ' + this.extra : ''}`;
  }

  get pipe(): string {
    return `| ${this.raw}`;
  }
}

export type LokiConfiguration = OldLokiConfiguration | NewLokiConfiguration;

export type OldLokiConfiguration = {
  filters: string;
  parserConfig: string;
};

export type NewLokiConfiguration = {
  selector: string;
  extra: string;
};
