import React, { ChangeEvent, useContext, useEffect, useState } from 'react';
import { Field, Input } from '@grafana/ui';
import { SigmaRulesContext } from '../../stores/sigmarules';
import { useQueryParams } from '../../hooks/useQueryParams';

interface RuleEditorFilenameFieldProps {
  filename: string;

  onChange(filename: string): void;
}

/**
 * This component is a stateless component, it is used to check that we're displaying the correct data
 * based on the input signature and to render the correct information. The stateful wrapper below
 * wraps this in the context that the React application provides.
 *
 * @param props {RuleEditorFilenameFieldProps}
 * @constructor
 */
export const RuleEditorFilenameField = (props: RuleEditorFilenameFieldProps) => {
  return (
    <Field label="Filename" description="The filepath to associate with the content below">
      <Input
        id="sigma-rule:filename"
        value={props.filename}
        onChange={(v: ChangeEvent<HTMLInputElement>) => props.onChange(v.target.value)}
      />
    </Field>
  );
};

export const StatefulRuleEditorFilenameField = () => {
  const [qs] = useQueryParams();
  const [filename, setFilename] = useState<string>('');
  const {
    data: { createModel },
    operations: { updateCreateAttributes },
  } = useContext(SigmaRulesContext);

  useEffect(() => {
    createModel.subscribe((model) => {
      setFilename(model.filename);
    });
  }, [createModel]);

  useEffect(() => {
    if (qs && qs.has('filename')) {
      updateCreateAttributes({
        filename: qs.get('filename') ?? '',
      });
    } else {
      updateCreateAttributes({
        filename: '',
      });
    }
  }, [qs, updateCreateAttributes]);

  return (
    <RuleEditorFilenameField
      filename={filename}
      onChange={(e) => {
        updateCreateAttributes({
          filename: e,
        });
      }}
    />
  );
};
