import {
  ConstantVariable,
  EmbeddedScene,
  SceneAppPage,
  SceneQueryRunner,
  SceneVariableSet,
  VariableValueSelectors,
  VizPanel,
  CustomVariable,
  SplitLayout,
  SceneFlexItem,
  SceneControlsSpacer,
  QueryVariable,
  SceneRefreshPicker,
  SceneTimePicker,
  SceneTimeRange,
  TextBoxVariable,
} from '@grafana/scenes';
import { CONVERSION_LOCATION_TEMPLATE, LOCATION_TEMPLATE, PLUGIN_ROOT } from '../shared/constants';
import { DataLinkClickEvent, FieldMatcherID } from '@grafana/data';
import { VariableHide } from '@grafana/schema';
import { GenericButton } from './generic.button';
import { IconName } from '@grafana/ui';

const getScene = (): EmbeddedScene => {
  const prefixVariable = new CustomVariable({
    name: 'selection',
    label: 'Selection',
    hide: VariableHide.hideVariable,
  });

  const conversionVariable = new CustomVariable({
    name: 'conversionId',
    label: 'Conversion ID',
    hide: VariableHide.hideVariable,
  });

  const typeVariable = new CustomVariable({
    name: 'type',
    value: 'all',
    label: 'Type',
    hide: VariableHide.dontHide,
    query: 'All : all,Custom : custom,Standard : standard',
  });

  const deploymentVariable = new CustomVariable({
    name: 'deployment',
    value: 'all',
    label: 'Deployment',
    hide: VariableHide.dontHide,
    query: 'All : all,Converted : conversion,Monitoring : alert,Dashboard : dashboard',
  });

  const ruleStateVariable = new CustomVariable({
    name: 'rule_state',
    value: 'all',
    label: 'Rule State',
    hide: VariableHide.dontHide,
    query: 'All : all,Deleted : deleted,Outdated : outdated,Not Deleted : not_deleted',
  });

  const repoVariable = new CustomVariable({
    name: 'repo',
    label: 'Repo',
    hide: VariableHide.hideVariable,
  });

  const repoQueryVariable = new QueryVariable({
    name: 'repo_id',
    label: 'Repository',
    datasource: {
      uid: 'repo-list',
    },
    query: {
      refId: 'A',
      JSON: {},
    },
  });

  const alertingVariable = new CustomVariable({
    name: 'alerting',
    value: 'all',
    label: 'Alert Status',
    query: 'All : all,Alerting : include,Not Alerting : exclude',
    description: 'Uses the time range to check whether alerts have fired in the given time period',
  });

  const containsVariable = new TextBoxVariable({
    name: 'contains',
    label: 'Contains',
    hide: VariableHide.dontHide,
    value: '',
    description: 'Filter the rules based on space-separated keywords',
  });

  const sceneTimeRange = new SceneTimeRange({
    from: 'now-7d/d',
    to: 'now',
  });

  const sigmaRulesQueryRunner = new SceneQueryRunner({
    datasource: {
      uid: 'sigmarules',
    },
    queries: [
      {
        refId: 'A',
        type: '$type',
        selection: '$selection',
        repo_id: '$repo_id', //eslint-disable-line camelcase
        deployment: '$deployment',
        alertingStatus: '$alerting',
        rule_state: '$rule_state', //eslint-disable-line camelcase
        contains: '$contains',
      },
    ],
  });

  const sigmaRulesMetricQueryRunner = new SceneQueryRunner({
    datasource: {
      uid: 'sigmarules-metric',
    },
    queries: [
      {
        refId: 'A',
        type: '$type',
        selection: '$selection',
      },
    ],
  });

  const sigmaRuleContentQueryRunner = new SceneQueryRunner({
    datasource: {
      uid: 'sigmarules-content',
    },
    queries: [
      {
        refId: 'A',
        file: '$selection',
        repoId: '$repo',
        type: '$type',
      },
    ],
  });

  const iconTitles: Map<IconName, string> = new Map();
  iconTitles.set('bell', 'Has an alerting rule configured');
  iconTitles.set('dashboard', 'Has a dashboard created');
  iconTitles.set('exclamation-circle', 'Is currently alerting');

  const folder = new VizPanel({
    pluginId: 'grafana-detect-app-folder-panel',
    title: 'All Rules',
    options: {
      fieldName: 'name',
      fieldChildren: 'children',
      fieldLabels: ['alerting'],
      showLabels: true,
      hasChildren: true,
      defaultOpenChildren: false,
      fieldConversions: 'conversions',
      fieldRepoName: 'repoName',
      fieldTarget: 'target',
      fieldDsUid: 'ds_config_uid',
      fieldAlertId: 'alertId',
      fieldDashboardId: 'dashboardId',
      fieldAlerting: 'alerting',
      allowSelect: false,
      wideClick: true,
      selection: '$selection',
      iconTitles: iconTitles,
    },
    fieldConfig: {
      overrides: [
        {
          matcher: {
            id: FieldMatcherID.byName,
            options: 'name',
          },
          properties: [
            {
              id: 'links',
              value: [
                {
                  title: 'Open Rule(s)',
                  onClick: (e: DataLinkClickEvent) => {
                    const varValue = e.replaceVariables ? e.replaceVariables('${__data.fields[1].text}') : '';
                    const repoValue = e.replaceVariables ? e.replaceVariables('${__data.fields[4].text}') : '';
                    prefixVariable.changeValueTo(varValue);
                    repoVariable.changeValueTo(repoValue);
                  },
                },
              ],
            },
          ],
        },
      ],
      defaults: {},
    },
  });

  const convertButton = new GenericButton({
    locationTemplate: LOCATION_TEMPLATE,
    text: 'Convert',
    variant: 'primary',
    disabled: repoVariable.getValue().toString() === '',
    tooltip: 'Convert the selected rule to a query in the converter page',
  });

  const loadConversionButton = new GenericButton({
    locationTemplate: CONVERSION_LOCATION_TEMPLATE,
    text: 'Load conversion',
    variant: 'primary',
    disabled: conversionVariable.getValue().toString() === '',
    tooltip: 'Load the latest conversion for the selected rule in the converter page',
  });

  convertButton.addActivationHandler(() => {
    const repoSub = repoVariable.subscribeToState((state) => {
      // Here we assume that the prefix variable is already set when the repo variable is set
      convertButton.setState({ disabled: state.value.toString() === '' });
    });

    return () => {
      repoSub.unsubscribe();
    };
  });

  loadConversionButton.addActivationHandler(() => {
    const conversionSub = sigmaRuleContentQueryRunner.subscribeToState((state) => {
      if (state.data && state.data.series.length > 0) {
        if (state.data.series[0].fields.length < 6) {
          loadConversionButton.setState({ disabled: true });
          return;
        }

        if (state.data.series[0].fields[5].values.length === 0) {
          loadConversionButton.setState({ disabled: true });
          return;
        }

        if (state.data.series[0].fields[5].values[0] === '') {
          loadConversionButton.setState({ disabled: true });
          return;
        }

        const conversionId = state.data.series[0].fields[5].values[0];
        loadConversionButton.setState({ disabled: conversionId.toString() === '' });
        conversionVariable.setState({ value: conversionId.toString() });
      } else {
        // Reset the conversion variable and disable the button
        loadConversionButton.setState({ disabled: true });
        conversionVariable.setState({ value: '' });
      }
    });

    return () => {
      conversionSub.unsubscribe();
    };
  });

  return new EmbeddedScene({
    body: new SplitLayout({
      $data: sigmaRulesQueryRunner,
      direction: 'row',
      initialSize: 0.75,
      primary: new SceneFlexItem({
        xSizing: 'fill',
        ySizing: 'fill',
        minWidth: '525px',
        minHeight: '200px',
        body: folder,
      }),
      secondary: new SceneFlexItem({
        xSizing: 'fill',
        ySizing: 'fill',
        minWidth: '275px',
        minHeight: '750px',
        body: new VizPanel({
          $data: sigmaRuleContentQueryRunner,
          pluginId: 'grafana-detect-app-rule-panel',
          key: 'rule-panel',
          title: 'Rule Data',
        }),
      }),
    }),
    controls: [
      new VariableValueSelectors({}),
      new SceneControlsSpacer(),
      new SceneTimePicker({}),
      new SceneRefreshPicker({}),
      loadConversionButton,
      convertButton,
    ],
    $variables: new SceneVariableSet({
      variables: [
        prefixVariable,
        typeVariable,
        repoVariable,
        repoQueryVariable,
        conversionVariable,
        deploymentVariable,
        ruleStateVariable,
        alertingVariable,
        containsVariable,
      ],
    }),
    $timeRange: sceneTimeRange,
  });
};

export const getSigmaRuleBrowserScene = () => {
  return new SceneAppPage({
    title: 'Sigma Rules',
    url: `${PLUGIN_ROOT}/scenes/sigma`,
    getScene: getScene,
  });
};
