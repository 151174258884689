import { getBackendSrv } from '@grafana/runtime';
import { PLUGIN_ID } from 'shared/constants';
import { useEffect, useState } from 'react';

export type RulePointer = {
  value: string;
  label: string;
  repositoryId: string;
  source?: 'github' | 'database' | 'custom';
  version?: string;
  filename?: string;
  disabled?: boolean;
  items?: Array<RulePointer>;
  repo_id?: string;
  type?: string;
};

export const useGitTree = (type?: string, status?: string, contains?: string) => {
  const [sourceRules, setSourceRules] = useState<Array<RulePointer>>([
    { label: 'Fetching rules...', value: '', repositoryId: '', disabled: true },
  ]);

  useEffect(() => {
    getBackendSrv()
      .get<Array<RulePointer>>(
        `/api/plugins/${PLUGIN_ID}/resources/v1/sources.GetRules?type=${type ?? 'all'}&status=${
          status ?? 'all'
        }&contains=${contains ?? ''}`
      )
      .then((d) => {
        setSourceRules(d);
      })
      .catch((err) => console.error(err));
  }, [type, status, contains]);

  return [sourceRules] as const;
};
