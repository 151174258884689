// Define constants for plugin wide consumption here to make copy easier to change.
const PLUGIN_NAME_EN = 'Grafana Detect';

// Then define translations, at the moment only english is supported (James C doesn't speak any other language well enough to translate here)
export const enTranslation = {
  installPage: {
    title: `Install ${PLUGIN_NAME_EN}`,
    introParagraph: `Welcome to ${PLUGIN_NAME_EN}, Grafana's Security Operations product. This page will help you or your administrator to setup the plugin to start exploring your data in a security centric way.`,
    requireAdminParagraph: `It appears that ${PLUGIN_NAME_EN} has not been configured for this Grafana instance yet, hang tight until an administrator completes the setup.`,
    adminIntro: `It appears that ${PLUGIN_NAME_EN} has not been configured for this Grafana instance yet, as an administrator of this instance you can use this page to finalise the installation.`,
    configureSigmaAPI: `Configure Sigma API`,
    configureService: `Configure ${PLUGIN_NAME_EN} Service`,
    configureGitHub: `Configure GitHub Integration`,
    submit: 'Initialize',
    modalTitle: `Initializing ${PLUGIN_NAME_EN}`,
    loadingTexts: [
      'Brewing Coffee',
      'Compiling Dossiers',
      'Generating Threat Intelligence',
      'Downloading more RAM',
      'Observing Quantum State',
      'Checking for backdoors',
      'Playing Uno Reverse Card on Hackers',
      'Preparing for the phishing season',
    ],
    sigmaAPIConfig: {
      title: `Configure Sigma API`,
      addressLabel: 'Sigma API HTTP Address',
      addressDescription: 'Use the format host:port',
    },
    serviceConfig: {
      title: `Configure ${PLUGIN_NAME_EN} Service`,
      addressLabel: `${PLUGIN_NAME_EN} Service Address`,
      accessTokenLabel: 'Service Access Token',
    },
    githubConfig: {
      title: `Configure GitHub Integration`,
    },
    dashboardConfig: {
      title: `Configure Dashboards`,
      incidentDSLabel: `Grafana Incident Data Source`,
      incidentDSDetail: `If applicable, select the Grafana Incident data source for security incidents`,
      alertingHistoryDSLabel: `Grafana Alert State History Data Source`,
      alertingHistoryDetail: `If applicable, select the Loki index where Alert State History is being stored`,
    },
  },
  converter: {
    alerting: {
      'see-alert': 'View Associated Alert',
      minute: 'Every minute',
      fiveMinute: 'Every 5 minutes',
      hour: 'Every hour',
      sixHours: 'Every six hours',
      day: 'Every day',
      button: 'Alert',
      createButton: 'Create Alert',
    },
    dashboards: {
      createButton: 'Create Dashboard',
      button: 'Dashboard',
      update: 'Update Dashboard',
      'see-dashboard': 'View Associated Dashboard',
    },
  },
};

export const enGBTranslation = {
  ...enTranslation,
  installPage: {
    ...enTranslation.installPage,
    submit: 'Initialise',
    modalTitle: `Initialising ${PLUGIN_NAME_EN}`,
  },
};
