import { Label, ReactMonacoEditor } from '@grafana/ui';
import React, { ReactNode, useEffect, useState } from 'react';

export interface PipelineContentInputProps {
  value?: string;
  invalid?: boolean;
  error?: ReactNode;

  onChange?(e: string): void;
}

export const PipelineContentInput = (props: PipelineContentInputProps) => {
  const [content, setContent] = useState(props.value ?? '');

  useEffect(() => {
    if (props.onChange) {
      props.onChange(content);
    }
  }, [content, props]);

  return (
    <>
      <Label>Rule Content</Label>
      <ReactMonacoEditor
        language="yaml"
        value={content}
        height={400}
        onChange={(e) => {
          setContent(e ?? '');
        }}
        options={{
          renderValidationDecorations: 'on',
        }}
      />
      <Label>
        For information on writing pipelines visit&nbsp;
        <a
          href="https://sigmahq-pysigma.readthedocs.io/en/latest/Processing_Pipelines.html"
          target="_blank"
          rel="noreferrer"
        >
          SigmaHQ
        </a>
      </Label>
    </>
  );
};
