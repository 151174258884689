import {
  AppEvents,
  DataQueryError,
  DataQueryResponse,
  DataQueryResponseData,
  DataSourceApi,
  Field,
  QueryResultMetaStat,
  SelectableValue,
  TimeRange,
} from '@grafana/data';
import { FetchResponse, getAppEvents, getBackendSrv, getDataSourceSrv, toDataQueryError } from '@grafana/runtime';
import { DataQuery } from '@grafana/schema';
import { DataSourceParserConfig, createTestQuery } from 'components/DataSourceConfiguration';
import React, { ReactNode, createContext, useCallback, useMemo, useEffect } from 'react';
import { Observable, BehaviorSubject, map, combineLatest, switchMap, take, of, ObservableInput } from 'rxjs';
import {
  DEFAULT_MAX_LINES,
  NullID,
  PLUGIN_ID,
  VAR_CONVERSION_ID,
  VAR_RULE_PATH,
  VAR_RULE_REPO,
} from 'shared/constants';
import { LokiParserAndLabelKeys, LokiQuery } from 'shared/lokiTypes';
import { SigmaServiceApi as SigmaAPIClient, Configuration as SigmaAPIConfiguration, V1Target } from 'api/sigma-api';
import {
  ConversionApi,
  Configuration as DetectAPIConfiguration,
  CreateConversionRequestQueriesValueModel,
  ListSigmaRuleVersions200ResponseInnerModel,
  SigmaRuleApi,
  SigmaRuleReadModel,
  DataSourceConfigReadModel,
} from 'api/detect-service';
import {
  PlainRules,
  RuleContent,
  Rules,
  ProcessingItem,
  ProcessingPipeline,
  StoreConvertResponse,
  CustomPipeline,
} from 'shared/requests/conversions';
import { SplunkQuery } from 'shared/splunkTypes';
import { QueryResult, QueryBuilderLabelFilter, Argument } from 'shared/types';
import { generateBasicQueryExpr } from 'utils/query';
import { getExploreUrl } from 'utils/explore';
import { getDefaultTimeRange } from 'utils/datetime';
import { useLocation } from 'react-router-dom';
import { DetectServiceConfig, SigmaAPIConfig } from '../shared/apiConfigs';
import { parse, render } from '../components/scenes/logsource/queries/loki';
import { LokiConfiguration, NewLokiConfiguration } from '../components/scenes/logsource/queries/types';
import { Query } from '@testing-library/react';
import { getData } from 'ajv/dist/compile/validate';

export const ConversionContext = createContext<ConversionContextType>({} as ConversionContextType);

export interface ConversionContextType {
  data: {
    targets: Observable<Array<SelectableValue<string>>>;
    target: Observable<SelectableValue<string>>;
    extraArguments: Observable<{ [key: string]: Argument }>;
    transformedExtraArguments: Observable<{ [key: string]: any }>;
    formats: Observable<Array<SelectableValue<string>>>;
    format: Observable<SelectableValue<string>>;
    pipelines: Observable<Array<SelectableValue<string>>>;
    activePipelines: Observable<Array<SelectableValue<string>>>;
    datasource: Observable<DataSourceApi | null>;
    queries: Observable<string>;
    storeConvertResponse: Observable<StoreConvertResponse | undefined>;
    fields: Observable<Array<Array<string>>>;
    parserConfig: Observable<DataSourceParserConfig | null>;
    filters: Observable<Array<QueryBuilderLabelFilter>>;
    selector: Observable<string | undefined>;
    extra: Observable<string | undefined>;
    rules: Observable<Rules>;
    plainRules: Observable<PlainRules>;
    queryResults: Observable<Array<DataQueryResponseData>>;
    queryErrors: Observable<Array<DataQueryError>>;
    timeRangeZone: Observable<[TimeRange, string]>; // Tuple of time range and timezone
    processedQueryResults: Observable<Array<QueryResult>>;
    conversionId: Observable<string | null>;
    lokiParserAndLabelKeys: Observable<LokiParserAndLabelKeys | null>;
    streamSelectors: Observable<Array<QueryBuilderLabelFilter>> | null;
    formattedStreamSelectors: Observable<string> | null;
    datasourceConfig: Observable<DataSourceConfigReadModel | undefined>;
    alertID: Observable<string>;
    alertGroup: Observable<string>;
    dashboardID: Observable<string>;
  };
  operations: {
    listTargets(): void;
    setTarget(value: SelectableValue<string>): void;
    setTransformedExtraArguments(value: { [key: string]: any }): void;
    setFormat(value: SelectableValue<string>): void;
    setActivePipelines(value: Array<SelectableValue<string>>): void;
    setDatasource(value?: DataSourceApi | null): void;
    setQueries(value: string): void;
    setFields(value: Array<Array<string>>): void;
    setParserConfig(value?: DataSourceParserConfig | null): void;
    setFilters(value: Array<QueryBuilderLabelFilter>): void;
    setSelector(value: string): void;
    setExtra(value: string): void;
    getLokiParserAndLabelKeys(): void;
    setStreamSelectors(value: Array<QueryBuilderLabelFilter>): void;
    setRules(value: Rules): void;
    appendRule(filename: string, rule: RuleContent): void;
    appendRules(rules: Rules): void;
    convert(customPipelines?: Observable<Array<ProcessingPipeline>>): void;
    testQuery(openExplore: boolean): void;
    getQueryResults(metricNames: Array<string>): void;
    setTimeRangeZone(value: [TimeRange, string]): void;
    setDatasourceConfig(value: DataSourceConfigReadModel): void;
    setAlertID(value: string): void;
    setAlertGroup(value: string): void;
    setDashboardID(value: string): void;
  };
}

export const ConversionProvider = (props: { children: ReactNode }) => {
  // We now want to know information about the search parameters in this store
  const { search } = useLocation();

  /**
   * Define some behavior subjects (see rxjs docs for more info) basically allows us to store a value
   * and subscribe to it. This enables us to expose observables that can be subscribed to by other
   * components in a read-only fashion. We use a memoized value to avoid loads of rerenders.
   */

  const sigmaAPIClient = useMemo(() => new SigmaAPIClient(SigmaAPIConfig), []);
  const conversionAPIClient = useMemo(() => new ConversionApi(DetectServiceConfig), []);
  const sigmaRulesAPIClient = useMemo(() => new SigmaRuleApi(DetectServiceConfig), []);

  const datasourceConfig = useMemo(() => new BehaviorSubject<DataSourceConfigReadModel | undefined>(undefined), []);

  const targets = useMemo(() => new BehaviorSubject<Array<SelectableValue<string>>>([]), []);
  const target = useMemo(
    () => new BehaviorSubject<SelectableValue<string>>({ label: 'Grafana Loki', value: 'loki' }),
    []
  );
  const extraArguments = useMemo(() => new BehaviorSubject<{ [key: string]: Argument }>({}), []);
  const transformedExtraArguments = useMemo(() => new BehaviorSubject<{ [key: string]: any }>({}), []);

  const formats = useMemo(() => new BehaviorSubject<Array<SelectableValue<string>>>([]), []);
  const format = useMemo(
    () => new BehaviorSubject<SelectableValue<string>>({ label: 'Plain Loki queries', value: 'default' }),
    []
  );

  const pipelines = useMemo(() => new BehaviorSubject<Array<SelectableValue<string>>>([]), []);
  const activePipelines = useMemo(() => new BehaviorSubject<Array<SelectableValue<string>>>([]), []);

  const datasource = useMemo(() => new BehaviorSubject<DataSourceApi | null>(null), []);

  const queries = useMemo(() => new BehaviorSubject<string>(''), []);

  const storeConvertResponse = useMemo(() => new BehaviorSubject<StoreConvertResponse | undefined>(undefined), []);

  const fields = useMemo(() => new BehaviorSubject<Array<Array<string>>>([]), []);

  const parserConfig = useMemo(() => new BehaviorSubject<DataSourceParserConfig | null>(null), []);

  const filters = useMemo(() => new BehaviorSubject<Array<QueryBuilderLabelFilter>>([]), []);

  const alertID = useMemo(() => new BehaviorSubject(''), []);
  const alertGroup = useMemo(() => new BehaviorSubject(''), []);
  const dashboardID = useMemo(() => new BehaviorSubject(''), []);

  // selector and extra replace the parserConfig and filters with a new way of defining queries using the full LokiQuery component.
  const selector = useMemo(
    () => datasourceConfig.pipe(map((conf) => conf?.configuration.selector)),
    [datasourceConfig]
  );
  const extra = useMemo(() => datasourceConfig.pipe(map((conf) => conf?.configuration.extra)), [datasourceConfig]);

  const lokiParserAndLabelKeys = useMemo(() => new BehaviorSubject<LokiParserAndLabelKeys | null>(null), []);

  // const streamSelectors = useMemo(() => new BehaviorSubject<Array<QueryBuilderLabelFilter>>([]), []);
  const streamSelectors = selector.pipe(
    map<string | undefined, Array<QueryBuilderLabelFilter>>((input) => {
      try {
        return JSON.parse(input ?? '[]') as Array<QueryBuilderLabelFilter>;
      } catch (e) {
        return [];
      }
    })
  );

  const formattedStreamSelectors = streamSelectors.pipe(
    map<Array<QueryBuilderLabelFilter>, string>((qblf: Array<QueryBuilderLabelFilter>) => {
      return '{' + qblf.map((selector) => selector.label + selector.op + '"' + selector.value + '"').join() + '}';
    })
  );

  const formattedSelector = selector.pipe(
    map<string | undefined, Array<QueryBuilderLabelFilter>>((input) => JSON.parse(input ?? '[]')),
    switchMap<Array<QueryBuilderLabelFilter>, Observable<string>>((qblf) => {
      return extra.pipe(
        map((value) => {
          return render({ selector: JSON.stringify(qblf), extra: value ?? '' });
        })
      );
    })
  );

  const rules = useMemo(() => new BehaviorSubject<Rules>({}), []);
  const plainRules = useMemo(
    () =>
      rules.pipe(
        map((e) => {
          let _rules: PlainRules = {};

          Object.keys(e).forEach((key) => {
            _rules[key] = e[key].content;
          });

          return _rules;
        })
      ),
    [rules]
  );
  const ruleIds = useMemo(
    () =>
      rules.pipe(
        map((e) => {
          return Object.values(e)
            .filter((ruleContent) => !!ruleContent.ruleId)
            .map<string>((v) => v.ruleId!);
        })
      ),
    [rules]
  );
  // FIXME: we need to obtain pipeline IDs too

  const timeRangeZone = useMemo(() => new BehaviorSubject<[TimeRange, string]>([getDefaultTimeRange(), 'UTC']), []);

  const queryResults = useMemo(() => new BehaviorSubject<Array<DataQueryResponseData>>([]), []);

  const queryErrors = useMemo(() => new BehaviorSubject<Array<DataQueryError>>([]), []);

  const processedQueryResults = useMemo(() => new BehaviorSubject<Array<QueryResult>>([]), []);

  const conversionId = useMemo(
    () => new BehaviorSubject<string | null>(new URLSearchParams(search).get(VAR_CONVERSION_ID)),
    [search]
  );

  const initialRules = useMemo(() => {
    const _searchParams = new URLSearchParams(search);
    const _rules = _searchParams.getAll(VAR_RULE_PATH);
    const _repos = _searchParams.getAll(VAR_RULE_REPO);

    if (_rules.length === 0 || _repos.length === 0) {
      return new BehaviorSubject<Array<Array<string>>>([]);
    }

    if (_rules.length !== _repos.length) {
      return new BehaviorSubject<Array<Array<string>>>([]);
    }

    return new BehaviorSubject<Array<Array<string>>>(_rules.map((e, i) => [_repos[i], e]));
  }, [search]);

  /**
   * These variables are for local use only, they shouldn't be exposed to components through the context
   * this is in order to allow for us to use state from other contexts without having to rely on the context
   * itself.
   */
  const _customPipelines = useMemo(() => new BehaviorSubject<Array<CustomPipeline>>([]), []);

  useEffect(() => {
    setTimeout(() => {
      conversionId.subscribe((e) => {
        if (e) {
          combineLatest({
            conversion: conversionAPIClient.readConversion({ id: e }),
            rules: conversionAPIClient.listConversionSigmaRules({ id: e }),
            dsConfig: conversionAPIClient.readConversionDataSourceConfig({ id: e }),
            customPipelines: conversionAPIClient.listConversionCustomPipelines({ id: e }),
            targets: targets,
            pipelines: pipelines,
            formats: formats,
          })
            .pipe(take(1))
            .subscribe((e) => {
              let t = e.targets.filter((target) => target.value === e.conversion.target);
              let _format = e.formats.filter((format) => format.value === e.conversion.output);

              if (e.conversion.alert_id) {
                alertID.next(e.conversion.alert_id);
                if (e.conversion.alert_groupname) {
                  alertGroup.next(e.conversion.alert_groupname);
                }
              }

              if (e.conversion.dashboard_id) {
                dashboardID.next(e.conversion.dashboard_id);
              }

              target.next(t.length ? t[0] : target.getValue());
              format.next(_format.length ? _format[0] : format.getValue());
              activePipelines.next(
                e.pipelines.filter((pipeline) => (e.conversion.builtin_pipelines ?? []).includes(pipeline.value ?? ''))
              );
              getDataSourceSrv()
                .get(e.dsConfig.uid)
                .then((ds) => {
                  datasource.next(ds);
                });
              transformedExtraArguments.next(e.conversion.extra_arguments ?? {});

              parserConfig.next(
                e.dsConfig.configuration?.parserConfig ? JSON.parse(e.dsConfig.configuration?.parserConfig) : null
              );
              filters.next(e.dsConfig.configuration?.filters ? JSON.parse(e.dsConfig.configuration?.filters) : []);

              datasourceConfig.next(e.dsConfig);

              const _queries: {
                [key: string]: CreateConversionRequestQueriesValueModel;
              } = e.conversion.queries ?? {};

              const concatenatedQueries = Object.keys(_queries)
                .map((key) => _queries[key].queries?.join('\n'))
                .join('\n\n');
              queries.next(concatenatedQueries);

              let _rules: Rules = {};

              for (let rule of e.rules.data) {
                _rules[[rule.github_repo_rules, rule.filename].join('/')] = {
                  content: rule.content,
                  ruleId: rule.id,
                  origin: rule.github_repo_rules ? 'github' : 'custom',
                  ref: rule.ref,
                  owner: rule.repo_owner,
                  repo: rule.repo_name,
                } as RuleContent;
              }

              rules.next(_rules);
            });
          const location = new URL(window.location.href);
          const params = new URLSearchParams(location.search);
          params.set(VAR_CONVERSION_ID, e);
          location.search = params.toString();

          // This is to change the URL value without triggering a refresh
          window.history.pushState(window.history.state, '', location.toString());
        }
      });
    }, 500);
  }, [
    rules,
    conversionId,
    conversionAPIClient,
    activePipelines,
    pipelines,
    targets,
    target,
    datasource,
    transformedExtraArguments,
    filters,
    parserConfig,
    queries,
    formats,
    format,
    selector,
    extra,
    datasourceConfig,
    alertID,
    alertGroup,
    dashboardID,
  ]);

  useEffect(() => {
    // Check that we're not going to double configure the rules
    if (conversionId.getValue()) {
      return;
    }

    initialRules
      .pipe(
        switchMap<Array<Array<string>>, Observable<Array<SigmaRuleReadModel | null>>>((e) => {
          return combineLatest(
            e.map((ruleData) => {
              return sigmaRulesAPIClient
                .listSigmaRuleVersions({
                  filename: ruleData[1],
                  repoId: ruleData[0] !== NullID ? ruleData[0] : '',
                })
                .pipe(
                  map((versions) => versions[0] ?? null),
                  switchMap((version) => {
                    if (!version || !version.id) {
                      return of(null);
                    }

                    // Horrible map closure to ensure that the repo is correctly set
                    return sigmaRulesAPIClient.readSigmaRule({ id: version.id }).pipe(
                      map((rule) => {
                        rule.github_repo_rules = ruleData[0]; //eslint-disable-line camelcase -- this is a generated field from the openapi spec
                        return rule;
                      })
                    );
                  })
                );
            })
          );
        })
      )
      .subscribe({
        next: (e) => {
          let _rules: Rules = {};

          for (let rule of e) {
            if (rule) {
              _rules[[rule.github_repo_rules, rule.filename].join('/')] = {
                content: rule.content,
                ruleId: rule.id,
                origin: rule.github_repo_rules ? 'github' : 'custom',
                ref: rule.ref,
              } as RuleContent;
            }
          }

          rules.next(_rules);
        },
      });
  }, [conversionId, initialRules, rules, sigmaRulesAPIClient]);

  /**
   * The following functions contain operations that can be executed against the store.
   * This allows for centralised management of the store and allows us to respond to data changes
   * in the components that care about the state change.
   */

  const opConvert = useCallback(
    (customPipelines?: Observable<Array<CustomPipeline>>) => {
      let cp = customPipelines ?? _customPipelines;
      combineLatest({
        plainRules,
        format,
        activePipelines,
        target,
        parserConfig,
        filters,
        cp,
        datasource,
        ruleIds,
        conversionId,
        selector,
        extra,
        datasourceConfig,
      })
        .pipe(
          take(1),
          map((e) => {
            _customPipelines.next(e.cp);
            if (Object.keys(e.plainRules).length === 0) {
              throw new Error('No rules selected');
            }
            return e;
          }),
          map((e) => {
            const target = e.target.value ?? 'loki';

            let lokiConfig: NewLokiConfiguration = {
              selector: e.selector ?? '[]',
              extra: e.extra ?? '',
            };

            if (!e.selector && e.filters) {
              lokiConfig = parse(
                render({
                  filters: JSON.stringify(e.filters),
                  parserConfig: JSON.stringify(e.parserConfig),
                })
              ) as NewLokiConfiguration;
            }

            return {
              conversionId: e.conversionId,
              customPipelines: e.cp,
              filters: e.filters,
              parserConfig: e.parserConfig,
              output: e.format?.value ?? 'default',
              pipelines: e.activePipelines.map<string>((e) => e.value ?? ''),
              rules: e.plainRules,
              target: target,
              extraArguments: transformedExtraArguments.getValue(),
              dataSource: e.datasource,
              ruleIds: e.ruleIds,
              selector: lokiConfig.selector,
              extra: lokiConfig.extra,
              datasourceConfig: e.datasourceConfig,
            };
          }),
          map((e) => {
            const transformations: Array<ProcessingItem> = [];
            const _selector = JSON.parse(
              e.datasourceConfig?.configuration.selector ?? '[{"label":"job","op":"=~","value":"\\".+\\""}]'
            );
            if (Array.isArray(_selector)) {
              const selection = `{${_selector.map((s) => s.label + s.op + s.value).join(',')}}`;

              transformations.push({
                id: '__internal_custom_data_source',
                type: 'set_custom_attribute',
                attribute: 'logsource_loki_selection',
                value: selection,
              });

              if (e.extra) {
                let extra = e.datasourceConfig?.configuration.extra ?? '';
                if (extra.indexOf('| ') !== -1) {
                  extra = extra.substring(extra.indexOf('| ') + 2);
                }

                transformations.push({
                  id: '__internal_custom_parser',
                  type: 'set_custom_attribute',
                  attribute: 'loki_parser',
                  value: extra,
                });
              }
            }
            let customPipelines = e.customPipelines ?? [];

            customPipelines = customPipelines?.filter((e) => e.name !== 'DataSourceFilters');

            customPipelines.push({
              id: '',
              pipeline: {
                name: 'DataSourceFilters',
                priority: 10,
                transformations: transformations,
              },
            });

            e.customPipelines = customPipelines;

            return e;
          }),
          switchMap((e) => {
            return getBackendSrv().fetch<StoreConvertResponse>({
              url: `/api/plugins/${PLUGIN_ID}/resources/v1/rules.StoreConvert`,
              method: 'POST',
              data: {
                conversionId: e.conversionId,
                target: e.target,
                pipelines: e.pipelines,
                output: e.output,
                extraArguments: Object.entries(e.extraArguments).reduce(
                  (o, ent) => Object.assign(o, { [ent[0]]: ent[1]?.toString() }),
                  {}
                ),
                customPipelines: e.customPipelines.map((e) => e.pipeline),
                pipelineIds: e.customPipelines.filter((e) => e.id !== '').map((e) => e.id),
                rules: e.rules,
                ruleIds: e.ruleIds,
                dataSourceConfigID: e.datasourceConfig?.id ?? undefined,
              },
              responseType: 'json',
            });
          })
        )
        .subscribe({
          next: (e: FetchResponse<StoreConvertResponse>) => {
            storeConvertResponse.next(e.data);
            conversionId.next(e.data.conversion_id);
            let keys = Object.keys(e.data.queries ?? {});
            const concatenatedQueries = keys.map((key) => e.data.queries?.[key]?.queries ?? []).join('\n\n');
            queries.next(concatenatedQueries);
          },
          error: (e) => {
            console.error(e);
          },
        });
    },
    [
      plainRules,
      format,
      activePipelines,
      target,
      parserConfig,
      filters,
      queries,
      _customPipelines,
      datasource,
      ruleIds,
      conversionId,
      storeConvertResponse,
      transformedExtraArguments,
      selector,
      extra,
      datasourceConfig,
    ]
  );

  const opListTargets = useCallback(() => {
    sigmaAPIClient.listTargets().subscribe((e) => {
      if (e.targets?.length === 0) {
        return;
      }

      targets.next((e.targets ?? []).map((e) => ({ label: e.queryLanguage, value: e.target })));

      // Get the extra arguments for the selected target
      let targetObject = e.targets?.reduce((e: V1Target) => {
        if (e.target === target.getValue()?.value) {
          return e;
        }

        return {};
      }) as V1Target;

      extraArguments.next(targetObject.extraArguments as { [key: string]: Argument });
    });
  }, [targets, target, extraArguments, sigmaAPIClient]);

  const opSetTarget = useCallback(
    (value: SelectableValue<string>) => {
      target.next(value);
      activePipelines.next([]);
    },
    [target, activePipelines]
  );

  const opSetFormat = useCallback(
    (value: SelectableValue<string>) => {
      format.next(value);
      if (queries.getValue() !== '') {
        opConvert();
      }
    },
    [format, queries, opConvert]
  );

  const opSetActivePipelines = useCallback(
    (value: Array<SelectableValue<string>>) => {
      activePipelines.next(value);
    },
    [activePipelines]
  );

  const opSetTransformedExtraArguments = useCallback(
    (value: { [key: string]: Argument }) => {
      transformedExtraArguments.next(value);
    },
    [transformedExtraArguments]
  );

  const opSetDatasource = useCallback(
    (value?: DataSourceApi | null) => {
      if (value) {
        datasource.next(value);
      }
    },
    [datasource]
  );

  const opSetQueries = useCallback(
    (value: string) => {
      queries.next(value);
    },
    [queries]
  );

  const opSetFields = useCallback(
    (value: Array<Array<string>>) => {
      fields.next(value);
    },
    [fields]
  );

  const opSetParserConfig = useCallback(
    (value?: DataSourceParserConfig | null) => {
      if (value) {
        parserConfig.next(value);
      }
    },
    [parserConfig]
  );

  const opSetFilters = useCallback(
    (value: Array<QueryBuilderLabelFilter>) => {
      filters.next(value);
    },
    [filters]
  );

  const opSetSelector = useCallback(
    (value: string) => {
      const currentVal = datasourceConfig.getValue();
      if (!currentVal) {
        return;
      }
      datasourceConfig.next({ ...currentVal, configuration: { ...currentVal.configuration, selector: value } });
    },
    [datasourceConfig]
  );
  const opSetExtra = useCallback(
    (value: string) => {
      const currentVal = datasourceConfig.getValue();
      if (!currentVal) {
        return;
      }
      datasourceConfig.next({ ...currentVal, configuration: { ...currentVal.configuration, extra: value } });
    },
    [datasourceConfig]
  );

  const opGetLokiParserAndLabelKeys = useCallback(() => {
    combineLatest({ datasourceConfig, formattedStreamSelectors, formattedSelector, parserConfig })
      // This means that we'll unsubscribe after the first value is emitted
      .pipe(
        take(1),
        switchMap((e) => {
          return getDataSourceSrv()
            .get(e.datasourceConfig?.uid)
            .then((ds) => {
              return Promise.resolve({ ...e, datasource: ds });
            });
        }),
        map((e) => {
          let query = '';

          switch (e.datasource.type) {
            case 'loki':
              query =
                e.formattedSelector !== '{}'
                  ? e.formattedSelector
                  : e.formattedStreamSelectors !== '{}'
                  ? e.formattedStreamSelectors
                  : '{job=~".+"}';
              break;
            default:
              break;
          }

          return {
            ...e,
            query,
          };
        }),
        switchMap<any, ObservableInput<LokiParserAndLabelKeys>>((e) => {
          if (!e.datasource) {
            return Promise.reject('No datasource provided');
          }

          const timeRange = timeRangeZone.getValue()[0];
          if (!e.datasource.languageProvider) {
            return Promise.reject('No language provider');
          }

          switch (e.datasource.type) {
            case 'loki':
              return e.datasource.languageProvider
                .getParserAndLabelKeys(e.query, {
                  maxLines: DEFAULT_MAX_LINES,
                  timeRange: timeRange,
                })
                .then((unfiltered: LokiParserAndLabelKeys) => {
                  let parser = 'logfmt';
                  if (unfiltered.hasJSON) {
                    parser = 'json';
                  } else if (unfiltered.hasPack) {
                    parser = 'unpack';
                  } else {
                    console.log('No parser detected, continuing with logfmt');
                  }

                  if (e.formattedSelector.endsWith('}')) {
                    opSetExtra('| ' + parser);
                  }

                  return e.datasource.languageProvider
                    .getParserAndLabelKeys(e.query + ' | ' + parser, {
                      maxLines: DEFAULT_MAX_LINES,
                      timeRange: timeRange,
                    })
                    .then((filtered: LokiParserAndLabelKeys) => {
                      if (filtered.extractedLabelKeys.length > 0) {
                        if (
                          !e.parserConfig?.parser?.value &&
                          !filtered.hasLogfmt &&
                          !filtered.hasJSON &&
                          !filtered.hasPack
                        ) {
                          filtered.hasLogfmt = true;
                        }
                        return Promise.resolve<LokiParserAndLabelKeys>(filtered);
                      }
                      return Promise.resolve<LokiParserAndLabelKeys>(unfiltered);
                    });
                });
            default:
              return Promise.reject('No label config for language');
          }
        })
      )
      .subscribe({
        next: (val) => {
          if (val) {
            lokiParserAndLabelKeys.next(val);
          }
        },
        error: (err) => {
          getAppEvents().publish({
            type: AppEvents.alertWarning.name,
            payload: ['Cannot fetch labels for Log Source', err.toString()],
          });
        },
      });
  }, [
    datasourceConfig,
    lokiParserAndLabelKeys,
    formattedSelector,
    formattedStreamSelectors,
    parserConfig,
    timeRangeZone,
    opSetExtra,
  ]);
  const opSetStreamSelectors = useCallback(
    (value: Array<QueryBuilderLabelFilter>) => {
      if (!value) {
        return;
      }
      let currentValue = datasourceConfig.getValue() ?? ({} as DataSourceConfigReadModel);
      let newValue = {
        ...currentValue,
        configuration: {
          ...currentValue.configuration,
          selector: JSON.stringify(value),
        },
      };
      datasourceConfig.next(newValue);
    },
    [datasourceConfig]
  );

  const opSetRules = useCallback(
    (_rules: Rules) => {
      rules.next(_rules);
    },
    [rules]
  );
  const opAppendRule = useCallback(
    (filename: string, _rule: RuleContent) => {
      let _rules = rules.getValue();
      _rules[filename] = _rule;
      rules.next(_rules);
    },
    [rules]
  );
  const opAppendRules = useCallback(
    (_rules: Rules) => {
      let _oldRules = rules.getValue();
      rules.next({ ..._oldRules, ..._rules });
    },
    [rules]
  );
  const opSetTimeRangeZone = useCallback(
    (_value: [TimeRange, string]) => {
      if (_value) {
        timeRangeZone.next(_value);
      }
    },
    [timeRangeZone]
  );
  const opGetQueryResults = useCallback(
    (_metricNames: Array<string>) => {
      if (!queryResults || !queryResults.getValue() || queryResults === null) {
        processedQueryResults.next([]);
      }

      const results = (queryResults.getValue() as Array<DataQueryResponseData>)
        .map((e: DataQueryResponseData): QueryResult | null => {
          if (('fields' in e && !e.fields) || e.fields.length === 0) {
            return null;
          }

          const stats = e.meta?.stats;
          const matchedStats = stats?.filter((stat: QueryResultMetaStat) => _metricNames.includes(stat.displayName));

          const lineField = e.fields.find((field: Field) => field?.name === 'Line');
          const lineValues = lineField?.values;
          let matchedLines = 0;

          if (lineValues) {
            matchedLines = lineValues.length;
          }

          return {
            stats: matchedStats,
            query: e.meta?.executedQueryString?.replace('Expr: ', '') || '',
            matched: matchedLines,
          };
        })
        .filter((e) => e !== null);

      processedQueryResults.next(results as Array<QueryResult>);
    },
    [queryResults, processedQueryResults]
  );

  const opTestQuery = useCallback(
    (openExplore: boolean) => {
      combineLatest({ datasource, queries })
        // This means that we'll unsubscribe after the first value is emitted
        .pipe(take(1))
        .subscribe((e) => {
          if (!e.datasource || !e.queries) {
            return;
          }

          let targets: Array<DataQuery> = [];

          if (!e.queries || e.datasource === null) {
            return;
          }

          switch (e.datasource.type) {
            case 'loki':
              targets = e.queries.split('\n\n').map<LokiQuery>((line, index) => ({
                refId: 'Query ' + (index + 1),
                expr: line,
              }));
              break;
            case 'grafana-splunk-datasource':
              targets = e.queries.split('\n\n').map<SplunkQuery>((line, index) => ({
                refId: 'Query ' + (index + 1),
                rawQuery: true,
                query: line,
              }));
              break;
            default:
              queryErrors.next([toDataQueryError('Unsupported datasource')] as Array<DataQueryError>);
              return;
          }

          const request = createTestQuery(targets, ...timeRangeZone.getValue());
          if (!openExplore) {
            try {
              (e.datasource.query(request) as Observable<DataQueryResponse>).subscribe({
                next: (e) => {
                  if ('errors' in e && e.errors?.length !== undefined && e.errors.length > 0) {
                    queryErrors.next(e.errors);
                  }

                  if (e && 'data' in e && e.data !== undefined && e.data.length > 0) {
                    queryResults.next(e.data?.map((e) => e as DataQueryResponseData));
                  }
                },
                error: (e: any) => {
                  console.error(e);
                },
              });
            } catch (e: any) {
              console.error(e);
            }
          } else {
            try {
              const url = getExploreUrl({
                datasource: e.datasource,
                request,
              });
              window.open(url, '_blank');
            } catch (e) {
              console.error(e);
            }
          }
        });
    },
    [datasource, queries, queryResults, queryErrors, timeRangeZone]
  );

  const opSetDatasourceConfig = useCallback(
    (conf: DataSourceConfigReadModel | undefined) => {
      datasourceConfig.next(conf);
    },
    [datasourceConfig]
  );

  const opSetAlertID = useCallback(
    (value: string) => {
      alertID.next(value);
    },
    [alertID]
  );

  const opSetAlertGroup = useCallback(
    (value: string) => {
      alertGroup.next(value);
    },
    [alertGroup]
  );

  const opSetDashboardID = useCallback(
    (value: string) => {
      dashboardID.next(value);
    },
    [dashboardID]
  );

  // This pre-emptively loads the formats and other dependants without requiring another call from the view.
  // We shouldn't ever be in a situation where the formats are not updated with the target changes.
  target.subscribe((e) => {
    sigmaAPIClient.listFormats({ body: { target: e.value ?? 'loki' } }).subscribe((e) => {
      let _formats = (e.formats ?? []).map((e) => ({ label: e.description, value: e.format }));
      formats.next(_formats);
      // This is to prevent no format being selected when the target changes
      format.next(format.getValue() ?? _formats[0]);
    });
    sigmaAPIClient.listPipelines({ body: { target: e.value ?? 'loki' } }).subscribe((e) => {
      let _pipelines = (e.pipelines ?? []).map((e) => ({ label: e.description, value: e.pipeline }));
      pipelines.next(_pipelines);
    });
  });

  return (
    <ConversionContext.Provider
      value={{
        data: {
          targets,
          target,
          extraArguments,
          transformedExtraArguments,
          formats,
          format,
          pipelines,
          activePipelines,
          datasource,
          queries,
          storeConvertResponse,
          fields,
          parserConfig,
          filters,
          selector,
          extra,
          rules,
          plainRules,
          queryResults,
          queryErrors,
          timeRangeZone,
          processedQueryResults,
          conversionId,
          lokiParserAndLabelKeys,
          streamSelectors,
          formattedStreamSelectors,
          datasourceConfig,
          alertID,
          alertGroup,
          dashboardID,
        },
        operations: {
          listTargets: opListTargets,
          setTarget: opSetTarget,
          setFormat: opSetFormat,
          setActivePipelines: opSetActivePipelines,
          setTransformedExtraArguments: opSetTransformedExtraArguments,
          setDatasource: opSetDatasource,
          setQueries: opSetQueries,
          setFields: opSetFields,
          setParserConfig: opSetParserConfig,
          setFilters: opSetFilters,
          setSelector: opSetSelector,
          setExtra: opSetExtra,
          getLokiParserAndLabelKeys: opGetLokiParserAndLabelKeys,
          setStreamSelectors: opSetStreamSelectors,
          setRules: opSetRules,
          appendRule: opAppendRule,
          appendRules: opAppendRules,
          convert: opConvert,
          testQuery: opTestQuery,
          getQueryResults: opGetQueryResults,
          setTimeRangeZone: opSetTimeRangeZone,
          setDatasourceConfig: opSetDatasourceConfig,
          setAlertID: opSetAlertID,
          setAlertGroup: opSetAlertGroup,
          setDashboardID: opSetDashboardID,
        },
      }}
    >
      {props.children}
    </ConversionContext.Provider>
  );
};
