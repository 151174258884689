// tslint:disable
/**
 * Detect service API.
 * The Detect service exposes an HTTP API to do actions within the plugin like saving pipelines, conversions of rules, and manage external rule sources.
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: security-operations@grafana.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Observable } from 'rxjs';
import type { AjaxResponse } from 'rxjs/ajax';
import { BaseAPI, throwIfNullOrUndefined, encodeURI } from '../runtime';
import type { OperationOpts, HttpHeaders, HttpQuery } from '../runtime';
import type {
  CreateSigmaRuleRequestModel,
  ListConversion400ResponseModel,
  ListSigmaRuleNames200ResponseInnerModel,
  ListSigmaRuleRuleConversions200ResponseModel,
  ListSigmaRuleVersions200ResponseInnerModel,
  SigmaRuleCreateModel,
  SigmaRuleReadModel,
  SigmaRuleSourceRepoReadModel,
  SigmaRuleUpdateModel,
  UpdateSigmaRuleRequestModel,
} from '../models';

export interface CreateSigmaRuleRequest {
  createSigmaRuleRequestModel: CreateSigmaRuleRequestModel;
}

export interface DeleteSigmaRuleRequest {
  id: string;
}

export interface ListSigmaRuleNamesRequest {
  type: ListSigmaRuleNamesTypeEnum;
  status?: ListSigmaRuleNamesStatusEnum;
  level?: ListSigmaRuleNamesLevelEnum;
  deployment?: ListSigmaRuleNamesDeploymentEnum;
  contains?: string;
  repoId?: string;
  updatedAtFrom?: string;
  updatedAtTo?: string;
  deletedAtFrom?: string;
  deletedAtTo?: string;
  ruleState?: ListSigmaRuleNamesRuleStateEnum;
  conversions?: boolean;
}

export interface ListSigmaRuleRuleConversionsRequest {
  id: string;
  page?: number;
  itemsPerPage?: number;
  startTime?: string;
  endTime?: string;
}

export interface ListSigmaRuleVersionsRequest {
  filename: string;
  repoId?: string;
}

export interface ReadSigmaRuleRequest {
  id: string;
}

export interface ReadSigmaRuleSourceRepoRequest {
  id: string;
}

export interface UpdateSigmaRuleRequest {
  id: string;
  updateSigmaRuleRequestModel: UpdateSigmaRuleRequestModel;
}

/**
 * no description
 */
export class SigmaRuleApi extends BaseAPI {
  /**
   * Creates a new SigmaRule and persists it to storage.
   * Create a new SigmaRule
   */
  createSigmaRule({ createSigmaRuleRequestModel }: CreateSigmaRuleRequest): Observable<SigmaRuleCreateModel>;
  createSigmaRule(
    { createSigmaRuleRequestModel }: CreateSigmaRuleRequest,
    opts?: OperationOpts
  ): Observable<AjaxResponse<SigmaRuleCreateModel>>;
  createSigmaRule(
    { createSigmaRuleRequestModel }: CreateSigmaRuleRequest,
    opts?: OperationOpts
  ): Observable<SigmaRuleCreateModel | AjaxResponse<SigmaRuleCreateModel>> {
    throwIfNullOrUndefined(createSigmaRuleRequestModel, 'createSigmaRuleRequestModel', 'createSigmaRule');

    const headers: HttpHeaders = {
      'Content-Type': 'application/json',
    };

    return this.request<SigmaRuleCreateModel>(
      {
        url: '/sigma-rules',
        method: 'POST',
        headers,
        body: createSigmaRuleRequestModel,
      },
      opts?.responseOpts
    );
  }

  /**
   * Deletes the SigmaRule with the requested ID.
   * Deletes a SigmaRule by ID
   */
  deleteSigmaRule({ id }: DeleteSigmaRuleRequest): Observable<void>;
  deleteSigmaRule({ id }: DeleteSigmaRuleRequest, opts?: OperationOpts): Observable<void | AjaxResponse<void>>;
  deleteSigmaRule({ id }: DeleteSigmaRuleRequest, opts?: OperationOpts): Observable<void | AjaxResponse<void>> {
    throwIfNullOrUndefined(id, 'id', 'deleteSigmaRule');

    return this.request<void>(
      {
        url: '/sigma-rules/content/{id}'.replace('{id}', encodeURI(id)),
        method: 'DELETE',
      },
      opts?.responseOpts
    );
  }

  /**
   * Lists sigma rule filenames
   */
  listSigmaRuleNames({
    type,
    status,
    level,
    deployment,
    contains,
    repoId,
    updatedAtFrom,
    updatedAtTo,
    deletedAtFrom,
    deletedAtTo,
    ruleState,
    conversions,
  }: ListSigmaRuleNamesRequest): Observable<Array<ListSigmaRuleNames200ResponseInnerModel>>;
  listSigmaRuleNames(
    {
      type,
      status,
      level,
      deployment,
      contains,
      repoId,
      updatedAtFrom,
      updatedAtTo,
      deletedAtFrom,
      deletedAtTo,
      ruleState,
      conversions,
    }: ListSigmaRuleNamesRequest,
    opts?: OperationOpts
  ): Observable<AjaxResponse<Array<ListSigmaRuleNames200ResponseInnerModel>>>;
  listSigmaRuleNames(
    {
      type,
      status,
      level,
      deployment,
      contains,
      repoId,
      updatedAtFrom,
      updatedAtTo,
      deletedAtFrom,
      deletedAtTo,
      ruleState,
      conversions,
    }: ListSigmaRuleNamesRequest,
    opts?: OperationOpts
  ): Observable<
    Array<ListSigmaRuleNames200ResponseInnerModel> | AjaxResponse<Array<ListSigmaRuleNames200ResponseInnerModel>>
  > {
    throwIfNullOrUndefined(type, 'type', 'listSigmaRuleNames');

    const query: HttpQuery = {
      // required parameters are used directly since they are already checked by throwIfNullOrUndefined
      type: type,
    };

    if (status != null) {
      query['status'] = status;
    }
    if (level != null) {
      query['level'] = level;
    }
    if (deployment != null) {
      query['deployment'] = deployment;
    }
    if (contains != null) {
      query['contains'] = contains;
    }
    if (repoId != null) {
      query['repo_id'] = repoId;
    }
    if (updatedAtFrom != null) {
      query['updated_at_from'] = (updatedAtFrom as any).toISOString();
    }
    if (updatedAtTo != null) {
      query['updated_at_to'] = (updatedAtTo as any).toISOString();
    }
    if (deletedAtFrom != null) {
      query['deleted_at_from'] = (deletedAtFrom as any).toISOString();
    }
    if (deletedAtTo != null) {
      query['deleted_at_to'] = (deletedAtTo as any).toISOString();
    }
    if (ruleState != null) {
      query['rule_state'] = ruleState;
    }
    if (conversions != null) {
      query['conversions'] = conversions;
    }

    return this.request<Array<ListSigmaRuleNames200ResponseInnerModel>>(
      {
        url: '/sigma-rules',
        method: 'GET',
        query,
      },
      opts?.responseOpts
    );
  }

  /**
   * List attached RuleConversions.
   * List attached RuleConversions
   */
  listSigmaRuleRuleConversions({
    id,
    page,
    itemsPerPage,
    startTime,
    endTime,
  }: ListSigmaRuleRuleConversionsRequest): Observable<ListSigmaRuleRuleConversions200ResponseModel>;
  listSigmaRuleRuleConversions(
    { id, page, itemsPerPage, startTime, endTime }: ListSigmaRuleRuleConversionsRequest,
    opts?: OperationOpts
  ): Observable<AjaxResponse<ListSigmaRuleRuleConversions200ResponseModel>>;
  listSigmaRuleRuleConversions(
    { id, page, itemsPerPage, startTime, endTime }: ListSigmaRuleRuleConversionsRequest,
    opts?: OperationOpts
  ): Observable<
    ListSigmaRuleRuleConversions200ResponseModel | AjaxResponse<ListSigmaRuleRuleConversions200ResponseModel>
  > {
    throwIfNullOrUndefined(id, 'id', 'listSigmaRuleRuleConversions');

    const query: HttpQuery = {};

    if (page != null) {
      query['page'] = page;
    }
    if (itemsPerPage != null) {
      query['itemsPerPage'] = itemsPerPage;
    }
    if (startTime != null) {
      query['startTime'] = (startTime as any).toISOString();
    }
    if (endTime != null) {
      query['endTime'] = (endTime as any).toISOString();
    }

    return this.request<ListSigmaRuleRuleConversions200ResponseModel>(
      {
        url: '/sigma-rules/{id}/rule-conversions'.replace('{id}', encodeURI(id)),
        method: 'GET',
        query,
      },
      opts?.responseOpts
    );
  }

  /**
   * Lists versions of a sigma rule.
   */
  listSigmaRuleVersions({
    filename,
    repoId,
  }: ListSigmaRuleVersionsRequest): Observable<Array<ListSigmaRuleVersions200ResponseInnerModel>>;
  listSigmaRuleVersions(
    { filename, repoId }: ListSigmaRuleVersionsRequest,
    opts?: OperationOpts
  ): Observable<AjaxResponse<Array<ListSigmaRuleVersions200ResponseInnerModel>>>;
  listSigmaRuleVersions(
    { filename, repoId }: ListSigmaRuleVersionsRequest,
    opts?: OperationOpts
  ): Observable<
    Array<ListSigmaRuleVersions200ResponseInnerModel> | AjaxResponse<Array<ListSigmaRuleVersions200ResponseInnerModel>>
  > {
    throwIfNullOrUndefined(filename, 'filename', 'listSigmaRuleVersions');

    const query: HttpQuery = {
      // required parameters are used directly since they are already checked by throwIfNullOrUndefined
      filename: filename,
    };

    if (repoId != null) {
      query['repo_id'] = repoId;
    }

    return this.request<Array<ListSigmaRuleVersions200ResponseInnerModel>>(
      {
        url: '/sigma-rules/versions',
        method: 'GET',
        query,
      },
      opts?.responseOpts
    );
  }

  /**
   * Finds the SigmaRule with the requested ID and returns it.
   * Find a SigmaRule by ID
   */
  readSigmaRule({ id }: ReadSigmaRuleRequest): Observable<SigmaRuleReadModel>;
  readSigmaRule({ id }: ReadSigmaRuleRequest, opts?: OperationOpts): Observable<AjaxResponse<SigmaRuleReadModel>>;
  readSigmaRule(
    { id }: ReadSigmaRuleRequest,
    opts?: OperationOpts
  ): Observable<SigmaRuleReadModel | AjaxResponse<SigmaRuleReadModel>> {
    throwIfNullOrUndefined(id, 'id', 'readSigmaRule');

    return this.request<SigmaRuleReadModel>(
      {
        url: '/sigma-rules/content/{id}'.replace('{id}', encodeURI(id)),
        method: 'GET',
      },
      opts?.responseOpts
    );
  }

  /**
   * Find the attached GithubRepo of the SigmaRule with the given ID
   * Find the attached GithubRepo
   */
  readSigmaRuleSourceRepo({ id }: ReadSigmaRuleSourceRepoRequest): Observable<SigmaRuleSourceRepoReadModel>;
  readSigmaRuleSourceRepo(
    { id }: ReadSigmaRuleSourceRepoRequest,
    opts?: OperationOpts
  ): Observable<AjaxResponse<SigmaRuleSourceRepoReadModel>>;
  readSigmaRuleSourceRepo(
    { id }: ReadSigmaRuleSourceRepoRequest,
    opts?: OperationOpts
  ): Observable<SigmaRuleSourceRepoReadModel | AjaxResponse<SigmaRuleSourceRepoReadModel>> {
    throwIfNullOrUndefined(id, 'id', 'readSigmaRuleSourceRepo');

    return this.request<SigmaRuleSourceRepoReadModel>(
      {
        url: '/sigma-rules/{id}/source-repo'.replace('{id}', encodeURI(id)),
        method: 'GET',
      },
      opts?.responseOpts
    );
  }

  /**
   * Updates a SigmaRule and persists changes to storage.
   * Updates a SigmaRule
   */
  updateSigmaRule({ id, updateSigmaRuleRequestModel }: UpdateSigmaRuleRequest): Observable<SigmaRuleUpdateModel>;
  updateSigmaRule(
    { id, updateSigmaRuleRequestModel }: UpdateSigmaRuleRequest,
    opts?: OperationOpts
  ): Observable<AjaxResponse<SigmaRuleUpdateModel>>;
  updateSigmaRule(
    { id, updateSigmaRuleRequestModel }: UpdateSigmaRuleRequest,
    opts?: OperationOpts
  ): Observable<SigmaRuleUpdateModel | AjaxResponse<SigmaRuleUpdateModel>> {
    throwIfNullOrUndefined(id, 'id', 'updateSigmaRule');
    throwIfNullOrUndefined(updateSigmaRuleRequestModel, 'updateSigmaRuleRequestModel', 'updateSigmaRule');

    const headers: HttpHeaders = {
      'Content-Type': 'application/json',
    };

    return this.request<SigmaRuleUpdateModel>(
      {
        url: '/sigma-rules/content/{id}'.replace('{id}', encodeURI(id)),
        method: 'PATCH',
        headers,
        body: updateSigmaRuleRequestModel,
      },
      opts?.responseOpts
    );
  }
}

/**
 * @export
 * @enum {string}
 */
export enum ListSigmaRuleNamesTypeEnum {
  All = 'all',
  Custom = 'custom',
  Standard = 'standard',
}
/**
 * @export
 * @enum {string}
 */
export enum ListSigmaRuleNamesStatusEnum {
  All = 'all',
  Stable = 'stable',
  Test = 'test',
  Experimental = 'experimental',
  Deprecated = 'deprecated',
}
/**
 * @export
 * @enum {string}
 */
export enum ListSigmaRuleNamesLevelEnum {
  All = 'all',
  Low = 'low',
  Medium = 'medium',
  High = 'high',
}
/**
 * @export
 * @enum {string}
 */
export enum ListSigmaRuleNamesDeploymentEnum {
  All = 'all',
  Conversion = 'conversion',
  Alert = 'alert',
  Dashboard = 'dashboard',
}
/**
 * @export
 * @enum {string}
 */
export enum ListSigmaRuleNamesRuleStateEnum {
  All = 'all',
  Outdated = 'outdated',
  Deleted = 'deleted',
  NotDeleted = 'not_deleted',
}
