import { Field, Input } from '@grafana/ui';
import React, { ChangeEvent, ReactNode, useCallback, useState } from 'react';

export interface PipelineTitleInputProps {
  value?: string;
  invalid?: boolean;
  error?: ReactNode;
  onChange?(e: string): void;
}

export const PipelineTitleInput = (props: PipelineTitleInputProps) => {
  const [title, setTitle] = useState(props.value ?? '');
  const cb = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setTitle(e.target.value);
      if (props.onChange) {
        props.onChange(e.target.value);
      }
    },
    [props]
  );

  return (
    <Field label="Friendly Name" invalid={props.invalid} error={props.error}>
      <Input onChangeCapture={cb} value={title} />
    </Field>
  );
};
