import React, { useContext, useEffect, useState } from 'react';
import {
  DataFrame,
  DecimalCount,
  Field,
  FieldType,
  FormattedValue,
  QueryResultMetaStat,
  toDataFrame,
  toFixed,
} from '@grafana/data';
import { TimeZone } from '@grafana/schema';
import { QueryResult } from 'shared/types';
import { ConversionContext } from 'stores/conversion';
import { css } from '@emotion/css';
import { clamp } from 'lodash';

export interface TestQueryResultProps {
  queryResults?: Array<DataFrame>;
}

const rowStyle = css`
  vertical-align: top;
  padding: 5px;
`;

const headerStyle = css`
  vertical-align: top;
  padding: 5px;
  color: rgb(255, 136, 51);
`;

export const TestQueryResult = (props: TestQueryResultProps) => {
  if (!props.queryResults) {
    return <p>Nothing to display</p>;
  }

  const rows = props.queryResults.map((data: DataFrame, dfIdx: number): React.JSX.Element => {
    return (
      <tr key={data.name + dfIdx.toString()}>
        {data.fields.map((field: Field, fIdx: number) => {
          if (field.type === FieldType.other) {
            const stats = (field.values as Array<QueryResultMetaStat>).map((stat: QueryResultMetaStat) => {
              let value = stat.value.toString();
              let unit = stat.unit;
              if (stat.unit && stat.unit === 'decbytes') {
                const data = SIPrefix('B')(stat.value);
                value = data.text;
                unit = data.suffix;
              }

              return (
                <tr className={rowStyle} key={stat.displayName}>
                  <td className={rowStyle}>{stat.displayName.replace('Summary: ', '')}</td>
                  <td className={rowStyle}>{value}</td>
                  <td className={rowStyle}>{unit}</td>
                </tr>
              );
            });

            return (
              <td key={data.name + fIdx.toString()}>
                <table key={data.name + fIdx.toString()}>
                  <thead>
                    <tr className={headerStyle}>
                      <th className={headerStyle}>Metric</th>
                      <th className={headerStyle}>Value</th>
                      <th className={headerStyle}>Unit</th>
                    </tr>
                  </thead>
                  <tbody>{stats}</tbody>
                </table>
              </td>
            );
          } else {
            return (
              <td className={rowStyle} key={field.name}>
                {field.values}
              </td>
            );
          }
        })}
      </tr>
    );
  });

  const headers =
    props.queryResults &&
    props.queryResults.length > 0 &&
    props.queryResults[0].fields.map((field: Field, fIdx: number) => {
      return (
        <th className={rowStyle} key={fIdx}>
          {field.name}
        </th>
      );
    });

  const table = (
    <table
      className={css`
        margin-bottom: 10px;
        vertical-align: top;
      `}
      width="100%"
    >
      <thead>
        <tr className={headerStyle}>{headers}</tr>
      </thead>
      <tbody>{rows}</tbody>
    </table>
  );

  return <>{table}</>;
};

export const StatefulTestQueryResult = () => {
  const {
    data: { processedQueryResults: stateProcessedQueryResults },
  } = useContext(ConversionContext);

  const [componentProps, setComponentProps] = useState<TestQueryResultProps>();

  useEffect(() => {
    stateProcessedQueryResults.subscribe((results: Array<QueryResult>) => {
      setComponentProps({ queryResults: convertToDataFrames(results) });
    });
  }, [stateProcessedQueryResults]);

  return <TestQueryResult {...componentProps} />;
};

export const convertToDataFrames = (queryResults: Array<QueryResult>): Array<DataFrame> => {
  return queryResults.map((result: QueryResult): DataFrame => {
    return toDataFrame({
      name: 'Query Results',
      fields: [
        { name: 'Query', type: FieldType.string, values: [result.query] },
        { name: 'Matched', type: FieldType.number, values: [result.matched] },
        {
          name: 'Stats',
          type: FieldType.other,
          values: result.stats,
        },
      ],
    });
  });
};

// The following is taken from Grafana's codebase.
export type ValueFormatter = (
  value: number,
  decimals?: DecimalCount,
  scaledDecimals?: DecimalCount,
  timeZone?: TimeZone,
  showMs?: boolean
) => FormattedValue;

const SI_PREFIXES = ['f', 'p', 'n', 'µ', 'm', '', 'k', 'M', 'G', 'T', 'P', 'E', 'Z', 'Y'];
const SI_BASE_INDEX = SI_PREFIXES.indexOf('');

const logb = (b: number, x: number) => Math.log10(x) / Math.log10(b);

export function scaledUnits(factor: number, extArray: Array<string>, offset = 0): ValueFormatter {
  return (size: number, decimals?: DecimalCount) => {
    if (size === null || size === undefined) {
      return { text: '' };
    }

    if (size === Number.NEGATIVE_INFINITY || size === Number.POSITIVE_INFINITY || isNaN(size)) {
      return { text: size.toLocaleString() };
    }

    const siIndex = size === 0 ? 0 : Math.floor(logb(factor, Math.abs(size)));
    const suffix = extArray[clamp(offset + siIndex, 0, extArray.length - 1)];

    return {
      text: toFixed(size / factor ** clamp(siIndex, -offset, extArray.length - offset - 1), decimals),
      suffix,
    };
  };
}

export function SIPrefix(unit: string, offset = 0): ValueFormatter {
  const units = SI_PREFIXES.map((p) => ' ' + p + unit);
  return scaledUnits(1000, units, SI_BASE_INDEX + offset);
}
