import { CompletionItem } from './auto';
import { v4 } from 'uuid';

export const getPipelineTransforms = (): Array<CompletionItem> => {
  return [...getTypes(), includeSuggestion, excludeSuggestion];
};

export const getTypes = (): Array<CompletionItem> => {
  const types: { [type: string]: string } = {
    add_condition: '\n  conditions:\n    Condition: ', // eslint-disable-line camelcase
    field_name_mapping: '\n  mapping:\n    Field: ', // eslint-disable-line camelcase
    value_placeholders: '\n  ', // eslint-disable-line camelcase
  };

  return Object.keys(types).map<CompletionItem>((key): CompletionItem => {
    return {
      insertText: `- id: ${v4()}\n  type: ${key}${types[key]}`,
      kind: 17,
      label: key,
      regex: new RegExp(`${key}:`, 'gim'),
      regexLine: {
        previousKeyword: /transformations:/gim,
      },
    };
  });
};

const includeSuggestion: CompletionItem = {
  insertText: 'include:\n  - ',
  kind: 17,
  label: 'include',
  regex: /include:/gim,
  regexLine: {
    previousKeyword: /type:\svalue_placeholders/gim,
  },
};
const excludeSuggestion: CompletionItem = {
  insertText: 'exclude:\n  - ',
  kind: 17,
  label: 'exclude',
  regex: /exclude:/gim,
  regexLine: {
    previousKeyword: /type: value_placeholders/gim,
  },
};
