import React, { useContext, useEffect, useState } from 'react';
import { Label, ReactMonacoEditor } from '@grafana/ui';
import { SigmaRulesContext } from '../../stores/sigmarules';
import { useQueryParams } from '../../hooks/useQueryParams';
import { NullID } from '../../shared/constants';
import { suggestions } from 'utils/autocomplete/rules';

interface RuleEditorFieldContentProps {
  content?: string;

  onChange(content: string): void;
}

export const RuleEditorFieldContent = (props: RuleEditorFieldContentProps) => {
  return (
    <>
      <Label description={'Rule matching the SigmaHQ specification'}>Sigma Rule Content</Label>
      <ReactMonacoEditor
        language="yaml"
        value={props.content}
        height={400}
        onChange={(e) => {
          props.onChange(e ?? '');
        }}
        beforeMount={(monaco) => {
          monaco.languages.registerCompletionItemProvider('yaml', {
            provideCompletionItems: (model, pos) => {
              return {
                suggestions: suggestions(model, pos),
                incomplete: true,
              };
            },
          });
        }}
        options={{
          renderValidationDecorations: 'on',
        }}
      />
    </>
  );
};

export const StatefulRuleEditorFieldContent = () => {
  const [qs] = useQueryParams();
  const [content, setContent] = useState<string>('');
  const {
    data: { createModel },
    operations: { updateCreateAttributes, loadExistingRule },
  } = useContext(SigmaRulesContext);

  useEffect(() => {
    createModel.subscribe((model) => {
      setContent(model.content);
    });
  }, [createModel]);

  useEffect(() => {
    if (qs && qs.has('filename')) {
      loadExistingRule(`${NullID}/${qs.get('filename')}`);
    } else {
      loadExistingRule('');
    }
  }, [qs, loadExistingRule]);

  return <RuleEditorFieldContent onChange={(e) => updateCreateAttributes({ content: e })} content={content} />;
};
