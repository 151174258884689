import {
  EmbeddedScene,
  PanelBuilders,
  SceneAppPage,
  SceneControlsSpacer,
  SceneFlexItem,
  SceneQueryRunner,
  SceneRefreshPicker,
  SceneTimePicker,
  SceneTimeRange,
  SplitLayout,
} from '@grafana/scenes';
import { PLUGIN_ROOT } from '../shared/constants';

export const getAlertScene = () => {
  const timeRange = new SceneTimeRange({
    from: 'now-7d/d',
    to: 'now',
  });

  const dashboardRunner = new SceneQueryRunner({
    datasource: {
      uid: 'alert-rule-history',
    },
    queries: [
      {
        refId: 'A',
        QueryType: 'dashboards',
      },
    ],
  });

  const alertsRunner = new SceneQueryRunner({
    datasource: {
      uid: 'alert-rule-history',
    },
    queries: [
      {
        refId: 'A',
        QueryType: 'alerts',
      },
    ],
  });

  return new EmbeddedScene({
    $timeRange: timeRange,
    body: new SplitLayout({
      direction: 'row',
      initialSize: 0.4,
      primary: new SceneFlexItem({
        body: PanelBuilders.table()
          .setTitle('Dashboards')
          .setData(dashboardRunner)
          .setOverrides((b) => {
            b.matchFieldsWithName('dashboard_title').overrideDisplayName('Dashboard Title');
            b.matchFieldsWithName('dashboard_link').overrideCustomFieldConfig('hidden', true);
          })
          .build(),
      }),
      secondary: new SceneFlexItem({
        body: PanelBuilders.table()
          .setTitle('Conversions')
          .setData(alertsRunner)
          .setOverrides((b) => {
            b.matchFieldsWithName('alert_link').overrideCustomFieldConfig('hidden', true);
            b.matchFieldsWithName('rule_link').overrideCustomFieldConfig('hidden', true);
            b.matchFieldsWithName('id').overrideLinks([
              {
                title: 'Converter',
                url: '/a/grafana-detect-app/converter${__url.params:exclude:var-conversionId}&var-conversionId=${__value.raw}',
              },
            ]);
          })
          .build(),
      }),
    }),
    controls: [new SceneControlsSpacer(), new SceneTimePicker({}), new SceneRefreshPicker({})],
  });
};

export const getAlertPage = () => {
  return new SceneAppPage({
    title: 'Conversions',
    getScene: getAlertScene,
    url: `${PLUGIN_ROOT}/scenes/sigma/alerts`,
  });
};
