// tslint:disable
/**
 * Detect service API.
 * The Detect service exposes an HTTP API to do actions within the plugin like saving pipelines, conversions of rules, and manage external rule sources.
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: security-operations@grafana.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Observable } from 'rxjs';
import type { AjaxResponse } from 'rxjs/ajax';
import { BaseAPI, throwIfNullOrUndefined, encodeURI } from '../runtime';
import type { OperationOpts, HttpHeaders, HttpQuery } from '../runtime';
import type {
  CreateFetchJobRequestModel,
  CreateRunNowFetchJobRequestModel,
  FetchJobCreateModel,
  FetchJobReadModel,
  FetchJobRepoReadModel,
  ListConversion400ResponseModel,
  ListFetchJob200ResponseModel,
} from '../models';

export interface CreateFetchJobRequest {
  createFetchJobRequestModel: CreateFetchJobRequestModel;
}

export interface CreateRunNowFetchJobRequest {
  createRunNowFetchJobRequestModel?: CreateRunNowFetchJobRequestModel;
}

export interface DeleteFetchJobRequest {
  id: string;
}

export interface ListFetchJobRequest {
  page?: number;
  itemsPerPage?: number;
  startTime?: string;
  endTime?: string;
}

export interface ReadFetchJobRequest {
  id: string;
}

export interface ReadFetchJobRepoRequest {
  id: string;
}

/**
 * no description
 */
export class FetchJobApi extends BaseAPI {
  /**
   * Creates a new FetchJob and persists it to storage.
   * Create a new FetchJob
   */
  createFetchJob({ createFetchJobRequestModel }: CreateFetchJobRequest): Observable<FetchJobCreateModel>;
  createFetchJob(
    { createFetchJobRequestModel }: CreateFetchJobRequest,
    opts?: OperationOpts
  ): Observable<AjaxResponse<FetchJobCreateModel>>;
  createFetchJob(
    { createFetchJobRequestModel }: CreateFetchJobRequest,
    opts?: OperationOpts
  ): Observable<FetchJobCreateModel | AjaxResponse<FetchJobCreateModel>> {
    throwIfNullOrUndefined(createFetchJobRequestModel, 'createFetchJobRequestModel', 'createFetchJob');

    const headers: HttpHeaders = {
      'Content-Type': 'application/json',
    };

    return this.request<FetchJobCreateModel>(
      {
        url: '/fetch-jobs',
        method: 'POST',
        headers,
        body: createFetchJobRequestModel,
      },
      opts?.responseOpts
    );
  }

  /**
   * Creates a run-now job that will run as soon as possible.
   */
  createRunNowFetchJob({ createRunNowFetchJobRequestModel }: CreateRunNowFetchJobRequest): Observable<void>;
  createRunNowFetchJob(
    { createRunNowFetchJobRequestModel }: CreateRunNowFetchJobRequest,
    opts?: OperationOpts
  ): Observable<void | AjaxResponse<void>>;
  createRunNowFetchJob(
    { createRunNowFetchJobRequestModel }: CreateRunNowFetchJobRequest,
    opts?: OperationOpts
  ): Observable<void | AjaxResponse<void>> {
    const headers: HttpHeaders = {
      'Content-Type': 'application/json',
    };

    return this.request<void>(
      {
        url: '/fetch-job/force',
        method: 'POST',
        headers,
        body: createRunNowFetchJobRequestModel,
      },
      opts?.responseOpts
    );
  }

  /**
   * Deletes the FetchJob with the requested ID.
   * Deletes a FetchJob by ID
   */
  deleteFetchJob({ id }: DeleteFetchJobRequest): Observable<void>;
  deleteFetchJob({ id }: DeleteFetchJobRequest, opts?: OperationOpts): Observable<void | AjaxResponse<void>>;
  deleteFetchJob({ id }: DeleteFetchJobRequest, opts?: OperationOpts): Observable<void | AjaxResponse<void>> {
    throwIfNullOrUndefined(id, 'id', 'deleteFetchJob');

    return this.request<void>(
      {
        url: '/fetch-jobs/{id}'.replace('{id}', encodeURI(id)),
        method: 'DELETE',
      },
      opts?.responseOpts
    );
  }

  /**
   * List FetchJobs.
   * List FetchJobs
   */
  listFetchJob({
    page,
    itemsPerPage,
    startTime,
    endTime,
  }: ListFetchJobRequest): Observable<ListFetchJob200ResponseModel>;
  listFetchJob(
    { page, itemsPerPage, startTime, endTime }: ListFetchJobRequest,
    opts?: OperationOpts
  ): Observable<AjaxResponse<ListFetchJob200ResponseModel>>;
  listFetchJob(
    { page, itemsPerPage, startTime, endTime }: ListFetchJobRequest,
    opts?: OperationOpts
  ): Observable<ListFetchJob200ResponseModel | AjaxResponse<ListFetchJob200ResponseModel>> {
    const query: HttpQuery = {};

    if (page != null) {
      query['page'] = page;
    }
    if (itemsPerPage != null) {
      query['itemsPerPage'] = itemsPerPage;
    }
    if (startTime != null) {
      query['startTime'] = (startTime as any).toISOString();
    }
    if (endTime != null) {
      query['endTime'] = (endTime as any).toISOString();
    }

    return this.request<ListFetchJob200ResponseModel>(
      {
        url: '/fetch-jobs',
        method: 'GET',
        query,
      },
      opts?.responseOpts
    );
  }

  /**
   * Finds the FetchJob with the requested ID and returns it.
   * Find a FetchJob by ID
   */
  readFetchJob({ id }: ReadFetchJobRequest): Observable<FetchJobReadModel>;
  readFetchJob({ id }: ReadFetchJobRequest, opts?: OperationOpts): Observable<AjaxResponse<FetchJobReadModel>>;
  readFetchJob(
    { id }: ReadFetchJobRequest,
    opts?: OperationOpts
  ): Observable<FetchJobReadModel | AjaxResponse<FetchJobReadModel>> {
    throwIfNullOrUndefined(id, 'id', 'readFetchJob');

    return this.request<FetchJobReadModel>(
      {
        url: '/fetch-jobs/{id}'.replace('{id}', encodeURI(id)),
        method: 'GET',
      },
      opts?.responseOpts
    );
  }

  /**
   * Find the attached GithubRepo of the FetchJob with the given ID
   * Find the attached GithubRepo
   */
  readFetchJobRepo({ id }: ReadFetchJobRepoRequest): Observable<FetchJobRepoReadModel>;
  readFetchJobRepo(
    { id }: ReadFetchJobRepoRequest,
    opts?: OperationOpts
  ): Observable<AjaxResponse<FetchJobRepoReadModel>>;
  readFetchJobRepo(
    { id }: ReadFetchJobRepoRequest,
    opts?: OperationOpts
  ): Observable<FetchJobRepoReadModel | AjaxResponse<FetchJobRepoReadModel>> {
    throwIfNullOrUndefined(id, 'id', 'readFetchJobRepo');

    return this.request<FetchJobRepoReadModel>(
      {
        url: '/fetch-jobs/{id}/repo'.replace('{id}', encodeURI(id)),
        method: 'GET',
      },
      opts?.responseOpts
    );
  }
}
