import { css } from '@emotion/css';
import { CollapsableSection, Stack } from '@grafana/ui';
import { useBreakPoint } from 'hooks/useBreakPoint';
import React, { useState } from 'react';

interface VerticalContainerProps {
  children: React.ReactNode;
  breakPoint: number;
  label: string;
  defaultOpen?: boolean;
}

export const VerticalContainer = (props: VerticalContainerProps) => {
  const [size] = useBreakPoint(props.breakPoint);
  const [open, setOpen] = useState(props.defaultOpen ?? false);

  if (size) {
    return (
      <Stack direction="column" justifyContent="flex-end" height="100%" width="100%">
        {props.children}
      </Stack>
    );
  }

  return (
    <CollapsableSection
      isOpen={open}
      label={props.label}
      onToggle={() => setOpen(!open)}
      className={css`
        width: 100%;
      `}
    >
      {props.children}
    </CollapsableSection>
  );
};
