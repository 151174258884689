import React from 'react';
import { Field, Input } from '@grafana/ui';
import { DirectoryRow } from '../types';
import { LabelList } from './labels/LabelList';

export interface DirectoryPanelProps {
  row: DirectoryRow;
}

export const DirectoryPanel = (props: DirectoryPanelProps) => {
  const { row } = props;

  return (
    <div>
      <Field label={'Directory Path'}>
        <Input value={row[0]} readOnly name="path" />
      </Field>
      <Field label={'Rules by repository'}>
        <LabelList labels={row[2]} />
      </Field>
    </div>
  );
};
