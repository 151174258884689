import React, { useMemo } from 'react';
import { Field, Icon, TagList, Tooltip } from '@grafana/ui';
import { GREY_COLOR_INDEX, LIGHT_BLUE_COLOR_INDEX } from '../../../shared/constants';
import { css } from '@emotion/css';

interface QueryEditorTagsProps {
  tags: Array<string>;
  fields: Array<string>;
}

export const QueryEditorTags = ({ tags, fields }: QueryEditorTagsProps) => {
  const fieldSet = useMemo(() => new Set(fields), [fields]);
  return (
    <Field
      label="Detected Labels"
      description={
        <>
          Labels detected within logs can be used to filter the logs{' '}
          <Tooltip content="Gray: the detected label doesn't have an equivalent field in the detected fields. Blue: the detected label has a matching field.">
            <Icon name="info-circle" />
          </Tooltip>
        </>
      }
    >
      <TagList
        tags={tags}
        getColorIndex={(tag) => {
          if (fieldSet.has(tag)) {
            return LIGHT_BLUE_COLOR_INDEX;
          }
          return GREY_COLOR_INDEX;
        }}
        className={css`
          justify-content: flex-start;
          max-height: 6em;
          overflow-y: scroll;
        `}
      />
    </Field>
  );
};
