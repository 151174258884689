/* eslint-disable camelcase */
export const installAlertingDashboardPanels = (
  detectFolderUid: string,
  incidentUid?: string,
  alertingHistoryUid?: string
) => {
  const panels = [];
  if (alertingHistoryUid) {
    panels.push(
      {
        collapsed: false,
        gridPos: {
          h: 1,
          w: 24,
          x: 0,
          y: 0,
        },
        id: 10,
        panels: [],
        title: 'Grafana Detect Alert Data',
        type: 'row',
      },
      {
        datasource: {
          type: 'loki',
          uid: alertingHistoryUid,
        },
        description: '',
        fieldConfig: {
          defaults: {
            color: {
              mode: 'thresholds',
            },
            custom: {
              align: 'auto',
              cellOptions: {
                type: 'auto',
              },
              inspect: false,
            },
            mappings: [],
            thresholds: {
              mode: 'absolute',
              steps: [
                {
                  color: 'green',
                  value: null,
                },
                {
                  color: 'red',
                  value: 80,
                },
              ],
            },
          },
          overrides: [],
        },
        gridPos: {
          h: 8,
          w: 19,
          x: 0,
          y: 1,
        },
        id: 12,
        options: {
          cellHeight: 'sm',
          footer: {
            countRows: false,
            fields: '',
            reducer: ['sum'],
            show: false,
          },
          showHeader: true,
        },
        pluginVersion: '11.1.0-70370',
        targets: [
          {
            datasource: {
              type: 'loki',
              uid: alertingHistoryUid,
            },
            editorMode: 'code',
            expr: '{folderUID="' + detectFolderUid + '"} | json | current=`Alerting`',
            queryType: 'range',
            refId: 'A',
          },
        ],
        title: 'Top alerting Grafana Detect rules',
        transformations: [
          {
            id: 'extractFields',
            options: {
              format: 'json',
              jsonPaths: [
                {
                  alias: 'events',
                  path: 'values.A',
                },
                {
                  path: 'ruleTitle',
                },
              ],
              keepTime: false,
              replace: true,
              source: 'Line',
            },
          },
          {
            id: 'groupBy',
            options: {
              fields: {
                events: {
                  aggregations: ['sum'],
                  operation: 'aggregate',
                },
                ruleTitle: {
                  aggregations: [],
                  operation: 'groupby',
                },
              },
            },
          },
          {
            id: 'sortBy',
            options: {
              fields: {},
              sort: [
                {
                  desc: true,
                  field: 'events (sum)',
                },
              ],
            },
          },
          {
            id: 'organize',
            options: {
              excludeByName: {},
              includeByName: {},
              indexByName: {},
              renameByName: {
                'events (sum)': 'Total Events',
                ruleTitle: 'Rule Title',
              },
            },
          },
        ],
        type: 'table',
      },
      {
        datasource: {
          type: 'loki',
          uid: alertingHistoryUid,
        },
        fieldConfig: {
          defaults: {
            color: {
              mode: 'thresholds',
            },
            mappings: [],
            thresholds: {
              mode: 'absolute',
              steps: [
                {
                  color: 'green',
                  value: null,
                },
                {
                  color: 'red',
                  value: 80,
                },
              ],
            },
          },
          overrides: [],
        },
        gridPos: {
          h: 8,
          w: 5,
          x: 19,
          y: 1,
        },
        id: 11,
        options: {
          colorMode: 'value',
          graphMode: 'area',
          justifyMode: 'auto',
          orientation: 'auto',
          reduceOptions: {
            calcs: ['sum'],
            fields: '',
            values: false,
          },
          showPercentChange: false,
          textMode: 'auto',
          wideLayout: true,
        },
        pluginVersion: '11.1.0-70370',
        targets: [
          {
            datasource: {
              type: 'loki',
              uid: alertingHistoryUid,
            },
            editorMode: 'code',
            expr:
              'sum (sum_over_time({folderUID="' +
              detectFolderUid +
              '"} | json | current=`Alerting` | unwrap values_A [1w])) or vector(0)',
            queryType: 'range',
            refId: 'A',
          },
        ],
        title: 'Total Grafana Detect alert events',
        type: 'stat',
      },
      {
        datasource: {
          type: 'loki',
          uid: alertingHistoryUid,
        },
        fieldConfig: {
          defaults: {
            color: {
              mode: 'palette-classic',
            },
            custom: {
              axisBorderShow: false,
              axisCenteredZero: false,
              axisColorMode: 'text',
              axisLabel: '',
              axisPlacement: 'auto',
              barAlignment: 0,
              drawStyle: 'bars',
              fillOpacity: 0,
              gradientMode: 'none',
              hideFrom: {
                legend: false,
                tooltip: false,
                viz: false,
              },
              insertNulls: false,
              lineInterpolation: 'linear',
              lineWidth: 1,
              pointSize: 5,
              scaleDistribution: {
                type: 'linear',
              },
              showPoints: 'auto',
              spanNulls: false,
              stacking: {
                group: 'A',
                mode: 'normal',
              },
              thresholdsStyle: {
                mode: 'off',
              },
            },
            mappings: [],
            thresholds: {
              mode: 'absolute',
              steps: [
                {
                  color: 'green',
                  value: null,
                },
                {
                  color: 'red',
                  value: 80,
                },
              ],
            },
          },
          overrides: [],
        },
        gridPos: {
          h: 8,
          w: 24,
          x: 0,
          y: 9,
        },
        id: 8,
        options: {
          legend: {
            calcs: [],
            displayMode: 'list',
            placement: 'bottom',
            showLegend: true,
          },
          tooltip: {
            mode: 'single',
            sort: 'none',
          },
        },
        pluginVersion: '11.1.0-69840',
        targets: [
          {
            datasource: {
              type: 'loki',
              uid: alertingHistoryUid,
            },
            editorMode: 'code',
            expr:
              'sum by (ruleTitle) (sum_over_time({folderUID="' +
              detectFolderUid +
              '"} | json | current=`Alerting` | unwrap values_A [1w])) or vector(0)',
            queryType: 'range',
            refId: 'A',
          },
        ],
        title: 'Weekly alert count from Grafana Detect rules',
        type: 'timeseries',
      }
    );
  }
  if (incidentUid) {
    panels.push(
      {
        collapsed: false,
        gridPos: {
          h: 1,
          w: 24,
          x: 0,
          y: 17,
        },
        id: 9,
        panels: [],
        title: 'Grafana Incident Security Summary',
        type: 'row',
      },
      {
        datasource: {
          type: 'grafana-incident-datasource',
          uid: incidentUid,
        },
        description: '',
        fieldConfig: {
          defaults: {
            color: {
              mode: 'palette-classic',
            },
            custom: {
              axisBorderShow: false,
              axisCenteredZero: false,
              axisColorMode: 'text',
              axisLabel: 'Count',
              axisPlacement: 'auto',
              barAlignment: 0,
              drawStyle: 'bars',
              fillOpacity: 0,
              gradientMode: 'none',
              hideFrom: {
                legend: false,
                tooltip: false,
                viz: false,
              },
              insertNulls: false,
              lineInterpolation: 'stepAfter',
              lineWidth: 1,
              pointSize: 5,
              scaleDistribution: {
                type: 'linear',
              },
              showPoints: 'auto',
              spanNulls: false,
              stacking: {
                group: 'A',
                mode: 'normal',
              },
              thresholdsStyle: {
                mode: 'off',
              },
            },
            mappings: [],
            min: 0,
            noValue: '0',
            thresholds: {
              mode: 'absolute',
              steps: [
                {
                  color: 'green',
                  value: null,
                },
              ],
            },
          },
          overrides: [],
        },
        gridPos: {
          h: 8,
          w: 12,
          x: 0,
          y: 18,
        },
        id: 1,
        options: {
          legend: {
            calcs: [],
            displayMode: 'list',
            placement: 'right',
            showLegend: true,
          },
          tooltip: {
            mode: 'single',
            sort: 'none',
          },
        },
        pluginVersion: '11.1.0-69840',
        targets: [
          {
            datasource: {
              type: 'grafana-incident-datasource',
              uid: incidentUid,
            },
            queryString: 'or(label:vulnerability label:security)',
            queryType: 'incidents',
            refId: 'A',
          },
        ],
        title: 'Weekly count of security incidents opened',
        transformations: [
          {
            id: 'convertFieldType',
            options: {
              conversions: [
                {
                  dateFormat: 'YYYY-ww',
                  destinationType: 'string',
                  targetField: 'incident_started',
                },
              ],
              fields: {},
            },
          },
          {
            id: 'groupBy',
            options: {
              fields: {
                id: {
                  aggregations: ['count'],
                  operation: 'aggregate',
                },
                incident_started: {
                  aggregations: [],
                  operation: 'groupby',
                },
                severity: {
                  aggregations: [],
                  operation: 'groupby',
                },
              },
            },
          },
          {
            id: 'convertFieldType',
            options: {
              conversions: [
                {
                  dateFormat: 'YYYY-ww',
                  destinationType: 'time',
                  targetField: 'incident_started',
                },
              ],
              fields: {},
            },
          },
          {
            id: 'organize',
            options: {
              excludeByName: {},
              indexByName: {
                'id (count)': 0,
                incident_started: 2,
                severity: 1,
              },
              renameByName: {
                'id (count)': 'unique',
              },
            },
          },
          {
            id: 'prepareTimeSeries',
            options: {
              format: 'multi',
            },
          },
        ],
        type: 'timeseries',
      },
      {
        datasource: {
          type: 'grafana-incident-datasource',
          uid: incidentUid,
        },
        fieldConfig: {
          defaults: {
            color: {
              mode: 'palette-classic',
            },
            custom: {
              axisBorderShow: false,
              axisCenteredZero: false,
              axisColorMode: 'text',
              axisLabel: '',
              axisPlacement: 'auto',
              barAlignment: 1,
              drawStyle: 'bars',
              fillOpacity: 0,
              gradientMode: 'none',
              hideFrom: {
                legend: false,
                tooltip: false,
                viz: false,
              },
              insertNulls: false,
              lineInterpolation: 'stepAfter',
              lineWidth: 1,
              pointSize: 5,
              scaleDistribution: {
                type: 'linear',
              },
              showPoints: 'auto',
              spanNulls: false,
              stacking: {
                group: 'A',
                mode: 'none',
              },
              thresholdsStyle: {
                mode: 'off',
              },
            },
            mappings: [],
            thresholds: {
              mode: 'absolute',
              steps: [
                {
                  color: 'green',
                  value: null,
                },
                {
                  color: 'red',
                  value: 80,
                },
              ],
            },
          },
          overrides: [],
        },
        gridPos: {
          h: 8,
          w: 12,
          x: 12,
          y: 18,
        },
        id: 6,
        options: {
          legend: {
            calcs: [],
            displayMode: 'list',
            placement: 'bottom',
            showLegend: true,
          },
          tooltip: {
            mode: 'single',
            sort: 'none',
          },
        },
        pluginVersion: '11.1.0-69840',
        targets: [
          {
            datasource: {
              type: 'grafana-incident-datasource',
              uid: incidentUid,
            },
            queryString: 'or(label:vulnerability label:security)',
            queryType: 'incidents',
            refId: 'A',
          },
        ],
        title: 'Weekly mean time to detect security incidents',
        transformations: [
          {
            id: 'filterByValue',
            options: {
              filters: [
                {
                  config: {
                    id: 'isNull',
                    options: {},
                  },
                  fieldName: 'time_to_discovery',
                },
              ],
              match: 'any',
              type: 'exclude',
            },
          },
          {
            id: 'calculateField',
            options: {
              alias: 'Time to discover (days)',
              binary: {
                left: 'time_to_discovery',
                operator: '/',
                reducer: 'sum',
                right: '86400',
              },
              mode: 'binary',
              reduce: {
                include: ['time_to_resolve'],
                reducer: 'sum',
              },
              replaceFields: true,
            },
          },
          {
            id: 'formatTime',
            options: {
              outputFormat: 'ww/YYYY',
              timeField: 'incident_started',
              timezone: 'utc',
              useTimezone: true,
            },
          },
          {
            id: 'groupBy',
            options: {
              fields: {
                'Time to discover (days)': {
                  aggregations: ['mean'],
                  operation: 'aggregate',
                },
                incident_started: {
                  aggregations: [],
                  operation: 'groupby',
                },
              },
            },
          },
          {
            id: 'convertFieldType',
            options: {
              conversions: [
                {
                  dateFormat: 'ww/YYYY',
                  destinationType: 'time',
                  targetField: 'incident_started',
                },
              ],
              fields: {},
            },
          },
          {
            id: 'prepareTimeSeries',
            options: {
              format: 'wide',
            },
          },
        ],
        type: 'timeseries',
      },
      {
        datasource: {
          type: 'grafana-incident-datasource',
          uid: incidentUid,
        },
        description: '',
        fieldConfig: {
          defaults: {
            color: {
              mode: 'thresholds',
            },
            mappings: [],
            min: 0,
            noValue: '0',
            thresholds: {
              mode: 'absolute',
              steps: [
                {
                  color: 'green',
                  value: null,
                },
              ],
            },
            unit: 'percentunit',
          },
          overrides: [],
        },
        gridPos: {
          h: 8,
          w: 12,
          x: 0,
          y: 26,
        },
        id: 5,
        options: {
          minVizHeight: 75,
          minVizWidth: 75,
          orientation: 'auto',
          reduceOptions: {
            calcs: [],
            fields: '',
            values: true,
          },
          showThresholdLabels: false,
          showThresholdMarkers: true,
          sizing: 'auto',
        },
        pluginVersion: '11.1.0-70370',
        targets: [
          {
            datasource: {
              type: 'grafana-incident-datasource',
              uid: incidentUid,
            },
            queryString: 'or(label:vulnerability label:security)',
            queryType: 'incidents',
            refId: 'A',
          },
        ],
        title: 'Percentage resolved security incidents by severity',
        transformations: [
          {
            id: 'groupBy',
            options: {
              fields: {
                id: {
                  aggregations: ['distinctCount'],
                  operation: 'aggregate',
                },
                incident_started: {
                  aggregations: [],
                },
                severity: {
                  aggregations: ['count'],
                  operation: 'groupby',
                },
                status: {
                  aggregations: ['count'],
                  operation: 'groupby',
                },
              },
            },
          },
          {
            id: 'groupingToMatrix',
            options: {
              columnField: 'status',
              emptyValue: 'null',
              rowField: 'severity',
              valueField: 'id (distinctCount)',
            },
          },
          {
            id: 'calculateField',
            options: {
              alias: 'total',
              binary: {
                left: 'active',
                operator: '+',
                reducer: 'sum',
                right: 'resolved',
              },
              mode: 'binary',
              reduce: {
                include: [],
                reducer: 'sum',
              },
              replaceFields: false,
            },
          },
          {
            id: 'calculateField',
            options: {
              binary: {
                left: 'resolved',
                operator: '/',
                reducer: 'sum',
                right: 'total',
              },
              mode: 'binary',
              reduce: {
                reducer: 'sum',
              },
              replaceFields: false,
            },
          },
          {
            id: 'filterFieldsByName',
            options: {
              include: {
                names: ['resolved / total', 'severity\\status'],
              },
            },
          },
        ],
        type: 'gauge',
      },
      {
        datasource: {
          type: 'grafana-incident-datasource',
          uid: incidentUid,
        },
        fieldConfig: {
          defaults: {
            color: {
              mode: 'palette-classic',
            },
            custom: {
              axisBorderShow: false,
              axisCenteredZero: false,
              axisColorMode: 'text',
              axisLabel: '',
              axisPlacement: 'auto',
              barAlignment: 1,
              drawStyle: 'bars',
              fillOpacity: 0,
              gradientMode: 'none',
              hideFrom: {
                legend: false,
                tooltip: false,
                viz: false,
              },
              insertNulls: false,
              lineInterpolation: 'linear',
              lineWidth: 1,
              pointSize: 5,
              scaleDistribution: {
                type: 'linear',
              },
              showPoints: 'auto',
              spanNulls: false,
              stacking: {
                group: 'A',
                mode: 'none',
              },
              thresholdsStyle: {
                mode: 'off',
              },
            },
            mappings: [],
            thresholds: {
              mode: 'absolute',
              steps: [
                {
                  color: 'green',
                  value: null,
                },
                {
                  color: 'red',
                  value: 80,
                },
              ],
            },
          },
          overrides: [],
        },
        gridPos: {
          h: 8,
          w: 12,
          x: 12,
          y: 26,
        },
        id: 3,
        options: {
          legend: {
            calcs: [],
            displayMode: 'list',
            placement: 'bottom',
            showLegend: true,
          },
          tooltip: {
            mode: 'single',
            sort: 'none',
          },
        },
        pluginVersion: '11.1.0-69840',
        targets: [
          {
            datasource: {
              type: 'grafana-incident-datasource',
              uid: incidentUid,
            },
            queryString: 'or(label:vulnerability label:security)',
            queryType: 'incidents',
            refId: 'A',
          },
        ],
        title: 'Weekly mean time to resolve security incidents',
        transformations: [
          {
            id: 'filterByValue',
            options: {
              filters: [
                {
                  config: {
                    id: 'isNull',
                    options: {},
                  },
                  fieldName: 'time_to_resolve',
                },
                {
                  config: {
                    id: 'equal',
                    options: {
                      value: 0,
                    },
                  },
                  fieldName: 'time_to_resolve',
                },
              ],
              match: 'any',
              type: 'exclude',
            },
          },
          {
            id: 'calculateField',
            options: {
              alias: 'Time to resolve (days)',
              binary: {
                left: 'time_to_resolve',
                operator: '/',
                reducer: 'sum',
                right: '86400',
              },
              mode: 'binary',
              reduce: {
                include: ['time_to_resolve'],
                reducer: 'sum',
              },
              replaceFields: true,
            },
          },
          {
            id: 'formatTime',
            options: {
              outputFormat: 'ww/YYYY',
              timeField: 'incident_started',
              timezone: 'utc',
              useTimezone: true,
            },
          },
          {
            id: 'groupBy',
            options: {
              fields: {
                'Time to resolve (days)': {
                  aggregations: ['mean'],
                  operation: 'aggregate',
                },
                incident_started: {
                  aggregations: [],
                  operation: 'groupby',
                },
              },
            },
          },
          {
            id: 'convertFieldType',
            options: {
              conversions: [
                {
                  dateFormat: 'ww/YYYY',
                  destinationType: 'time',
                  targetField: 'incident_started',
                },
              ],
              fields: {},
            },
          },
          {
            id: 'prepareTimeSeries',
            options: {
              format: 'wide',
            },
          },
        ],
        type: 'timeseries',
      },
      {
        datasource: {
          type: 'grafana-incident-datasource',
          uid: incidentUid,
        },
        description: '',
        fieldConfig: {
          defaults: {
            color: {
              mode: 'thresholds',
            },
            mappings: [],
            min: 0,
            noValue: '0',
            thresholds: {
              mode: 'absolute',
              steps: [
                {
                  color: 'green',
                  value: null,
                },
              ],
            },
            unit: 'none',
          },
          overrides: [],
        },
        gridPos: {
          h: 8,
          w: 12,
          x: 0,
          y: 34,
        },
        id: 4,
        options: {
          displayMode: 'gradient',
          maxVizHeight: 300,
          minVizHeight: 75,
          minVizWidth: 75,
          namePlacement: 'auto',
          orientation: 'auto',
          reduceOptions: {
            calcs: [],
            fields: '',
            values: true,
          },
          showUnfilled: true,
          sizing: 'auto',
          valueMode: 'color',
        },
        pluginVersion: '11.1.0-70370',
        targets: [
          {
            datasource: {
              type: 'grafana-incident-datasource',
              uid: incidentUid,
            },
            queryString: 'or(label:vulnerability label:security)',
            queryType: 'incidents',
            refId: 'A',
          },
        ],
        title: 'Count of security incidents by severity',
        transformations: [
          {
            id: 'groupBy',
            options: {
              fields: {
                id: {
                  aggregations: ['distinctCount'],
                  operation: 'aggregate',
                },
                incident_started: {
                  aggregations: [],
                },
                severity: {
                  aggregations: ['count'],
                  operation: 'groupby',
                },
                status: {
                  aggregations: ['count'],
                  operation: 'groupby',
                },
              },
            },
          },
          {
            id: 'groupingToMatrix',
            options: {
              columnField: 'status',
              emptyValue: 'null',
              rowField: 'severity',
              valueField: 'id (distinctCount)',
            },
          },
          {
            id: 'calculateField',
            options: {
              alias: 'total',
              binary: {
                left: 'active',
                operator: '+',
                reducer: 'sum',
                right: 'resolved',
              },
              mode: 'binary',
              reduce: {
                include: [],
                reducer: 'sum',
              },
              replaceFields: false,
            },
          },
          {
            id: 'filterFieldsByName',
            options: {
              byVariable: false,
              include: {
                names: ['severity\\status', 'total'],
              },
            },
          },
        ],
        type: 'bargauge',
      },
      {
        datasource: {
          type: 'grafana-incident-datasource',
          uid: incidentUid,
        },
        description: '',
        fieldConfig: {
          defaults: {
            color: {
              mode: 'thresholds',
            },
            custom: {
              align: 'auto',
              cellOptions: {
                type: 'auto',
              },
              inspect: false,
            },
            mappings: [],
            thresholds: {
              mode: 'absolute',
              steps: [
                {
                  color: 'green',
                  value: null,
                },
                {
                  color: 'red',
                  value: 80,
                },
              ],
            },
          },
          overrides: [
            {
              matcher: {
                id: 'byName',
                options: 'Status',
              },
              properties: [
                {
                  id: 'custom.width',
                  value: 101,
                },
              ],
            },
            {
              matcher: {
                id: 'byName',
                options: 'ID',
              },
              properties: [
                {
                  id: 'custom.width',
                  value: 83,
                },
              ],
            },
            {
              matcher: {
                id: 'byName',
                options: 'Severity',
              },
              properties: [
                {
                  id: 'custom.width',
                  value: 89,
                },
              ],
            },
            {
              matcher: {
                id: 'byName',
                options: 'Name',
              },
              properties: [
                {
                  id: 'custom.width',
                  value: 768,
                },
              ],
            },
            {
              matcher: {
                id: 'byName',
                options: 'TTD',
              },
              properties: [
                {
                  id: 'custom.width',
                  value: 69,
                },
              ],
            },
            {
              matcher: {
                id: 'byName',
                options: 'TTR',
              },
              properties: [
                {
                  id: 'custom.width',
                  value: 85,
                },
              ],
            },
            {
              matcher: {
                id: 'byName',
                options: 'incident_started',
              },
              properties: [
                {
                  id: 'custom.width',
                  value: 183,
                },
              ],
            },
            {
              matcher: {
                id: 'byName',
                options: 'created',
              },
              properties: [
                {
                  id: 'custom.width',
                  value: 173,
                },
              ],
            },
            {
              matcher: {
                id: 'byName',
                options: 'incident_ended',
              },
              properties: [
                {
                  id: 'custom.width',
                  value: 174,
                },
              ],
            },
            {
              matcher: {
                id: 'byName',
                options: 'closed',
              },
              properties: [
                {
                  id: 'custom.width',
                  value: 168,
                },
              ],
            },
            {
              matcher: {
                id: 'byName',
                options: 'Closed',
              },
              properties: [
                {
                  id: 'custom.width',
                  value: 160,
                },
              ],
            },
          ],
        },
        gridPos: {
          h: 13,
          w: 24,
          x: 0,
          y: 42,
        },
        id: 7,
        options: {
          cellHeight: 'sm',
          footer: {
            countRows: false,
            fields: '',
            reducer: ['sum'],
            show: false,
          },
          showHeader: true,
          sortBy: [],
        },
        pluginVersion: '11.1.0-70370',
        targets: [
          {
            datasource: {
              type: 'grafana-incident-datasource',
              uid: incidentUid,
            },
            queryString: 'and(or(label:security label:vulnerability) isdrill:false)',
            queryType: 'incidents',
            refId: 'A',
          },
        ],
        title: 'Security and Vulnerability Incidents',
        transformations: [
          {
            id: 'calculateField',
            options: {
              alias: 'TTD',
              binary: {
                left: 'time_to_discovery',
                operator: '/',
                right: '86400',
              },
              mode: 'binary',
              reduce: {
                reducer: 'sum',
              },
            },
          },
          {
            id: 'calculateField',
            options: {
              alias: 'TTR',
              binary: {
                left: 'time_to_resolve',
                operator: '/',
                right: '86400',
              },
              mode: 'binary',
              reduce: {
                reducer: 'sum',
              },
            },
          },
          {
            id: 'organize',
            options: {
              excludeByName: {
                commanders: true,
                investigators: true,
                is_drill: true,
                labelCounts: true,
                labels: true,
                observers: true,
                summary: true,
                task_completed: true,
                task_total: true,
                time_to_discovery: true,
                time_to_resolve: true,
              },
              includeByName: {},
              indexByName: {
                TTD: 8,
                TTR: 9,
                closed: 7,
                commanders: 14,
                created: 5,
                id: 0,
                incident_ended: 6,
                incident_started: 4,
                investigators: 15,
                is_drill: 19,
                labelCounts: 18,
                labels: 17,
                observers: 16,
                severity: 3,
                status: 2,
                summary: 20,
                task_completed: 13,
                task_total: 12,
                time_to_discovery: 10,
                time_to_resolve: 11,
                title: 1,
              },
              renameByName: {
                closed: 'Closed',
                created: 'Detected',
                id: 'ID',
                incident_ended: 'Resolved',
                incident_started: 'Started',
                severity: 'Severity',
                status: 'Status',
                summary: '',
                time_to_discovery: 'TTD',
                time_to_resolve: 'TTR',
                title: 'Name',
              },
            },
          },
        ],
        type: 'table',
      }
    );
  }
  return panels;
};
