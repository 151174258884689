import { RuntimeDataSource } from '@grafana/scenes';
import { getTemplateSrv, TemplateSrv } from '@grafana/runtime';
import { DataQueryRequest, DataQueryResponse } from '@grafana/data';
import { Observable } from 'rxjs';
import { SigmaRuleQuery } from './index';
import { backendResponseToDataFrame, fetchRemoteData, fetchResponseToBackendResponse } from './helper';

export class SigmaRuleDataSource extends RuntimeDataSource {
  constructor(
    public pluginId: string,
    public uid: string,
    public readonly templateSrv: TemplateSrv = getTemplateSrv()
  ) {
    super(pluginId, uid);
    this.templateSrv = templateSrv;
  }

  query(request: DataQueryRequest<SigmaRuleQuery>): Promise<DataQueryResponse> | Observable<DataQueryResponse> {
    return fetchRemoteData(request).pipe(fetchResponseToBackendResponse, backendResponseToDataFrame);
  }

  testDatasource() {
    return Promise.resolve({ status: 'success', message: 'OK' });
  }
}
