import { GitHubContext } from 'contexts/github';
import { useContext, useEffect, useRef, useState } from 'react';
import { getBackendSrv } from '@grafana/runtime';
import { PLUGIN_ID } from 'shared/constants';
import { RuleContent } from 'shared/requests/conversions';
import { SigmaRuleModel } from 'api/detect-service';

export const useFetchFileContents = (
  defaultBranch: string,
  defaultContent: RuleContent,
  filepath: string,
  sourceId = 'PLUGIN_GITHUB_REPOSITORY'
) => {
  const githubConfig = useContext(GitHubContext);

  const [currentRef, setCurrentRef] = useState(defaultBranch);
  const [id, setId] = useState(defaultContent.ruleId);
  const [content, setContent] = useState(defaultContent.content);
  const [loading, setLoading] = useState(false);
  const stateChanged = useRef(false);

  useEffect(() => {
    if (!stateChanged.current || defaultContent.origin !== 'github') {
      return;
    }
    setLoading(true);
    getBackendSrv()
      .get<SigmaRuleModel>(
        `/api/plugins/${PLUGIN_ID}/resources/v1/sources.GetContent?source_id=${sourceId}&ref=${currentRef}&file=${filepath}`
      )
      .then((d) => {
        setId(d.id);
        setContent(d.content);
      })
      .finally(() => setLoading(false));
  }, [filepath, sourceId, currentRef, githubConfig, defaultContent.origin]);

  const updateRef = (ref: string) => {
    stateChanged.current = true;
    setLoading(true);
    setCurrentRef(ref);
  };

  return [content, loading, id, setContent, updateRef, setId] as const;
};
