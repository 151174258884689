import { config, getBackendSrv } from '@grafana/runtime';
import { useEffect, useState } from 'react';
import { lastValueFrom } from 'rxjs';
import { AlertGroup, AlertGroupResponse } from 'shared/alertingTypes';

export function useGrafanaAlertGroup(namespace: string) {
  const [groupnames, setGroupnames] = useState<Array<AlertGroup>>([]);

  useEffect(() => {
    if (!namespace) {
      setGroupnames([]);
      return;
    }
    lastValueFrom(
      getBackendSrv().fetch<AlertGroupResponse>({
        method: 'GET',
        url: `/api/ruler/grafana/api/v1/rules/${namespace}`,
        params: { accesscontrol: config.rbacEnabled },
        showErrorAlert: false,
      })
    )
      .then((response) => {
        setGroupnames(response.data[namespace]);
      })
      .catch((err) => {
        console.error(err);
        setGroupnames([]);
      });
  }, [namespace]);

  return [groupnames];
}
