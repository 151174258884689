import {
  EmbeddedScene,
  SceneAppPage,
  SceneControlsSpacer,
  SceneGridItem,
  SceneGridLayout,
  SceneTimePicker,
  SceneVariableSet,
  VariableValueSelectors,
} from '@grafana/scenes';
import { PLUGIN_ROOT } from '../shared/constants';
import { DetectStateObject } from './stateObject';
import { PipelineMenu } from '../components/scenes/pipelineMenu';

const useScene = (): EmbeddedScene => {
  const pipelineObject = new PipelineMenu({
    loading: true,
  });

  const stateObject = new DetectStateObject({
    body: new SceneGridLayout({
      children: [
        new SceneGridItem({
          width: 4,
          height: 24,
          x: 0,
          y: 0,
          body: pipelineObject,
        }),
      ],
    }),
  });

  return new EmbeddedScene({
    controls: [
      new VariableValueSelectors({
        $variables: new SceneVariableSet({
          variables: [],
        }),
      }),
      new SceneControlsSpacer(),
      new SceneTimePicker({}),
    ],
    body: stateObject,
  });
};

export const getSigmaPipelineBrowserScene = () => {
  return new SceneAppPage({
    title: 'Sigma Pipelines',
    url: `${PLUGIN_ROOT}/scenes/sigma/pipelines`,
    getScene: useScene,
  });
};
