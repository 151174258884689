import { GrafanaTheme2 } from '@grafana/data';
import tinycolor2 from 'tinycolor2';
import { css } from '@emotion/css';
import { LabelValue } from '../../types';
import { getTagColorsFromName, Stack, useTheme2 } from '@grafana/ui';
import React from 'react';

const getStyles = (theme: GrafanaTheme2, color?: string) => {
  const backgroundColor = color ?? theme.colors.primary.main;

  const borderColor = theme.isDark
    ? tinycolor2(backgroundColor).lighten(5).toString()
    : tinycolor2(backgroundColor).darken(5).toString();

  const valueBackgroundColor = theme.colors.secondary.main;

  const fontColor = tinycolor2.mostReadable(backgroundColor, ['#000', '#fff']).toString();
  const valueFontColor = theme.isLight ? '#000' : '#fff';

  const padding = theme.spacing(0.5);

  return {
    wrapper: css`
      color: ${fontColor};
      font-size: ${theme.typography.bodySmall.fontSize};

      border-radius: ${theme.shape.radius.pill};
      margin: ${theme.spacing(0.25)};
    `,
    label: css`
      display: flex;
      align-items: center;
      color: inherit;

      padding: ${padding};
      background: ${backgroundColor};

      border: solid 1px ${borderColor};
      border-top-left-radius: ${theme.shape.radius.pill};
      border-bottom-left-radius: ${theme.shape.radius.pill};
    `,
    value: css`
      color: inherit;
      padding: ${padding};
      color: ${valueFontColor};
      background: ${valueBackgroundColor};

      border: solid 1px ${borderColor};
      border-left: none;
      border-top-right-radius: ${theme.shape.radius.pill};
      border-bottom-right-radius: ${theme.shape.radius.pill};
    `,
  };
};

export const Label = (props: LabelValue) => {
  const { color } = getTagColorsFromName('label' + props.label.toLowerCase());
  const theme = useTheme2();
  const styles = getStyles(theme, color);

  return (
    <div className={styles.wrapper}>
      <Stack direction="row" gap={0} alignItems="stretch">
        <div className={styles.label}>{props.label}</div>
        <div className={styles.value}>{props.value}</div>
      </Stack>
    </div>
  );
};
