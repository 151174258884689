import { CompletionItem } from './auto';

export const getRequiredPipelines = (): Array<CompletionItem> => {
  return [nameSuggestion, prioritySuggestion, transformationSuggestion, ...priorityEnumerationSuggestion()];
};

const nameSuggestion: CompletionItem = {
  insertText: 'name: ',
  kind: 17,
  label: 'name',
  regex: /name:/gim,
  regexLine: {
    notStartWith: /^\s/,
  },
};

const prioritySuggestion: CompletionItem = {
  insertText: 'priority: ',
  kind: 17,
  label: 'priority',
  regex: /priority:/gim,
  regexLine: {
    notStartWith: /^\s/,
  },
};

const priorityEnumerationSuggestion = (): Array<CompletionItem> => {
  const conventions: { [name: string]: number } = {
    sysmon: 10,
    'pre-backend': 20,
    backend: 50,
    format: 60,
  };
  return Object.keys(conventions).map<CompletionItem>((key): CompletionItem => {
    return {
      insertText: `priority: ${conventions[key]}\n`,
      kind: 17,
      label: `priority (${key})`,
      regex: /priority:/gim,
      regexLine: {
        notStartWith: /^\s/,
      },
    };
  });
};

const transformationSuggestion: CompletionItem = {
  insertText: `transformations:\n  `,
  kind: 17,
  label: 'transformations',
  regex: /transformations:/gim,
  regexLine: {
    notStartWith: /^\s/,
  },
};
