import { css } from '@emotion/css';
import { CollapsableSection, Icon } from '@grafana/ui';
import React, { useState } from 'react';

interface InstallCollapsibleSectionProps {
  children: React.ReactNode;
  configured?: boolean;
  label: string;
}

export const InstallCollapsibleSection = (props: InstallCollapsibleSectionProps) => {
  const [closed, setClosed] = useState(props.configured ?? false);

  return (
    <CollapsableSection
      label={
        <span
          className={css`
            display: flex;
            flex-direction: row;
            & > div {
              line-height: 1.4em;
              margin-left: 0.5em;
            }
          `}
        >
          <span>{props.label}</span>
          {props.configured ? (
            <Icon name="check-circle" size="lg" style={{ color: 'green' }} aria-label="Step Complete" />
          ) : null}
        </span>
      }
      isOpen={!closed}
      onToggle={() => {
        setClosed(!closed);
      }}
    >
      {props.children}
    </CollapsableSection>
  );
};
