// In Grafana Scenes we export a function that contains the overall scene
import { SceneApp, SceneAppPage, SceneTimePicker } from '@grafana/scenes';
import { PLUGIN_ROOT } from '../shared/constants';
import { getSigmaRuleBrowserScene } from './sigma.rulebrowser';
import { getSigmaPipelineBrowserScene } from './sigma.pipelinebrowser';
import { getAlertPage } from './alerts';
import { getLogsourceOverviewScene } from './scene-logsource/overview';
import { getLogSourceEditor } from './scene-logsource/editor';

export const getDetectScene = () => {
  return new SceneApp({
    key: 'detect',
    name: 'Grafana Detect',
    pages: [
      new SceneAppPage({
        key: 'rules',
        title: 'Rules',
        titleImg: 'public/plugins/grafana-detect-app/img/Sigma_icon.png',
        subTitle: 'Manage the Sigma rules running on your instance.',
        url: `${PLUGIN_ROOT}/scenes/sigma`,
        tabs: [getSigmaRuleBrowserScene(), getSigmaPipelineBrowserScene(), getAlertPage()],
      }),
      new SceneAppPage({
        key: 'logsource',
        title: 'Log Source Configs',
        titleImg: 'public/plugins/grafana-detect-app/img/Sigma_icon.png',
        subTitle: 'Manage the log sources that are being used to generate Sigma rules.',
        url: `${PLUGIN_ROOT}/scenes/logsource`,
        tabs: [getLogsourceOverviewScene(), getLogSourceEditor()],
      }),
    ],
  });
};
