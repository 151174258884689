import { sceneUtils } from '@grafana/scenes';
import { DataQuery } from '@grafana/schema';
import { RepoListDataSource } from './list';

export { RepoListDataSource };

export interface RepoListQuery extends DataQuery {
  selection: string;
}

try {
  console.log('Registering Runtime DS repo-list');
  sceneUtils.registerRuntimeDataSource({
    dataSource: new RepoListDataSource('repo-list', 'repo-list'),
  });
} catch (e) {
  console.error(e);
}
