import React from 'react';
import {
  SceneComponentProps,
  SceneObjectBase,
  SceneObjectState,
  sceneGraph,
  CustomVariable,
  SceneVariable,
} from '@grafana/scenes';
import { BusEventBase, BusEventWithPayload, textUtil } from '@grafana/data';
import { Button, ButtonVariant } from '@grafana/ui';
import { DetectEvent, DetectEventTypes } from './events';

interface ButtonState extends SceneObjectState {
  eventName: DetectEventTypes;
  text: string;
  variant: ButtonVariant;
  disabled?: boolean;
  tooltip?: string;
  requiredVariables: Array<string>;
}

export class EventButton extends SceneObjectBase<ButtonState> {
  public variableMap: { [name: string]: boolean } = {};

  public constructor(state?: Partial<ButtonState>) {
    super({
      eventName: DetectEventTypes.None,
      text: 'Button',
      variant: 'primary',
      requiredVariables: [],
      ...state,
    });

    this.addActivationHandler(this.subscribeToVariables.bind(this));
  }

  subscribeToVariables() {
    this.state.requiredVariables.forEach((variableName) => {
      const variable = sceneGraph.lookupVariable(variableName, this) as CustomVariable;
      variable?.subscribeToState((state) => {
        this.variableMap[variableName] = !state.value;
        this.checkDisable();
      });
      this.variableMap[variableName] = !variable?.getValue();
    });
    this.checkDisable();
  }

  checkDisable() {
    let disabled = Object.entries(this.variableMap).reduce((acc, [_, value]) => value || acc, false);
    this.setState({ disabled });
  }

  public onClick = () => {
    this.publishEvent(new DetectEvent({ type: this.state.eventName }), true);
  };

  static Component = EventButtonRenderer;
}

function EventButtonRenderer({ model }: SceneComponentProps<EventButton>) {
  const { text, variant, disabled, tooltip } = model.useState();

  return (
    <Button variant={variant} onClick={model.onClick} disabled={disabled} tooltip={tooltip}>
      {text}
    </Button>
  );
}
