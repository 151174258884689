export const PLUGIN_ID = 'grafana-detect-app';
export const PRODUCT_NAME = 'Grafana Detect';
export const PLUGIN_ROOT = `/a/${PLUGIN_ID}`;
export const NullID = '00000000-0000-0000-0000-000000000000';
export const ServiceAccountName = 'grafana-detect-service-account';

// Secure JSON Keys
export const GitHubPersonalAccessToken = 'GH_PERSONAL_ACCESS_TOKEN';
export const AccessPolicyToken = 'ACCESS_POLICY_TOKEN';
export const GrafanaServiceAccountToken = 'GRAFANA_SERVICE_ACCOUNT_TOKEN';

// Create a new one of these with an incremented number each time we need to force a user to
// update their configuration through the install screen
export const Configured0 = 'CONFIGURED_0';
// This represents the change introducing API Keys into the secure JSON data
export const Configured1 = 'CONFIGURED_1';
// Add dashboard UIDs to the JSON data
export const Configured2 = 'CONFIGURED_2';

// Loki datasource query metrics
export const EXECUTION_TIME = 'Summary: exec time';
export const TOTAL_LINES_PROCESSED = 'Summary: total lines processed';
export const TOTAL_BYTES_PROCESSED = 'Summary: total bytes processed';
export const BYTES_PROCESSED_PER_SECOND = 'Summary: bytes processed per second';
export const LINES_PROCESSED_PER_SECOND = 'Summary: lines processed per second';
export const TOTAL_REACHED = 'Ingester: total reached';
export const TOTAL_CHUNKS_MATCHED = 'Ingester: total chunks matched';
export const TOTAL_BATCHES = 'Ingester: total batches';
export const TOTAL_LINES_SENT = 'Ingester: total lines sent';
export const HEAD_CHUNK_BYTES = 'Ingester: head chunk bytes';
export const HEAD_CHUNK_LINES = 'Ingester: head chunk lines';
export const DECOMPRESSED_BYTES = 'Ingester: decompressed bytes';
export const DECOMPRESSED_LINES = 'Ingester: decompressed lines';
export const COMPRESSED_BYTES = 'Ingester: compressed bytes';
export const TOTAL_DUPLICATES = 'Ingester: total duplicates';

export const RED_COLOR_INDEX = 0;
export const LIGHT_BLUE_COLOR_INDEX = 6;
export const GREY_COLOR_INDEX = 9;

export const DEFAULT_RULES_FOLDER_NAME = 'Grafana Detect';

// Default value for the Loki datasource's "Max Lines" setting
export const DEFAULT_MAX_LINES = 100; // Grafana default is 10

// Shared variables for the URL state
export const VAR_CONVERSION_ID = 'var-conversionId';
export const VAR_RULE_PATH = 'var-rulePath';
export const VAR_RULE_REPO = 'var-ruleRepo';

// Generic button in scenes
export const LOCATION_TEMPLATE = PLUGIN_ROOT.concat(
  '/converter?',
  VAR_RULE_PATH,
  '=${selection}&',
  VAR_RULE_REPO,
  '=${repo}'
);
export const CONVERSION_LOCATION_TEMPLATE = PLUGIN_ROOT.concat('/converter?', VAR_CONVERSION_ID, '=${conversionId}');
