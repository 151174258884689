import React, { useMemo } from 'react';
import { Field, getDataFrameRow, PanelProps } from '@grafana/data';
import { DirectoryRow, FileRow, LabelValue, RulePanelOptions } from '../types';
import { ErrorNotifier } from './ErrorNotifier';
import { renderData } from '../utils/renderCheck';
import { FilePanel } from './FilePanel';
import { DirectoryPanel } from './DirectoryPanel';

interface Props extends PanelProps<RulePanelOptions> {}

export const RulePanel: React.FC<Props> = (props: Props) => {
  const { data } = props;

  const renderCheckData = useMemo(() => {
    return renderData(data.series);
  }, [data]);

  if (!renderCheckData.canRender) {
    return <ErrorNotifier errorText={`${renderCheckData.reason!}`} />;
  }

  let row: FileRow = ['', '', 0, 0, ''];
  let directoryRow: DirectoryRow = ['', 0, []];

  if (renderCheckData.renderType === 'file') {
    row = getDataFrameRow(data.series[0], 0) as FileRow;
  } else {
    let tags: Array<LabelValue> = [];
    directoryRow = getDataFrameRow(data.series[0], 0) as DirectoryRow;
    for (let field of data.series[0].fields.filter((f: Field) => f.name === 'length')) {
      tags.push({
        label: field.labels!['source']!,
        value: field.values[0].toString(10),
      });
    }
    directoryRow[2] = tags;
  }

  return renderCheckData.renderType === 'file' ? <FilePanel row={row} /> : <DirectoryPanel row={directoryRow} />;
};
