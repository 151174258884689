import {
  DataFrame,
  dataFrameFromJSON,
  DataQueryRequest,
  DataQueryResponse,
  FieldType,
  getDataFrameRow,
  LoadingState,
} from '@grafana/data';
import { BackendDataSourceResponse, FetchResponse, getBackendSrv, getTemplateSrv } from '@grafana/runtime';
import { PLUGIN_ID } from '../../../shared/constants';
import { SigmaRuleQuery } from './index';
import { map } from 'rxjs/operators';

export const fetchResponseToBackendResponse = map<FetchResponse<BackendDataSourceResponse>, BackendDataSourceResponse>(
  (fetchResponse) => {
    return fetchResponse.data;
  }
);

export const backendResponseToDataFrame = map<BackendDataSourceResponse, DataQueryResponse>((result) => {
  let keys = Object.keys(result.results);

  return {
    data: keys
      .map((key) => ({
        // We know that we only have one frame returned from the backend here,
        // so we can just take the first one in this case.
        ...dataFrameFromJSON(result.results[key]?.frames?.at(0) ?? {}),
        refId: key,
      }))
      .map(mapResponseToDataFrames),
    state: LoadingState.Done,
  };
});

export const mapResponseToDataFrames = (frame: DataFrame): DataFrame => {
  frame.fields = frame.fields.map((field) => {
    if (field.name === 'children' || field.name === 'conversions') {
      field.type = FieldType.frame;
      field.values = field.values
        .map((val) => (val ? dataFrameFromJSON(val) : val))
        .map((val) => (val ? mapResponseToDataFrames(val) : val));
    }
    return field;
  });
  return frame;
};

export const findRootFrame = (frame: DataFrame, selection: string): DataFrame => {
  for (let i = 0; i < frame.length; i++) {
    const row = getDataFrameRow(frame, i);
    if (row[1] === selection) {
      return (
        (row[3] as DataFrame) ??
        ({
          fields: [
            { name: 'name', values: [selection] },
            { name: 'value', values: [selection] },
          ],
          length: 1,
        } as DataFrame)
      );
    }
    if (row[1] && selection.startsWith(row[1] as string)) {
      return findRootFrame(row[3] as DataFrame, selection);
    }
  }
  return frame;
};

export const countChildren = (frame: DataFrame): number => {
  if (!frame) {
    return 1;
  }
  let count = 0;
  for (let i = 0; i < frame.length; i++) {
    let row = getDataFrameRow(frame, i);
    count += countChildren(row[3] as DataFrame);
  }
  return count;
};

export const reduceFrameToStat = (frame: DataFrame, selection: string): number => {
  return countChildren(findRootFrame(frame, selection));
};

export const fetchRemoteData = (request: DataQueryRequest<SigmaRuleQuery>) => {
  return getBackendSrv().fetch<BackendDataSourceResponse>({
    url: `/api/plugins/${PLUGIN_ID}/resources/v1/datasource/sigmarules`,
    method: 'POST',
    data: {
      app: request.app,
      filters: request.filters,
      Queries: request.targets.map((target) => {
        target.JSON = {
          range: request.range,
          repo_id: getTemplateSrv().replace(target.repo_id, request.scopedVars), //eslint-disable-line camelcase
          type: getTemplateSrv().replace(target.type, request.scopedVars),
          prefix: getTemplateSrv().replace(target.selection, request.scopedVars),
          deployment: getTemplateSrv().replace(target.deployment, request.scopedVars),
          deletion_state: getTemplateSrv().replace(target.deletion_state, request.scopedVars), //eslint-disable-line camelcase
          alertingStatus: getTemplateSrv().replace(target.alertingStatus, request.scopedVars) as
            | 'all'
            | 'include'
            | 'exclude',
          rule_state: getTemplateSrv().replace(target.rule_state, request.scopedVars), //eslint-disable-line camelcase
          contains: getTemplateSrv().replace(target.contains, request.scopedVars),
        };
        return target;
      }),
    },
  });
};
