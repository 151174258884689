import React, { ChangeEvent } from 'react';
import {
  CustomVariable,
  SceneComponentProps,
  sceneGraph,
  SceneObjectBase,
  SceneObjectState,
  VariableDependencyConfig,
} from '@grafana/scenes';
import { DetectScenesVariables } from '../../../variables';
import { Field, Input } from '@grafana/ui';

interface NameFieldState extends SceneObjectState {}

interface NameFieldStateInternal extends NameFieldState {
  name: string;
}

export class NameField extends SceneObjectBase<NameFieldStateInternal> {
  protected static Component = NameFieldRenderer;

  _variableDependency = new VariableDependencyConfig(this, {
    variableNames: [DetectScenesVariables.LogSourceName],
    statePaths: ['name'],
  });

  constructor(state: Partial<NameFieldState>) {
    super({ ...state, name: '${' + DetectScenesVariables.LogSourceName + '}' });
  }

  update(value: string) {
    const nameVariable = sceneGraph.lookupVariable(DetectScenesVariables.LogSourceName, this) as CustomVariable;
    nameVariable.changeValueTo(value);
  }
}

function NameFieldRenderer({ model }: SceneComponentProps<NameField>) {
  const { name } = model.useState();
  const nameValue = sceneGraph.interpolate(model, name);

  return (
    <Field label="Name">
      <Input value={nameValue} onChange={(e: ChangeEvent<HTMLInputElement>) => model.update(e.target.value)}></Input>
    </Field>
  );
}
