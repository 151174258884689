import { RuntimeDataSource } from '@grafana/scenes';
import { BackendDataSourceResponse, getBackendSrv, getTemplateSrv, TemplateSrv } from '@grafana/runtime';
import { DataQueryRequest, DataQueryResponse, LoadingState } from '@grafana/data';
import { Observable } from 'rxjs';
import { backendResponseToDataFrame, fetchResponseToBackendResponse } from './helper';
import { SigmaRuleContentQuery } from './index';
import { PLUGIN_ID } from '../../../shared/constants';

export const fetchRemoteContentData = (request: DataQueryRequest<SigmaRuleContentQuery>) => {
  return getBackendSrv().fetch<BackendDataSourceResponse>({
    url: `/api/plugins/${PLUGIN_ID}/resources/v1/datasource/sigmarule-content`,
    method: 'POST',
    data: {
      app: request.app,
      filters: request.filters,
      Queries: request.targets,
    },
  });
};

export class SigmaRuleContentDataSource extends RuntimeDataSource {
  constructor(
    public pluginId: string,
    public uid: string,
    public readonly templateSrv: TemplateSrv = getTemplateSrv()
  ) {
    super(pluginId, uid);
    this.templateSrv = templateSrv;
  }

  stripExtraData(url: string): string {
    // Return all the text before the # token, this prevents it being sent back up to the server. We know that this will
    // be sent to the application to distinguish between custom and main rules.
    return url.split('#')[0];
  }

  query(request: DataQueryRequest<SigmaRuleContentQuery>): Observable<DataQueryResponse> {
    request.targets = request.targets.map((target) => {
      target.JSON = {
        filename: this.stripExtraData(getTemplateSrv().replace(target.file, request.scopedVars)),
        repoId: getTemplateSrv().replace(target.repoId, request.scopedVars),
        type: getTemplateSrv().replace(target.type, request.scopedVars),
        isDirectory: false,
      };
      return target;
    });

    if (request.targets.length !== 1) {
      return new Observable((subscriber) => {
        subscriber.next({
          data: [
            {
              fields: [],
              length: 0,
              refId: 'rule-content',
            },
          ],
          state: LoadingState.Done,
        });
      });
    }

    if (request.targets[0].JSON.repoId === '') {
      request.targets[0].JSON.isDirectory = true;
    }

    return fetchRemoteContentData(request).pipe(fetchResponseToBackendResponse, backendResponseToDataFrame);
  }

  testDatasource() {
    return Promise.resolve({ status: 'success', message: 'OK' });
  }
}
