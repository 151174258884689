// tslint:disable
/**
 * Detect service API.
 * The Detect service exposes an HTTP API to do actions within the plugin like saving pipelines, conversions of rules, and manage external rule sources.
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: security-operations@grafana.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Observable } from 'rxjs';
import type { AjaxResponse } from 'rxjs/ajax';
import { BaseAPI, throwIfNullOrUndefined, encodeURI } from '../runtime';
import type { OperationOpts, HttpHeaders, HttpQuery } from '../runtime';
import type {
  CreateGithubRepoRequestModel,
  GithubRepoCreateModel,
  GithubRepoReadModel,
  GithubRepoUpdateModel,
  ListConversion400ResponseModel,
  ListGithubRepo200ResponseModel,
  ListGithubRepoRules200ResponseModel,
  UpdateGithubRepoRequestModel,
} from '../models';

export interface CreateGithubRepoRequest {
  createGithubRepoRequestModel: CreateGithubRepoRequestModel;
}

export interface DeleteGithubRepoRequest {
  id: string;
}

export interface ListGithubRepoRequest {
  page?: number;
  itemsPerPage?: number;
  startTime?: string;
  endTime?: string;
}

export interface ListGithubRepoRulesRequest {
  id: string;
  page?: number;
  itemsPerPage?: number;
  startTime?: string;
  endTime?: string;
}

export interface ReadGithubRepoRequest {
  id: string;
}

export interface UpdateGithubRepoRequest {
  id: string;
  updateGithubRepoRequestModel: UpdateGithubRepoRequestModel;
}

/**
 * no description
 */
export class GithubRepoApi extends BaseAPI {
  /**
   * Creates a new GithubRepo and persists it to storage.
   * Create a new GithubRepo
   */
  createGithubRepo({ createGithubRepoRequestModel }: CreateGithubRepoRequest): Observable<GithubRepoCreateModel>;
  createGithubRepo(
    { createGithubRepoRequestModel }: CreateGithubRepoRequest,
    opts?: OperationOpts
  ): Observable<AjaxResponse<GithubRepoCreateModel>>;
  createGithubRepo(
    { createGithubRepoRequestModel }: CreateGithubRepoRequest,
    opts?: OperationOpts
  ): Observable<GithubRepoCreateModel | AjaxResponse<GithubRepoCreateModel>> {
    throwIfNullOrUndefined(createGithubRepoRequestModel, 'createGithubRepoRequestModel', 'createGithubRepo');

    const headers: HttpHeaders = {
      'Content-Type': 'application/json',
    };

    return this.request<GithubRepoCreateModel>(
      {
        url: '/github-repos',
        method: 'POST',
        headers,
        body: createGithubRepoRequestModel,
      },
      opts?.responseOpts
    );
  }

  /**
   * Deletes the GithubRepo with the requested ID.
   * Deletes a GithubRepo by ID
   */
  deleteGithubRepo({ id }: DeleteGithubRepoRequest): Observable<void>;
  deleteGithubRepo({ id }: DeleteGithubRepoRequest, opts?: OperationOpts): Observable<void | AjaxResponse<void>>;
  deleteGithubRepo({ id }: DeleteGithubRepoRequest, opts?: OperationOpts): Observable<void | AjaxResponse<void>> {
    throwIfNullOrUndefined(id, 'id', 'deleteGithubRepo');

    return this.request<void>(
      {
        url: '/github-repos/{id}'.replace('{id}', encodeURI(id)),
        method: 'DELETE',
      },
      opts?.responseOpts
    );
  }

  /**
   * List GithubRepos.
   * List GithubRepos
   */
  listGithubRepo({
    page,
    itemsPerPage,
    startTime,
    endTime,
  }: ListGithubRepoRequest): Observable<ListGithubRepo200ResponseModel>;
  listGithubRepo(
    { page, itemsPerPage, startTime, endTime }: ListGithubRepoRequest,
    opts?: OperationOpts
  ): Observable<AjaxResponse<ListGithubRepo200ResponseModel>>;
  listGithubRepo(
    { page, itemsPerPage, startTime, endTime }: ListGithubRepoRequest,
    opts?: OperationOpts
  ): Observable<ListGithubRepo200ResponseModel | AjaxResponse<ListGithubRepo200ResponseModel>> {
    const query: HttpQuery = {};

    if (page != null) {
      query['page'] = page;
    }
    if (itemsPerPage != null) {
      query['itemsPerPage'] = itemsPerPage;
    }
    if (startTime != null) {
      query['startTime'] = (startTime as any).toISOString();
    }
    if (endTime != null) {
      query['endTime'] = (endTime as any).toISOString();
    }

    return this.request<ListGithubRepo200ResponseModel>(
      {
        url: '/github-repos',
        method: 'GET',
        query,
      },
      opts?.responseOpts
    );
  }

  /**
   * List attached Rules.
   * List attached Rules
   */
  listGithubRepoRules({
    id,
    page,
    itemsPerPage,
    startTime,
    endTime,
  }: ListGithubRepoRulesRequest): Observable<ListGithubRepoRules200ResponseModel>;
  listGithubRepoRules(
    { id, page, itemsPerPage, startTime, endTime }: ListGithubRepoRulesRequest,
    opts?: OperationOpts
  ): Observable<AjaxResponse<ListGithubRepoRules200ResponseModel>>;
  listGithubRepoRules(
    { id, page, itemsPerPage, startTime, endTime }: ListGithubRepoRulesRequest,
    opts?: OperationOpts
  ): Observable<ListGithubRepoRules200ResponseModel | AjaxResponse<ListGithubRepoRules200ResponseModel>> {
    throwIfNullOrUndefined(id, 'id', 'listGithubRepoRules');

    const query: HttpQuery = {};

    if (page != null) {
      query['page'] = page;
    }
    if (itemsPerPage != null) {
      query['itemsPerPage'] = itemsPerPage;
    }
    if (startTime != null) {
      query['startTime'] = (startTime as any).toISOString();
    }
    if (endTime != null) {
      query['endTime'] = (endTime as any).toISOString();
    }

    return this.request<ListGithubRepoRules200ResponseModel>(
      {
        url: '/github-repos/{id}/rules'.replace('{id}', encodeURI(id)),
        method: 'GET',
        query,
      },
      opts?.responseOpts
    );
  }

  /**
   * Finds the GithubRepo with the requested ID and returns it.
   * Find a GithubRepo by ID
   */
  readGithubRepo({ id }: ReadGithubRepoRequest): Observable<GithubRepoReadModel>;
  readGithubRepo({ id }: ReadGithubRepoRequest, opts?: OperationOpts): Observable<AjaxResponse<GithubRepoReadModel>>;
  readGithubRepo(
    { id }: ReadGithubRepoRequest,
    opts?: OperationOpts
  ): Observable<GithubRepoReadModel | AjaxResponse<GithubRepoReadModel>> {
    throwIfNullOrUndefined(id, 'id', 'readGithubRepo');

    return this.request<GithubRepoReadModel>(
      {
        url: '/github-repos/{id}'.replace('{id}', encodeURI(id)),
        method: 'GET',
      },
      opts?.responseOpts
    );
  }

  /**
   * Updates a GithubRepo and persists changes to storage.
   * Updates a GithubRepo
   */
  updateGithubRepo({ id, updateGithubRepoRequestModel }: UpdateGithubRepoRequest): Observable<GithubRepoUpdateModel>;
  updateGithubRepo(
    { id, updateGithubRepoRequestModel }: UpdateGithubRepoRequest,
    opts?: OperationOpts
  ): Observable<AjaxResponse<GithubRepoUpdateModel>>;
  updateGithubRepo(
    { id, updateGithubRepoRequestModel }: UpdateGithubRepoRequest,
    opts?: OperationOpts
  ): Observable<GithubRepoUpdateModel | AjaxResponse<GithubRepoUpdateModel>> {
    throwIfNullOrUndefined(id, 'id', 'updateGithubRepo');
    throwIfNullOrUndefined(updateGithubRepoRequestModel, 'updateGithubRepoRequestModel', 'updateGithubRepo');

    const headers: HttpHeaders = {
      'Content-Type': 'application/json',
    };

    return this.request<GithubRepoUpdateModel>(
      {
        url: '/github-repos/{id}'.replace('{id}', encodeURI(id)),
        method: 'PATCH',
        headers,
        body: updateGithubRepoRequestModel,
      },
      opts?.responseOpts
    );
  }
}
