import { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

export const useQueryParams = () => {
  const location = useLocation();
  const [search, setSearch] = useState<URLSearchParams>(new URLSearchParams(location.search));
  const history = useHistory();

  useEffect(() => {
    const pq = new URLSearchParams(location.search);
    setSearch(pq);
  }, [location]);

  const doNavigate = useCallback(
    (params: URLSearchParams) => {
      setSearch(params);
      const newLoc = { ...location };
      newLoc.search = params.toString();
      history.push(newLoc);
    },
    [location, history]
  );

  return [search, doNavigate] as const;
};
