import React from 'react';

export interface ErrorNotifierProps {
  errorText: string;
}

export const ErrorNotifier = (props: ErrorNotifierProps) => {
  return (
    <div className="panel-empty">
      <p>{props.errorText}</p>
    </div>
  );
};
