import React from 'react';
import { EnvironmentProvider } from './environment';
import { PipelinesProvider } from './pipelines';
import { ConversionProvider } from './conversion';
import { SigmaRulesProvider } from './sigmarules';

export * from './alerts';
export * from './conversion';
export * from './environment';
export * from './pipelines';

export const StoreProvider = (props: { children: React.ReactNode }) => {
  return (
    <EnvironmentProvider>
      <PipelinesProvider>
        <ConversionProvider>
          <SigmaRulesProvider>{props.children}</SigmaRulesProvider>
        </ConversionProvider>
      </PipelinesProvider>
    </EnvironmentProvider>
  );
};
