import { SelectableValue } from '@grafana/data';
import { Field, MultiSelect } from '@grafana/ui';
import React, { useContext, useEffect, useState } from 'react';
import { ConversionContext } from 'stores/conversion';

interface FieldActivePipelinesProps {
  isOpen?: boolean;
  remotePipelines: Array<SelectableValue<string>>;
  pipelines: Array<SelectableValue<string>>;
  setPipelines: (pipelines: Array<SelectableValue<string>>) => void;
}

interface StatefulFieldActivePipelinesProps {
  isOpen?: boolean;
}

export const FieldActivePipelines = ({
  remotePipelines,
  pipelines,
  setPipelines,
  isOpen,
}: FieldActivePipelinesProps) => {
  return (
    <Field
      label="Active pipeline(s)"
      description="Any additional processing pipelines that should be applied to the rule"
    >
      <MultiSelect
        options={remotePipelines}
        value={pipelines}
        onChange={setPipelines}
        isOpen={isOpen}
        aria-label="pipeline-select"
      />
    </Field>
  );
};

export const StatefulFieldActivePipelines = (props: StatefulFieldActivePipelinesProps) => {
  const [pipelines, setPipelines] = useState<Array<SelectableValue<string>>>([]);
  const [selection, setSelection] = useState<Array<SelectableValue<string>>>([]);

  const {
    data: { pipelines: statePipelines, activePipelines: stateActivePipelines },
    operations: { setActivePipelines },
  } = useContext(ConversionContext);

  useEffect(() => {
    statePipelines.subscribe(setPipelines);
    stateActivePipelines.subscribe(setSelection);
  }, [statePipelines, stateActivePipelines]);

  return (
    <FieldActivePipelines
      {...props}
      remotePipelines={pipelines}
      pipelines={selection}
      setPipelines={setActivePipelines}
    />
  );
};
