import { getBackendSrv } from '@grafana/runtime';
import { useCallback, useEffect, useState } from 'react';
import { ServiceAccountName } from '../shared/constants';

interface Plugin {
  name: string;
  id: string;
}

interface AuthAppSettings {
  jsonData: AuthState;
}

interface AuthState {
  region: string;
  stack_id: string;
}

interface AuthAccessPolicyResponse {
  items: Array<AuthAccessPolicy>;
}

interface AuthAccessPolicy {
  id: string;
  name: string;
  orgId: string;
  realms: Array<{
    type: string;
    identifier: string;
  }>;
}

interface AccessToken {
  token: string;
}

interface ServiceAccount {
  id: number;
  name: string;
  login: string;
  orgId: number;
  role: string;
}

interface ServiceAccountToken {
  id: number;
  name: string;
  key: string;
}

export const useGrafanaState = () => {
  const [hasAuth, setHasAuth] = useState(false);
  const [hasIncident, setHasIncident] = useState(false);
  const [authStack, setAuthStack] = useState<AuthState>({
    region: '',
    stack_id: '', //eslint-disable-line camelcase
  });

  useEffect(() => {
    getBackendSrv()
      .get<Array<Plugin>>('/api/plugins')
      .then((d) => {
        let plugins = d.reduce(
          (p, e) => [e.id === 'grafana-auth-app' || p[0], e.id === 'grafana-incident-app' || p[1]],
          [false, false]
        );
        return Promise.resolve(plugins);
      })
      .then((plugins) => {
        setHasAuth(plugins[0]);
        setHasIncident(plugins[1]);
      });
  }, [setHasAuth, setHasIncident]);

  useEffect(() => {
    if (!hasAuth) {
      return;
    }

    getBackendSrv()
      .get<AuthAppSettings>('/api/plugins/grafana-auth-app/settings')
      .then((d) => {
        setAuthStack((prevState) => ({
          ...prevState,
          ...d.jsonData,
        }));
      });
  }, [hasAuth]);

  const generateRandom = (): string => {
    return Math.random().toString(36).substring(2, 5);
  };

  const generateToken = useCallback(() => {
    if (!hasAuth || authStack.region === '' || authStack.stack_id === '') {
      return Promise.reject('No auth stack');
    }

    const getAccessPolicy = (region: string, stackId: string) => {
      return getBackendSrv().get<AuthAccessPolicyResponse>(
        `/api/plugin-proxy/grafana-auth-app/auth/v1/accesspolicies?region=${region}&name=grafana-detect-${stackId}`
      );
    };

    const createAccessPolicy = (region: string, stackId: string) => {
      return getBackendSrv().post<AuthAccessPolicy>(
        `/api/plugin-proxy/grafana-auth-app/auth/v1/accesspolicies?region=${region}`,
        {
          name: `grafana-detect-${stackId}`,
          scopes: ['alerts:read', 'alerts:write'],
          realms: [
            {
              type: 'stack',
              identifier: stackId,
            },
          ],
        }
      );
    };

    const createAccessToken = (region: string, stackId: string, accessPolicyId: string) => {
      return getBackendSrv().post<AccessToken>(`/api/plugin-proxy/grafana-auth-app/auth/v1/tokens?region=${region}`, {
        name: `grafana-detect-${stackId}-${generateRandom()}`,
        accessPolicyId: accessPolicyId,
        displayName: `grafana-detect-${stackId}-token`,
      });
    };

    return new Promise<AccessToken>((resolve, reject) => {
      getAccessPolicy(authStack.region, authStack.stack_id)
        .then((d) => {
          if (d.items.length === 0) {
            createAccessPolicy(authStack.region, authStack.stack_id)
              .then((d) => {
                createAccessToken(authStack.region, authStack.stack_id, d.id)
                  .then((d) => {
                    resolve(d);
                  })
                  .catch(() => {
                    reject('Cannot create access token API');
                  });
              })
              .catch(() => {
                reject('Cannot create access policy API');
              });
          } else {
            createAccessToken(authStack.region, authStack.stack_id, d.items[0].id)
              .then((d) => {
                resolve(d);
              })
              .catch(() => {
                reject('Cannot create access token API');
              });
          }
        })
        .catch(() => {
          reject('Cannot get access policy from API');
        });
    });
  }, [hasAuth, authStack]);

  /**
   * generateServiceAccountToken generates a service account token for the plugin to use to talk to the Grafana Instance.
   * It will create a service account & token if one does not exist.
   */
  const generateServiceAccountToken = useCallback(() => {
    const findServiceAccount = async (accountName: string) =>
      getBackendSrv().get<{
        totalCount: number;
        serviceAccounts: Array<ServiceAccount>;
      }>('/api/serviceaccounts/search?perpage=1&page=1&query=' + accountName);

    const createServiceAccount = async (accountName: string) =>
      getBackendSrv().post<ServiceAccount>('/api/serviceaccounts', {
        name: accountName,
        role: 'Admin',
        isDisabled: false,
      });

    const createServiceAccountToken = async (accountId: number) =>
      getBackendSrv().post<ServiceAccountToken>(`/api/serviceaccounts/${accountId}/tokens`, {
        name: `grafana-detect-${generateRandom()}`,
      });

    const doToken = async () => {
      const search = await findServiceAccount(ServiceAccountName);
      let serviceAccount: ServiceAccount;
      if (search.totalCount === 0) {
        serviceAccount = await createServiceAccount(ServiceAccountName);
      } else {
        serviceAccount = search.serviceAccounts[0];
      }

      const serviceAccountToken = await createServiceAccountToken(serviceAccount.id);

      return serviceAccountToken.key;
    };

    return doToken();
  }, []);

  return [hasAuth, hasIncident, authStack, generateToken, generateServiceAccountToken] as const;
};
