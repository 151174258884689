// tslint:disable
/**
 * Sigma API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Observable } from 'rxjs';
import type { AjaxResponse } from 'rxjs/ajax';
import { BaseAPI, throwIfNullOrUndefined } from '../runtime';
import type { OperationOpts, HttpHeaders } from '../runtime';
import type {
  RpcStatus,
  V1ConvertRequest,
  V1ConvertResponse,
  V1ListFormatsRequest,
  V1ListFormatsResponse,
  V1ListPipelinesRequest,
  V1ListPipelinesResponse,
  V1ListSchemasResponse,
  V1ListTargetsResponse,
  V1ListValidatorsResponse,
  V1ValidateRuleRequest,
} from '../models';

export interface ConvertRequest {
  body: V1ConvertRequest;
}

export interface ListFormatsRequest {
  body: V1ListFormatsRequest;
}

export interface ListPipelinesRequest {
  body: V1ListPipelinesRequest;
}

export interface ValidateRuleRequest {
  body: V1ValidateRuleRequest;
}

/**
 * no description
 */
export class SigmaServiceApi extends BaseAPI {
  /**
   */
  convert({ body }: ConvertRequest): Observable<V1ConvertResponse>;
  convert({ body }: ConvertRequest, opts?: OperationOpts): Observable<AjaxResponse<V1ConvertResponse>>;
  convert(
    { body }: ConvertRequest,
    opts?: OperationOpts
  ): Observable<V1ConvertResponse | AjaxResponse<V1ConvertResponse>> {
    throwIfNullOrUndefined(body, 'body', 'convert');

    const headers: HttpHeaders = {
      'Content-Type': 'application/json',
    };

    return this.request<V1ConvertResponse>(
      {
        url: '/api/v1/convert',
        method: 'POST',
        headers,
        body: body,
      },
      opts?.responseOpts
    );
  }

  /**
   */
  listFormats({ body }: ListFormatsRequest): Observable<V1ListFormatsResponse>;
  listFormats({ body }: ListFormatsRequest, opts?: OperationOpts): Observable<AjaxResponse<V1ListFormatsResponse>>;
  listFormats(
    { body }: ListFormatsRequest,
    opts?: OperationOpts
  ): Observable<V1ListFormatsResponse | AjaxResponse<V1ListFormatsResponse>> {
    throwIfNullOrUndefined(body, 'body', 'listFormats');

    const headers: HttpHeaders = {
      'Content-Type': 'application/json',
    };

    return this.request<V1ListFormatsResponse>(
      {
        url: '/api/v1/formats',
        method: 'POST',
        headers,
        body: body,
      },
      opts?.responseOpts
    );
  }

  /**
   */
  listPipelines({ body }: ListPipelinesRequest): Observable<V1ListPipelinesResponse>;
  listPipelines(
    { body }: ListPipelinesRequest,
    opts?: OperationOpts
  ): Observable<AjaxResponse<V1ListPipelinesResponse>>;
  listPipelines(
    { body }: ListPipelinesRequest,
    opts?: OperationOpts
  ): Observable<V1ListPipelinesResponse | AjaxResponse<V1ListPipelinesResponse>> {
    throwIfNullOrUndefined(body, 'body', 'listPipelines');

    const headers: HttpHeaders = {
      'Content-Type': 'application/json',
    };

    return this.request<V1ListPipelinesResponse>(
      {
        url: '/api/v1/pipelines',
        method: 'POST',
        headers,
        body: body,
      },
      opts?.responseOpts
    );
  }

  /**
   */
  listSchemas(): Observable<V1ListSchemasResponse>;
  listSchemas(opts?: OperationOpts): Observable<AjaxResponse<V1ListSchemasResponse>>;
  listSchemas(opts?: OperationOpts): Observable<V1ListSchemasResponse | AjaxResponse<V1ListSchemasResponse>> {
    return this.request<V1ListSchemasResponse>(
      {
        url: '/api/v1/schemas',
        method: 'GET',
      },
      opts?.responseOpts
    );
  }

  /**
   */
  listTargets(): Observable<V1ListTargetsResponse>;
  listTargets(opts?: OperationOpts): Observable<AjaxResponse<V1ListTargetsResponse>>;
  listTargets(opts?: OperationOpts): Observable<V1ListTargetsResponse | AjaxResponse<V1ListTargetsResponse>> {
    return this.request<V1ListTargetsResponse>(
      {
        url: '/api/v1/targets',
        method: 'GET',
      },
      opts?.responseOpts
    );
  }

  /**
   */
  listValidators(): Observable<V1ListValidatorsResponse>;
  listValidators(opts?: OperationOpts): Observable<AjaxResponse<V1ListValidatorsResponse>>;
  listValidators(opts?: OperationOpts): Observable<V1ListValidatorsResponse | AjaxResponse<V1ListValidatorsResponse>> {
    return this.request<V1ListValidatorsResponse>(
      {
        url: '/api/v1/validators',
        method: 'GET',
      },
      opts?.responseOpts
    );
  }

  /**
   */
  validateRule({ body }: ValidateRuleRequest): Observable<object>;
  validateRule({ body }: ValidateRuleRequest, opts?: OperationOpts): Observable<AjaxResponse<object>>;
  validateRule({ body }: ValidateRuleRequest, opts?: OperationOpts): Observable<object | AjaxResponse<object>> {
    throwIfNullOrUndefined(body, 'body', 'validateRule');

    const headers: HttpHeaders = {
      'Content-Type': 'application/json',
    };

    return this.request<object>(
      {
        url: '/api/v1/validate-rule',
        method: 'POST',
        headers,
        body: body,
      },
      opts?.responseOpts
    );
  }

  /**
   */
  version(): Observable<object>;
  version(opts?: OperationOpts): Observable<AjaxResponse<object>>;
  version(opts?: OperationOpts): Observable<object | AjaxResponse<object>> {
    return this.request<object>(
      {
        url: '/api/v1',
        method: 'HEAD',
      },
      opts?.responseOpts
    );
  }
}
