import { SelectableValue } from '@grafana/data';
import { ActionMeta, Field, MultiSelect } from '@grafana/ui';
import React, { useContext, useEffect, useState } from 'react';
import { PipelineList } from 'shared/requests/pipelines';
import { PipelineContext } from 'stores';

interface FieldCustomPipelineProps {
  // selectedPipeline is for setting the default selection (primarily for component testing, but could have use elsewhere)
  selectedPipeline?: Array<SelectableValue<string>>;
  onChange?: (pipeline: Array<SelectableValue<string>>, action: ActionMeta) => void;
}

export const FieldCustomPipeline = ({ selectedPipeline, onChange }: FieldCustomPipelineProps) => {
  const {
    data: { list: listData, getMulti },
    operations: { getMulti: doGetMulti, list: doList },
  } = useContext(PipelineContext);

  const [pipelines, setPipelines] = useState<PipelineList>({
    data: [],
    count: 0,
    page: 0,
    pageSize: 0,
    returned: 0,
  });

  const [selected, setSelected] = useState<Array<SelectableValue<string>>>(selectedPipeline ?? []);

  useEffect(() => {
    listData?.subscribe(setPipelines);
    getMulti?.subscribe((e) => {
      setSelected(e.map((e) => ({ label: e.name, value: e.id })));
      onChange?.(
        e.map((e) => ({ label: e.name, value: e.id })),
        { action: 'select-option', option: e }
      );
    });
  }, [listData, getMulti, onChange]);

  useEffect(() => {
    doList(1);
  }, [doList]);

  return (
    <Field label="Custom pipeline" description="Use a custom pipeline">
      <MultiSelect
        options={[...pipelines.data.map((e) => ({ label: e.name, value: e.id }))]}
        value={selected}
        onChange={(e, action) => {
          setSelected(e);
          doGetMulti(e.map((e) => e.value ?? ''));
          onChange?.(e, action);
        }}
      ></MultiSelect>
    </Field>
  );
};
