import { getBackendSrv } from '@grafana/runtime';
import { PLUGIN_ID } from 'shared/constants';
import { useCallback, useContext, useMemo, useState } from 'react';
import { firstValueFrom, lastValueFrom } from 'rxjs';
import {
  CreateRunNowFetchJobRequest,
  CreateRunNowFetchJobRequestModel,
  FetchJobApi,
  GithubRepoApi,
  Configuration as GithubRepoApiCfg,
  GithubRepoReadModel,
} from 'api/detect-service';
import { DetectError, DetectErrorContext } from 'contexts/errors/generic';
import { ForceFetchRepoError, ForceFetchRepoSuccess } from 'contexts/errors/repos';

export const useForceFetchRepo = () => {
  const fetchJobApi = useMemo(
    () =>
      new FetchJobApi(
        new GithubRepoApiCfg({
          basePath: `/api/plugins/${PLUGIN_ID}/resources/service/v1/db`,
        })
      ),
    []
  );

  const { pushError } = useContext(DetectErrorContext);

  const forceFetchRepo = useCallback((repoId: string) => {
    firstValueFrom(fetchJobApi.createRunNowFetchJob({ createRunNowFetchJobRequestModel: { repo_id: repoId } })) //eslint-disable-line camelcase
      .catch((e) => {
        pushError(ForceFetchRepoError(e));
        console.error('Cannot force fetch repository', repoId);
      })
      .then(() => {
        pushError(ForceFetchRepoSuccess());
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [forceFetchRepo] as const;
};
