import { DataSourceApi } from '@grafana/data';
import { getDataSourceSrv } from '@grafana/runtime';
import React, { useEffect, useState } from 'react';

export const LogSourceDataSource = (props: { uid: string } = { uid: 'grafana' }) => {
  const [ds, setDs] = useState<DataSourceApi>();

  useEffect(() => {
    getDataSourceSrv()
      .get(props.uid)
      .then((res) => {
        setDs(res);
      })
      .catch((err) => console.error(err));
  }, [props.uid]);

  if (!ds) {
    return <></>;
  }

  return (
    <>
      <img src={ds.meta.info.logos.small} width="16rem" /> {ds.name}
    </>
  );
};
