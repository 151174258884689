import React, { useContext, useMemo } from 'react';
import { SceneComponentProps, SceneFlexLayout, SceneObject, SceneObjectBase, SceneObjectState } from '@grafana/scenes';
import {
  AlertContext,
  AlertState,
  ConversionContext,
  ConversionContextType,
  EnvironmentContext,
  EnvironmentState,
  PipelineContext,
  PipelinesState,
} from '../stores';

export interface DetectState extends SceneObjectState {
  body: SceneObject;
  alertState?: AlertState;
  conversionState?: ConversionContextType;
  environmentState?: EnvironmentState;
  pipelineState?: PipelinesState;
}

export class DetectStateObject extends SceneObjectBase<DetectState> {
  constructor(state: Partial<DetectState>) {
    super({ body: state.body ?? new SceneFlexLayout({ children: [] }), ...state });
  }

  setAlertState = (alertState: AlertState) => {
    this.setState({ ...this.state, alertState });
  };
  setConversionState = (conversionState: ConversionContextType) => {
    this.setState({ ...this.state, conversionState });
  };
  setEnvironmentState = (environmentState: EnvironmentState) => {
    this.setState({ ...this.state, environmentState });
  };
  setPipelineState = (pipelineState: PipelinesState) => {
    this.setState({ ...this.state, pipelineState });
  };

  get alertState() {
    return this.state.alertState!;
  }

  get conversionState() {
    return this.state.conversionState!;
  }

  get environmentState() {
    return this.state.environmentState!;
  }

  get pipelineState() {
    return this.state.pipelineState!;
  }

  static Component = DetectRenderer;
}

function DetectRenderer({ model }: SceneComponentProps<DetectStateObject>) {
  const { body } = model.useState();
  const alertState = useContext(AlertContext);
  const conversionState = useContext(ConversionContext);
  const environmentState = useContext(EnvironmentContext);
  const pipelineState = useContext(PipelineContext);
  useMemo(() => {
    model.setAlertState(alertState);
    model.setConversionState(conversionState);
    model.setEnvironmentState(environmentState);
    model.setPipelineState(pipelineState);
  }, [model, alertState, conversionState, environmentState, pipelineState]);
  return <body.Component model={body} />;
}
