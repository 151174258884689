import { DetectError, DetectErrorCode } from './generic';

export const CreateAlertSuccess = (id: string): DetectError => {
  const err = new DetectError(DetectErrorCode.GMA_ALERT_CREATE_SUCCESS);
  err.level = 'success';
  err.stack = undefined;
  err.message = id;
  return err;
};

export const CreateAlertFail = (msg: string): DetectError => {
  const err = new DetectError(DetectErrorCode.GMA_ALERT_CREATE_FAILURE);
  err.level = 'error';
  err.message = msg;
  return err;
};

export const CreateDashboardSuccess = (id: string): DetectError => {
  const err = new DetectError(DetectErrorCode.DASHBOARD_CREATE_SUCCESS);
  err.level = 'success';
  err.stack = undefined;
  err.message = id;
  return err;
};

export const CreateDashboardFail = (msg: string): DetectError => {
  const err = new DetectError(DetectErrorCode.DASHBOARD_CREATE_FAILURE);
  err.level = 'error';
  err.message = msg;
  return err;
};
