/**
 * This store will rarely, if ever, have user interaction, it is designed to provide
 * contextual information to the plugin about where it is running. This is in part
 * to determine whether we are running in a cloud environment or on premise. It also
 * provide information about whether or not the service is deployed.
 */
import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { PLUGIN_ID } from 'shared/constants';

import { BehaviorSubject, Observable, switchMap, take } from 'rxjs';
import {
  Configuration,
  CreateSigmaRuleRequestModel,
  SigmaRuleApi,
  SigmaRuleCreateModel,
  SigmaRuleModel,
} from '../api/detect-service';
import { DetectErrorContext } from '../contexts/errors';
import { ServiceCreateSigmaRuleFail, ServiceCreateSigmaRuleSuccess } from '../contexts/errors/service';
import { ajax } from 'rxjs/ajax';
import { DetectServiceConfig } from '../shared/apiConfigs';

const initialCreateModel: Partial<CreateSigmaRuleRequestModel> = {
  filename: '',
  content: '',
};

export const SigmaRulesContext = createContext<SigmaRulesState>({} as SigmaRulesState);

// Export the type of the state, this allows us to know what the state looks like elsewheres
export interface SigmaRulesState {
  loading: boolean;
  remote: Observable<boolean>;
  data: {
    createModel: Observable<CreateSigmaRuleRequestModel>;
    createResponse: Observable<SigmaRuleCreateModel>;
  };
  operations: {
    create(): void;
    // This is needed to pass in from the old methodology
    createWithArgs(filename: string, content: string): void;
    updateCreateAttributes(request: Partial<CreateSigmaRuleRequestModel>): void;
    loadExistingRule(filename: string): void;
  };
}

export const SigmaRulesProvider = (props: { children: React.ReactNode }) => {
  const [loading, setLoading] = useState<boolean>(true);
  const remote = useMemo(() => new BehaviorSubject<boolean>(false), []);
  const createModel = useMemo(
    () => new BehaviorSubject<CreateSigmaRuleRequestModel>(initialCreateModel as CreateSigmaRuleRequestModel),
    []
  );
  // Enable components to listen for responses from the create operation
  const createResponse = useMemo(() => new BehaviorSubject<SigmaRuleCreateModel>({} as SigmaRuleCreateModel), []);

  const { pushError } = useContext(DetectErrorContext);

  const sigmaRulesApi = useMemo(() => new SigmaRuleApi(DetectServiceConfig), []);

  const operationCreate = useCallback(() => {
    createModel
      .pipe(
        take(1),
        switchMap((request) => sigmaRulesApi.createSigmaRule({ createSigmaRuleRequestModel: request }))
      )
      .subscribe({
        next: (val: SigmaRuleCreateModel) => {
          createResponse.next(val);
          createModel.next(initialCreateModel as CreateSigmaRuleRequestModel);
          setLoading(false);
          pushError(ServiceCreateSigmaRuleSuccess());
        },
        error: (err) => {
          setLoading(false);
          pushError(ServiceCreateSigmaRuleFail(err.message));
        },
      });
  }, [createModel, createResponse, sigmaRulesApi, pushError]);

  const operationUpdateCreateAttributesOperation = useCallback(
    (request: Partial<CreateSigmaRuleRequestModel>) => {
      createModel.next({
        ...createModel.getValue(),
        ...request,
      });
    },
    [createModel]
  );

  const operationCreateWithArgs = useCallback(
    (filename: string, content: string) => {
      operationUpdateCreateAttributesOperation({
        filename,
        content,
      });
      operationCreate();
    },
    [operationCreate, operationUpdateCreateAttributesOperation]
  );

  const operationLoadExistingRuleContent = useCallback(
    (filename: string) => {
      createModel.next({
        filename: createModel.getValue().filename,
      } as CreateSigmaRuleRequestModel);

      ajax<SigmaRuleModel>({
        url: `/api/plugins/${PLUGIN_ID}/resources/v1/sources.GetContent?source_id=grafana-detect&file=${filename}`,
        method: 'GET',
        responseType: 'json',
      }).subscribe({
        next: (response) => {
          // if a rule is found we'll load it into the editor here, otherwise we need to set it to an empty string
          operationUpdateCreateAttributesOperation({
            content: response.response.content,
            rule_id: response.response.id, //eslint-disable-line camelcase
          });
        },
        error: () => {
          console.error(`Rule not found at path: ${filename}`);
          operationUpdateCreateAttributesOperation({
            content: '',
          });
        },
      });
    },
    [createModel, operationUpdateCreateAttributesOperation]
  );

  useEffect(() => {
    setLoading(false);
  }, [createModel]);

  return (
    <SigmaRulesContext.Provider
      value={{
        loading,
        remote,
        data: {
          createModel,
          createResponse,
        },
        operations: {
          create: operationCreate,
          createWithArgs: operationCreateWithArgs,
          updateCreateAttributes: operationUpdateCreateAttributesOperation,
          loadExistingRule: operationLoadExistingRuleContent,
        },
      }}
    >
      {props.children}
    </SigmaRulesContext.Provider>
  );
};
