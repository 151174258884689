import React, { createContext, useState } from 'react';
import { PRODUCT_NAME } from 'shared/constants';

export enum DetectErrorCode {
  GENERIC_SIGMA_API_ERROR = 2000,
  SIGMA_INVALID_YAML = 2001,
  LOKI_ALERT_RULES_NOT_SUPPORTED_FOR_DATASOURCE = 3000,
  LOKI_ALERT_RULES_NOT_ENABLED_FOR_DATASOURCE = 3001,
  LOKI_CREATE_ERROR = 3002,
  LOKI_CREATE_SUCCESS = 3003,
  LOKI_NO_RULES_TO_CONVERT = 3004,
  SERVICE_CREATE_SIGMA_RULE_ERROR = 4000,
  SERVICE_CREATE_SIGMA_RULE_SUCCESS = 4001,
  DATA_SOURCE_QUERY_ERROR = 5000,
  GMA_ALERT_CREATE_SUCCESS = 6000,
  GMA_ALERT_CREATE_FAILURE = 6001,
  DASHBOARD_CREATE_SUCCESS = 6002,
  DASHBOARD_CREATE_FAILURE = 6003,
  GRAFANA_FOLDER_UID_UNDEF = 6004,
  CUSTOM_REPO_MISSING_FIELDS = 7000,
  CUSTOM_REPO_FETCH_ERROR = 7001,
  CUSTOM_REPO_SAVED = 7002,
  CUSTOM_REPO_DELETED = 7003,
  CUSTOM_REPO_ERROR_SAVE = 7004,
  CUSTOM_REPO_ERROR_DELETE = 7005,
  MAIN_REPO_FETCH_ERROR = 7006,
  FORCE_FETCH_REPO_ERROR = 7007,
  FORCE_FETCH_REPO_SUCCESS = 7008,
  UNKNOWN_ERROR = 9999,
}

export class DetectError extends Error {
  code: DetectErrorCode = DetectErrorCode.UNKNOWN_ERROR;
  level: 'error' | 'warning' | 'info' | 'success' = 'error';
  message = '';

  constructor(code: DetectErrorCode) {
    super();
    this.code = code;
  }

  get title(): string {
    switch (this.code) {
      case DetectErrorCode.GENERIC_SIGMA_API_ERROR:
        return 'Sigma REST API Error';
      case DetectErrorCode.SIGMA_INVALID_YAML:
        return 'Invalid Sigma Pipeline';
      case DetectErrorCode.LOKI_ALERT_RULES_NOT_ENABLED_FOR_DATASOURCE:
      case DetectErrorCode.LOKI_ALERT_RULES_NOT_SUPPORTED_FOR_DATASOURCE:
      case DetectErrorCode.LOKI_CREATE_ERROR:
        return 'Alert Ruler Error';
      case DetectErrorCode.LOKI_CREATE_SUCCESS:
        return 'Alert Rule Created';
      case DetectErrorCode.LOKI_NO_RULES_TO_CONVERT:
        return 'No Rules';
      case DetectErrorCode.SERVICE_CREATE_SIGMA_RULE_ERROR:
        return 'Save Sigma Rule Failed';
      case DetectErrorCode.SERVICE_CREATE_SIGMA_RULE_SUCCESS:
        return 'Save Sigma Rule Success';
      case DetectErrorCode.DATA_SOURCE_QUERY_ERROR:
        return 'Data Source Query Failed';
      case DetectErrorCode.GMA_ALERT_CREATE_SUCCESS:
        return 'Alert Create Success';
      case DetectErrorCode.GMA_ALERT_CREATE_FAILURE:
        return 'Alert Create Failed';
      case DetectErrorCode.DASHBOARD_CREATE_SUCCESS:
        return 'Dashboard Create Success';
      case DetectErrorCode.DASHBOARD_CREATE_FAILURE:
        return 'Dashboard Create Failed';
      case DetectErrorCode.GRAFANA_FOLDER_UID_UNDEF:
        return 'Folder Not Configured';
      case DetectErrorCode.CUSTOM_REPO_MISSING_FIELDS:
        return 'Custom repository missing fields';
      case DetectErrorCode.CUSTOM_REPO_FETCH_ERROR:
        return 'Custom repository fetch error';
      case DetectErrorCode.CUSTOM_REPO_SAVED:
        return 'Custom repository saved';
      case DetectErrorCode.CUSTOM_REPO_DELETED:
        return 'Custom repository deleted';
      case DetectErrorCode.CUSTOM_REPO_ERROR_SAVE:
        return 'Custom repository save error';
      case DetectErrorCode.CUSTOM_REPO_ERROR_DELETE:
        return 'Custom repository delete error';
      case DetectErrorCode.MAIN_REPO_FETCH_ERROR:
        return 'Main repository fetch error';
      case DetectErrorCode.FORCE_FETCH_REPO_ERROR:
        return 'Force fetch repository error';
      case DetectErrorCode.FORCE_FETCH_REPO_SUCCESS:
        return 'Force fetch repository success';
      default:
        return 'Unknown Error Occurred';
    }
  }

  get body(): string {
    switch (this.code) {
      case DetectErrorCode.GENERIC_SIGMA_API_ERROR:
        return this.message;
      case DetectErrorCode.SIGMA_INVALID_YAML:
        return 'The YAML provided is not valid for Sigma Pipelines';
      case DetectErrorCode.LOKI_ALERT_RULES_NOT_ENABLED_FOR_DATASOURCE:
        return `Alerts not enabled for selected datasource`;
      case DetectErrorCode.LOKI_ALERT_RULES_NOT_SUPPORTED_FOR_DATASOURCE:
        return `The datasource selected does not support alert ruler`;
      case DetectErrorCode.LOKI_CREATE_ERROR:
        return `Could not create alert: ${this.message}`;
      case DetectErrorCode.LOKI_CREATE_SUCCESS:
        return 'Rule created successfully';
      case DetectErrorCode.LOKI_NO_RULES_TO_CONVERT:
        return 'Please specify rule(s) to convert';
      case DetectErrorCode.SERVICE_CREATE_SIGMA_RULE_ERROR:
        return this.message;
      case DetectErrorCode.SERVICE_CREATE_SIGMA_RULE_SUCCESS:
        return 'Successfully created Sigma Rule';
      case DetectErrorCode.DATA_SOURCE_QUERY_ERROR:
        return this.message;
      case DetectErrorCode.GMA_ALERT_CREATE_SUCCESS:
        return `Successfully created an alert on Grafana Alerting: ${this.message}`;
      case DetectErrorCode.GMA_ALERT_CREATE_FAILURE:
        return `Could not create Grafana Alerting alert: ${this.message}`;
      case DetectErrorCode.DASHBOARD_CREATE_SUCCESS:
        return `Successfully created a Grafana dashboard: ${this.message}`;
      case DetectErrorCode.DASHBOARD_CREATE_FAILURE:
        return `An error occurred when creating the Grafana dashboard: ${this.message}`;
      case DetectErrorCode.GRAFANA_FOLDER_UID_UNDEF:
        return `${PRODUCT_NAME} configuration requires additional steps: a Grafana folder needs to be created and selected`;
      case DetectErrorCode.CUSTOM_REPO_MISSING_FIELDS:
        return 'Some required fields are missing';
      case DetectErrorCode.CUSTOM_REPO_FETCH_ERROR:
        return `Error whilst fetching custom repositories ${this.message}`;
      case DetectErrorCode.CUSTOM_REPO_SAVED:
        return 'Custom repository saved';
      case DetectErrorCode.CUSTOM_REPO_DELETED:
        return 'Custom repository deleted';
      case DetectErrorCode.CUSTOM_REPO_ERROR_SAVE:
        return `Error whilst saving custom repository: ${this.message}`;
      case DetectErrorCode.CUSTOM_REPO_ERROR_DELETE:
        return `Error whilst deleting custom repository ${this.message}`;
      case DetectErrorCode.MAIN_REPO_FETCH_ERROR:
        return `Error whilst fetching main repository: ${this.message}`;
      case DetectErrorCode.FORCE_FETCH_REPO_ERROR:
        return `Error whilst force fetching repository: ${this.message}`;
      case DetectErrorCode.FORCE_FETCH_REPO_SUCCESS:
        return `The rules will be fetched as soon as possible`;
      default:
        return 'An unknown error occurred whilst completing your request.';
    }
  }
}

/**
 * Defines the Error Context interface for the Detect
 */
export interface DetectErrorContextInterface {
  // errorList enables the error context to display errors in the
  errorList: Array<DetectError>;

  pushError(err: DetectError): void;

  dismissError(idx: number): void;
}

// DetectErrorContext defines the global context of errors from the Octokit API.
export const DetectErrorContext = createContext<DetectErrorContextInterface>({
  errorList: [],
  pushError: (err: DetectError) => {},
  dismissError: (idx: number) => {},
});

/**
 * Defines the properties to be sent to a provider
 */
export interface DetectErrorProviderProps {
  children?: React.ReactNode;
}

/**
 * Wrap a child component in the Octokit API Errors, acts like an error boundary.
 * @param {DetectErrorProviderProps} props the passed in properties
 * @returns {void}
 */
export const DetectErrorProvider = (props: DetectErrorProviderProps) => {
  const [errors, setErrors] = useState<Array<DetectError>>([]);

  return (
    <DetectErrorContext.Provider
      value={{
        errorList: errors,
        pushError: (err: DetectError) => setErrors([...errors, err]),
        dismissError: (idx: number) => setErrors(errors.filter((_, index) => index !== idx)),
      }}
    >
      {props?.children}
    </DetectErrorContext.Provider>
  );
};
