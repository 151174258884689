import React, { ChangeEvent, useState } from 'react';
import { Cascader, Counter, Stack, Input, Label, Select, getInputStyles, useTheme2 } from '@grafana/ui';
import { RulePointer, useGitTree } from 'hooks';
import { NullID } from '../shared/constants';

export interface FileInfo {
  path: string;
  content: string;
}

export interface GitHubRepoFileCascaderProps {
  onSelectFile: (paths: Array<RulePointer>) => void;
  width?: number;
  autoFocus?: boolean;
}

export default function GitHubRepoFileCascader(props: GitHubRepoFileCascaderProps) {
  const { onSelectFile, width = 0 } = props;
  const theme = useTheme2();
  const styles = getInputStyles({ theme, width });
  const [ruleCount, setRuleCount] = useState(0);
  const [selectedType, setSelectedType] = useState('all');
  const [selectedStatus, setSelectedStatus] = useState('all');
  const [selectedContains, setSelectedContains] = useState('');

  const [sourceRules] = useGitTree(selectedType, selectedStatus, selectedContains);

  const types = [
    { value: 'all', label: 'All' },
    { value: 'standard', label: 'Standard' },
    { value: 'custom', label: 'Custom' },
  ];

  const status = [
    { value: 'all', label: 'All' },
    { value: 'stable', label: 'Stable' },
    { value: 'experimental', label: 'Experimental' },
    { value: 'test', label: 'Test' },
    { value: 'deprecated', label: 'Deprecated' },
  ];

  return (
    <Stack direction="column" grow={1}>
      <Stack direction="row">
        <Label>Type of rules to import:</Label>
        <Select
          options={types}
          placeholder="All"
          onChange={(val) => {
            setSelectedType(val.value?.toString() ?? 'all');
          }}
          value={selectedType}
        />
      </Stack>
      <Stack direction="row">
        <Label>Status of the rules:</Label>
        <Select
          options={status}
          placeholder="All"
          onChange={(val) => {
            setSelectedStatus(val.value?.toString() ?? 'all');
          }}
          value={selectedStatus}
        />
      </Stack>
      <Stack direction="row">
        <Label>Search a rule</Label>
        <Input
          type="text"
          value={selectedContains}
          placeholder="Enter space-separated keywords"
          contentEditable="true"
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setSelectedContains(e.target.value ?? '');
          }}
        ></Input>
      </Stack>
      <div className={styles.wrapper} data-testid="cascader-wrapper">
        <div className={styles.inputWrapper}>
          <Cascader
            options={sourceRules}
            placeholder="Import rules"
            displayAllSelectedLevels
            autoFocus={props.autoFocus}
            onSelect={(val: string) => {
              const files = listFilesWithin(sourceRules, val).map((f) => {
                f.filename = `${f.repo_id ?? NullID}/${f.value}`;
                return f;
              });
              onSelectFile(files);
              setRuleCount(files.length);
            }}
          />
        </div>
        <div className={styles.addon}>
          <Counter value={ruleCount} />
        </div>
      </div>
    </Stack>
  );
}

const listFilesWithin = (topOptions: Array<RulePointer>, subPath: string): Array<RulePointer> => {
  const path = subPath.split('/');
  let options: Array<RulePointer> = [...topOptions]; // make a copy of the current top options
  // This could be rather slow for a large tree with many subfolders - hard to see how it could be optimised...
  for (const part of path) {
    if (options.length === 0) {
      throw new Error(`Unexpected end of file tree for: ${path}`);
    } else {
      for (const opt of options) {
        if (opt.label === part) {
          let subitems = opt.items ?? [opt];
          options = [...subitems];
          break;
        }
      }
    }
  }
  const files: Array<RulePointer> = [];
  const toExplore = [...options]; // make a copy of the options to list
  while (toExplore.length > 0) {
    const option = toExplore.shift();
    if (option) {
      if (option.items) {
        toExplore.unshift(...option.items);
      } else {
        if (option.value?.startsWith(subPath)) {
          files.push(option);
        }
      }
    }
  }
  return files;
};
