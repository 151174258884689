import { DetectError, DetectErrorCode } from './generic';

export const LokiAlertRulesNotSupported = (): DetectError => {
  const err = new DetectError(DetectErrorCode.LOKI_ALERT_RULES_NOT_SUPPORTED_FOR_DATASOURCE);
  err.level = 'error';
  return err;
};

export const LokiAlertRulesNotEnabled = (): DetectError => {
  const err = new DetectError(DetectErrorCode.LOKI_ALERT_RULES_NOT_ENABLED_FOR_DATASOURCE);
  err.level = 'error';
  return err;
};

export const LokiAlertCreateError = (message: Error): DetectError => {
  const err = new DetectError(DetectErrorCode.LOKI_CREATE_ERROR);
  err.message = message.name;
  err.stack = message.stack;
  err.level = 'error';
  return err;
};

export const LokiAlertCreateSuccess = (): DetectError => {
  const err = new DetectError(DetectErrorCode.LOKI_CREATE_SUCCESS);
  err.level = 'success';
  err.stack = undefined;
  return err;
};

export const LokiNoRules = (): DetectError => {
  const err = new DetectError(DetectErrorCode.LOKI_NO_RULES_TO_CONVERT);
  err.level = 'warning';
  err.stack = undefined;
  return err;
};
