import { Button, FieldSet, InlineField, InlineLabel, Input, SecretInput, Stack, TextLink } from '@grafana/ui';
import { useCustomRepo } from 'hooks/useCustomRepo';
import React, { useState } from 'react';
import { DeleteCustomRepoModal } from 'components/DeleteCustomRepoModal';
import { useForceFetchRepo } from 'hooks/useForceFetchRepo';

interface CustomRepositoryFormFieldsProps {
  isNew: boolean;
  uuid: string;
  onSaved?(): any;
  onDeleted?(): any;
}

/* eslint-disable camelcase */

export const CustomRepository = (props: CustomRepositoryFormFieldsProps) => {
  if (props.isNew === true) {
    props.uuid = '';
  }

  const [fetched, setFetched] = useState<boolean>(false);
  const [
    repo,
    owner,
    folder,
    url,
    ref,
    token,
    name,
    lastCommit,
    lastError,
    getCustomRepo,
    saveCustomRepo,
    deleteCustomRepo,
    updateUrl,
    setRef,
    setToken,
    setName,
    setFolder,
    setLastCommit,
    setLastError,
  ] = useCustomRepo(props.uuid);
  const [forceFetchRepo] = useForceFetchRepo();

  const [displayDeleteDialog, setDisplayDeleteDialog] = useState<boolean>(false);

  if (props.isNew === false && props.uuid !== '' && fetched === false) {
    getCustomRepo();
    setFetched(true);
  }

  async function saveRepo(onSaved: any) {
    if (owner === '' || repo === '' || name === '') {
      console.error('Missing required fields');
      return;
    }
    await saveCustomRepo();
    doForceFetchRepo();
    onSaved();
  }

  async function deleteRepo(onDeleted: any) {
    await deleteCustomRepo();
    onDeleted();
  }

  const doForceFetchRepo = () => {
    if (props.isNew === true || props.uuid === '') {
      console.error('Cannot fetch rules from a new repository before saving it');
      return;
    }

    forceFetchRepo(props.uuid);
  };

  return (
    <FieldSet>
      <InlineField label="Repository Name" grow tooltip={<>Name that will be associated with the repository</>}>
        <Input
          type="text"
          value={name}
          onChange={(evt) => {
            setName(evt.currentTarget.value);
          }}
          contentEditable={true}
        ></Input>
      </InlineField>
      <InlineField label="Repository URL" grow tooltip={<>URL of the repository</>}>
        <Input
          type="text"
          value={url}
          onChange={(evt) => {
            updateUrl(evt.currentTarget.value);
          }}
          contentEditable={true}
        ></Input>
      </InlineField>
      <InlineField label="Folder" grow tooltip={`Folder prefix (e.g. 'rules' matches 'rules' and 'rules-threats')`}>
        <Input
          type="text"
          value={folder}
          onChange={(evt) => {
            setFolder(evt.currentTarget.value);
          }}
          contentEditable={true}
        ></Input>
      </InlineField>
      <InlineField label="Branch" grow tooltip={`Use 'default' for the main branch`}>
        <Input
          type="text"
          value={ref}
          contentEditable={true}
          onChange={(evt) => {
            setRef(evt.currentTarget.value);
          }}
        ></Input>
      </InlineField>
      <InlineField label="PAT" grow tooltip={<>Token used to authenticate</>}>
        <SecretInput
          value={token}
          onChange={(evt) => {
            setToken(evt.currentTarget.value);
          }}
          isConfigured={false}
          onReset={() => {}}
        />
      </InlineField>
      <InlineField
        label="Last commit fetched"
        grow
        error={`An error occurred while fetching the repository: ${lastError}`}
        invalid={lastError !== ''}
        tooltip={<>Last commit successfully fetched from the repository</>}
      >
        <InlineLabel>
          {lastCommit !== '' ? (
            <TextLink href={`https://www.github.com/${owner}/${repo}/commit/${lastCommit}`} icon="github" external>
              {lastCommit}
            </TextLink>
          ) : (
            'None'
          )}
        </InlineLabel>
      </InlineField>
      <Stack direction="row">
        <Button
          variant="primary"
          onClick={() => {
            saveRepo(props.onSaved);
          }}
        >
          Save
        </Button>
        <Button
          variant="destructive"
          onClick={() => {
            setDisplayDeleteDialog(true);
          }}
        >
          Delete
        </Button>
        {props.isNew === true ? null : (
          <Button variant="secondary" onClick={doForceFetchRepo}>
            Fetch rules now
          </Button>
        )}
      </Stack>
      <DeleteCustomRepoModal
        isOpen={displayDeleteDialog}
        dismiss={() => {
          setDisplayDeleteDialog(false);
        }}
        {...props}
        onDelete={() => {
          deleteRepo(props.onDeleted);
          setDisplayDeleteDialog(false);
        }}
        repoName={name}
      />
    </FieldSet>
  );
};
