import { css } from '@emotion/css';
import { useBreakPoint } from 'hooks/useBreakPoint';
import React from 'react';

interface HorizontalContainerProps {
  children: React.ReactNode;
  breakPoint: number;
  defaultOpen?: boolean;
  className?: string;
}

export const HorizontalContainer = (props: HorizontalContainerProps) => {
  const [size] = useBreakPoint(props.breakPoint);

  if (size) {
    return (
      <div
        className={css`
          display: flex;
          flex-direction: row;
          gap: 20px;
        `}
        data-testid="parent"
      >
        {props.children}
      </div>
    );
  }

  return <>{props.children}</>;
};
