import { DataFrame, Field, FieldType, LinkModel } from '@grafana/data';
import { IconName } from '@grafana/ui';
import { FolderPanelOptions } from '../types';

export type RowList = Array<FieldValue>;
export type FieldLabels = Array<string>;
export type FieldIcons = Set<IconName>;
export type FieldDataSourceInner = {
  target: string;
  uid: string;
};
export type FieldDataSources = Array<FieldDataSourceInner>;
// The structure of a row is:
// * Primary Value
// * Is Directory
// * Selection Value
// * List of Labels
// * Children
// * Any Links associated with the row (well... primary field at the moment)
// * List of DataSources for which there is a conversion
// * List of Icons
export type FieldValue = [
  any,
  boolean,
  string,
  FieldLabels,
  DataFrame | null,
  Array<LinkModel<any>> | null,
  FieldDataSources,
  FieldIcons | null
];

export const dataFrameToRow = (df: DataFrame, options: FolderPanelOptions) => {
  const rows: RowList = Array<FieldValue>(df.length);

  for (let row = 0; row < df.length; row++) {
    if (!df.fields) {
      break;
    }

    rows[row] = [
      getPrimaryValueForRow(df, row, options),
      getIsDirectoryForRow(df, row, options),
      getSelectionValueForRow(df, row, options),
      getLabelsForRow(df, row, options),
      getChildrenForRow(df, row, options),
      getLinksForRow(df, row, options),
      getDataSourcesForRow(df, row, options),
      getIconsForRow(df, row, options),
    ];
  }

  return rows;
};

export const getValueForRow = (field: Field, row: number) => {
  let fieldVal = field.values.at(row);

  if (field.display) {
    let displayVal = field.display(fieldVal);
    fieldVal = (displayVal.prefix ?? '') + displayVal.text + (displayVal.suffix ?? '');
  }

  return fieldVal;
};

export const getPrimaryValueForRow = (df: DataFrame, row: number, options: FolderPanelOptions) => {
  for (let field of df.fields) {
    if (field.name === options.fieldName) {
      return getValueForRow(field, row);
    }
  }

  return '';
};

export const getIsDirectoryForRow = (df: DataFrame, row: number, options: FolderPanelOptions) => {
  for (let field of df.fields) {
    if (options.hasChildren && field.name === options.fieldChildren && field.type === FieldType.frame) {
      const df: DataFrame = field.values.at(row);
      if (df) {
        return df.length > 0;
      }
    }
  }

  return false;
};

export const getSelectionValueForRow = (df: DataFrame, row: number, options: FolderPanelOptions) => {
  for (let field of df.fields) {
    if (options.allowSelect && field.name === options.fieldSelection && field.type === FieldType.string) {
      return field.values.at(row);
    }
  }

  return '';
};

export const getLabelsForRow = (df: DataFrame, row: number, options: FolderPanelOptions) => {
  let labels: FieldLabels = [];
  for (let field of df.fields) {
    if (field.type === FieldType.boolean && !options.fieldLabels.includes(field.name)) {
      if (field.values.at(row)) {
        labels.push(field.name);
      }
    } else if (field.name === options.fieldRepoName) {
      if (field.values.at(row)) {
        labels.push(field.values.at(row) as string);
      }
    } else if (
      !options.fieldLabels.includes(field.name) &&
      options.hasChildren &&
      options.allowSelect &&
      field.name !== options.fieldChildren &&
      field.name !== options.fieldSelection &&
      field.name !== options.fieldConversions
    ) {
      labels.push(`${field.name}:${getValueForRow(field, row)}`);
    }
  }

  return labels;
};

export const getDataSourcesForRow = (df: DataFrame, row: number, options: FolderPanelOptions) => {
  for (let field of df.fields) {
    if (field.name === options.fieldConversions) {
      const dfConv = field.values.at(row) as DataFrame;
      if (!dfConv) {
        return [] as FieldDataSources;
      }
      const dataSources: FieldDataSources = Array<FieldDataSourceInner>(dfConv.length);

      for (let innerRow = 0; innerRow < dfConv.length; innerRow++) {
        if (!dfConv.fields) {
          break;
        }
        dataSources[innerRow] = {
          target: getTargetForConvRow(dfConv, innerRow, options),
          uid: getDsUidForConvRow(dfConv, innerRow, options),
        };
      }

      return dataSources;
    }
  }
  return [] as FieldDataSources;
};

export const getChildrenForRow = (df: DataFrame, row: number, options: FolderPanelOptions) => {
  if (getIsDirectoryForRow(df, row, options)) {
    for (let field of df.fields) {
      if (options.hasChildren && field.name === options.fieldChildren && field.type === FieldType.frame) {
        return field.values.at(row) as DataFrame;
      }
    }
  }

  return null;
};

export const getLinksForRow = (df: DataFrame, row: number, options: FolderPanelOptions) => {
  for (let field of df.fields) {
    if (field.getLinks && field.name === options.fieldName) {
      return field.getLinks({
        valueRowIndex: row,
      });
    }
  }

  return null;
};

export const getTargetForConvRow = (df: DataFrame, row: number, options: FolderPanelOptions) => {
  for (let field of df.fields) {
    if (field.name === options.fieldTarget) {
      return field.values.at(row);
    }
  }

  return null;
};

export const getDsUidForConvRow = (df: DataFrame, row: number, options: FolderPanelOptions) => {
  for (let field of df.fields) {
    if (field.name === options.fieldDsUid) {
      return field.values.at(row);
    }
  }

  return null;
};

// Identifies the relevant icons to show for a given row. In this case, we do so by examining
// each associated conversion and, if they contain an alertId or dashboardId, adding the
// relevant icon name to the set of icons to display.
export const getIconsForRow = (df: DataFrame, row: number, options: FolderPanelOptions) => {
  const icons: FieldIcons = new Set();
  for (let field of df.fields) {
    if (field.name === options.fieldAlerting) {
      if (field.values.at(row)) {
        icons.add('exclamation-circle');
      }
    }
    if (field.name === options.fieldConversions) {
      const dfConv = field.values.at(row) as DataFrame;
      if (!dfConv || !dfConv.fields) {
        return null;
      }
      for (let innerRow = 0; innerRow < dfConv.length; innerRow++) {
        for (let innerField of dfConv.fields) {
          if (innerField.values.at(innerRow)) {
            if (innerField.name === options.fieldAlertId) {
              icons.add('bell');
            }
            if (innerField.name === options.fieldDashboardId) {
              icons.add('dashboard');
            }
          }
        }
        // If the performance of displaying rows, due to the number of conversions, is degraded
        // we could improve it by terminating this loop early if all icons have been added
      }
    }
  }
  return icons;
};
