import React, { useState } from 'react';
import { Alert, Button, InlineFieldRow, Modal } from '@grafana/ui';
import GitHubRepoFileCascader from 'components/GitHubRepoFileCascader';
import { RulePointer } from 'hooks';

interface SigmaCustomRuleModalProps {
  isOpen?: boolean;

  onCancel(): void;

  onImport(rules: Array<RulePointer>): void;
}

interface SigmaGitHubRuleImportModalButtonProps {
  onImport(rules: Array<RulePointer>): void;
}

export const SigmaGitHubRuleImportModal = (props: SigmaCustomRuleModalProps) => {
  const [filePickers, setFilePickers] = useState<Array<Array<RulePointer>>>([[]]);
  const [totalRules, setTotalRules] = useState(0);
  const [showInfo, setShowInfo] = useState(false);
  return (
    <Modal
      title={'Rule Import'}
      closeOnBackdropClick={false}
      isOpen={props.isOpen}
      onDismiss={() => {
        setFilePickers([[]]);
        setTotalRules(0);
        props.onCancel();
      }}
      trapFocus
    >
      {showInfo ? (
        <Alert title="Large Import" severity="info">
          <p>
            The below selection contains a large number of rules and could impact the performance of the user interface.
          </p>
          <p>
            If you are happy to proceed, please continue. If not please narrow down your selection using the pickers.
          </p>
        </Alert>
      ) : null}
      {filePickers.map((e, idx) => {
        return (
          <InlineFieldRow key={`picker-${idx}`}>
            <GitHubRepoFileCascader
              autoFocus
              onSelectFile={(f) => {
                let x = [...filePickers];
                x[idx] = f;
                let l = 0;
                for (let arr of x) {
                  l += arr.length;
                }
                setTotalRules(l);
                setFilePickers(x);
              }}
            />
          </InlineFieldRow>
        );
      })}
      <Modal.ButtonRow>
        <Button variant="secondary" icon="plus" onClick={() => setFilePickers([...filePickers, []])}>
          Choose More Rules
        </Button>
        <Button
          variant="primary"
          icon="import"
          onClick={() => {
            if (totalRules >= 100 && !showInfo) {
              setShowInfo(true);
              return;
            }
            props.onImport(
              filePickers.reduce((prev, cur) => [
                ...prev.filter((item) => item.filename !== ''),
                ...cur.filter((item) => item.filename !== ''),
              ])
            );
            setFilePickers([[]]);
            setTotalRules(0);
            setShowInfo(false);
          }}
          disabled={totalRules <= 0}
        >
          {!showInfo ? `Import ${totalRules} Rules` : `Confirm ${totalRules} Rules`}
        </Button>
      </Modal.ButtonRow>
    </Modal>
  );
};

export const SigmaGitHubRuleImportModalButton = (props: SigmaGitHubRuleImportModalButtonProps) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <SigmaGitHubRuleImportModal
        isOpen={isOpen}
        onCancel={() => setIsOpen(!isOpen)}
        onImport={(rules: Array<RulePointer>) => {
          setIsOpen(!isOpen);
          props.onImport(rules);
        }}
      />
      <Button variant="secondary" icon="github" onClick={() => setIsOpen(true)}>
        Import Rules
      </Button>
    </>
  );
};
