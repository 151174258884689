import { SelectableValue } from '@grafana/data';
import { Field, Select } from '@grafana/ui';
import React, { useContext, useEffect, useState } from 'react';
import { ConversionContext } from 'stores/conversion';

interface FieldTargetsProps {
  targets: Array<SelectableValue<string>>;
  target?: SelectableValue<string>;
  onChange: (target: SelectableValue<string>) => void;
}

export const FieldTargets = (props: FieldTargetsProps) => {
  return (
    <Field label="Target" description="Target Log system">
      <Select options={props.targets} value={props.target} onChange={props.onChange} />
    </Field>
  );
};

export interface StatefulFieldTargetsProps {
  onChange?: (target: SelectableValue<string>) => void;
}

export const StatefulFieldTargets = (props: StatefulFieldTargetsProps) => {
  const {
    data: { targets: stateTargets, target: stateTarget },
    operations: { setTarget: doSetTarget },
  } = useContext(ConversionContext);

  const [targets, setTargets] = useState<Array<SelectableValue<string>>>([]);
  const [target, setTarget] = useState<SelectableValue<string>>();

  useEffect(() => {
    stateTargets.subscribe(setTargets);
    stateTarget.subscribe(setTarget);
  }, [stateTargets, stateTarget]);

  return (
    <FieldTargets
      targets={targets}
      target={target}
      onChange={(e) => {
        doSetTarget(e);
        props.onChange && props.onChange(e);
      }}
    />
  );
};
