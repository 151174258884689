import { SelectableValue } from '@grafana/data';
import { Tab, TabsBar } from '@grafana/ui';
import React from 'react';

interface FormatTabsProps {
  activeFormat: string;
  formats?: Array<SelectableValue<string>>;
  onChange?: (tab: string) => void;
}

export const FormatTabs = (props: FormatTabsProps) => {
  return (
    <TabsBar>
      {props.formats &&
        props.formats.map((format) => (
          <Tab
            key={`format-tab-${format.value}}`}
            label={format.label ?? format.value ?? 'Unknown'}
            active={props.activeFormat === format.value}
            onChangeTab={(e) => {
              props.onChange && props.onChange(format.value ?? 'default');
            }}
          />
        ))}
    </TabsBar>
  );
};
