import { monacoTypes } from '@grafana/ui';
import { getRequiredRules } from './requiredRules';
import { getOptionalRules } from './optionalRules';

export const suggestions = (
  m: monacoTypes.editor.ITextModel,
  pos: monacoTypes.Position
): Array<monacoTypes.languages.CompletionItem> => {
  let x = m.getWordUntilPosition(pos);

  return [...getRequiredRules(), ...getOptionalRules()]
    .map((s) => ({
      ...s,
      range: {
        insert: {
          startColumn: x.startColumn,
          endColumn: x.startColumn + s.insertText.length,
          startLineNumber: pos.lineNumber,
          endLineNumber: pos.lineNumber,
        },
        replace: {
          startColumn: x.startColumn,
          endColumn: x.startColumn + s.insertText.length,
          startLineNumber: pos.lineNumber,
          endLineNumber: pos.lineNumber,
        },
      },
      filterText: s.insertText,
    }))
    .filter((s) => {
      if (s.insertText.startsWith(x.word)) {
        return true;
      }
      // Javascript has some weird behaviour with regexes in that they don't get reset
      // this is to counter-act that behaviour
      const regex = new RegExp(s.regex);
      return !regex.test(m.getValue());
    })
    .filter((s) => {
      if (!s.regexLine.notStartWith) {
        return true;
      }
      const r = new RegExp(s.regexLine.notStartWith);
      return !r.test(m.getLineContent(pos.lineNumber));
    })
    .filter((s) => {
      if (!s.regexLine.previousKeyword) {
        return true;
      }
      let line = pos.lineNumber;
      while (line > 0) {
        try {
          const r = new RegExp(s.regexLine.previousKeyword);
          line--;
          if (r.test(m.getLineContent(line))) {
            return true;
          }
          const parentR = new RegExp(/^\w/gim);
          if (parentR.test(m.getLineContent(line))) {
            return false;
          }
        } catch (e) {
          return false;
        }
      }
      return false;
    });
};
