import React, { useContext, useEffect, useMemo } from 'react';
import { AppRootProps } from '@grafana/data';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Sigma } from './Sigma';
import { Infrastructure } from './Infrastructure';
import { Main } from './Main';
import { DetectSettings } from './AppConfig';
import { ProviderWrapper } from 'contexts/ProviderWrapper';
import { SigmaProvider } from 'contexts/sigma';
import { PipelineEditor } from './PipelineEditor';
import { InstallPage } from './Install';
import { Configured1, PLUGIN_ROOT } from 'shared/constants';
import { StoreProvider, PipelineContext, EnvironmentContext, AlertProvider } from 'stores';
import { DetectErrorProvider } from 'contexts/errors';
import { getDetectScene } from '../scenes/detect';
import { RuleEditorPage } from './RuleEditor';
import '../scenes/datasource/sigmarules/index';
import '../scenes/datasource/repos/index';
import '../scenes/datasource/alerts';
import '../scenes/datasource/logsource';
import '../vizualizations/folder-panel';
import '../vizualizations/rule-panel';
import { DashboardProvider } from 'contexts/dashboard';

export function AppContent(props: AppRootProps<DetectSettings>) {
  const { jsonData, secureJsonFields } = props.meta;
  const { loading, checks, checkAll, __setMeta, servicePresent } = useContext(EnvironmentContext);
  const {
    operations: { __setRemote },
  } = useContext(PipelineContext);

  useEffect(() => {
    if (loading && checks === 0) {
      checkAll();
      __setMeta(props.meta);
    }
  }, [loading, checks, checkAll, __setMeta, props.meta]);

  useEffect(() => {
    if (servicePresent !== undefined) {
      __setRemote(servicePresent);
    }
  }, [servicePresent, __setRemote]);

  const sigmaScene = useMemo(() => getDetectScene(), []);

  return (
    <ProviderWrapper
      providers={[
        <SigmaProvider key="sigma-provider" sigmaAPIConfig={jsonData?.sigmaAPIConfig} />,
        <AlertProvider key="alerting-provider" alertingConfig={jsonData?.alerting} />,
        <DashboardProvider key="dashboard-provider" dashboardConfig={jsonData?.dashboardConfig} />,
      ]}
    >
      <>
        {secureJsonFields && !secureJsonFields[Configured1] ? <Redirect to={`${PLUGIN_ROOT}/install`} /> : null}
        <Switch>
          <Route exact path={`${PLUGIN_ROOT}/`} component={Main} />
          <Route path={`${PLUGIN_ROOT}/converter`} component={Sigma} />
          <Route path={`${PLUGIN_ROOT}/infra`} component={Infrastructure} />
          <Route path={`${PLUGIN_ROOT}/sigma/pipelineEditor`} component={PipelineEditor} />
          <Route path={`${PLUGIN_ROOT}/sigma/ruleEditor`} component={RuleEditorPage} />
          <Route path={`${PLUGIN_ROOT}/install`} component={() => <InstallPage {...props} />} />
          {/* This has been updated so that we can render all of the scenes in the same way through a single scenes app */}
          <Route path={`${PLUGIN_ROOT}/scenes`} render={() => <sigmaScene.Component model={sigmaScene} />} />
        </Switch>
      </>
    </ProviderWrapper>
  );
}

export function App(props: AppRootProps<DetectSettings>) {
  return (
    <DetectErrorProvider>
      <StoreProvider>
        <AppContent {...props} />
      </StoreProvider>
    </DetectErrorProvider>
  );
}
