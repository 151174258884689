import { Field, Input, Switch } from '@grafana/ui';
import React, { ChangeEvent, useContext, useEffect, useState } from 'react';
import { Argument } from 'shared/types';
import { ConversionContext } from 'stores/conversion';
import { sentenceCase } from 'utils/helpers';
import { combineLatest, take } from 'rxjs';

interface FieldExtraArgsProps {
  extraArguments: { [key: string]: Argument };
  values?: { [key: string]: any };
  onChanges: (key: string, e: any) => void;
}

export const FieldExtraArgs = (props: FieldExtraArgsProps) => {
  const { extraArguments, values } = props;

  return (
    <>
      {Object.entries(extraArguments).map(([key, argument]) => {
        const label = sentenceCase(key);
        let value = values && key in values ? values[key] : argument.default_value;

        switch (argument.type) {
          case 'bool' || 'boolean':
            if (typeof value === 'string') {
              value = value === 'true';
            }
            return (
              <Field label={label} key={key}>
                <Switch value={value} onChange={(e: ChangeEvent<HTMLInputElement>) => props.onChanges(key, !value)} />
              </Field>
            );
          default:
            return (
              <Field label={label} key={key}>
                <Input value={value} onChange={(e: any) => props.onChanges(key, e)} />
              </Field>
            );
        }
      })}
    </>
  );
};

export const StatefulFieldExtraArgs = () => {
  const {
    data: { extraArguments: stateExtraArguments, transformedExtraArguments: stateTransformedExtraArguments },
    operations: { setTransformedExtraArguments: doSetTransformedExtraArguments },
  } = useContext(ConversionContext);

  const [extraArguments, setExtraArguments] = useState<{ [key: string]: Argument }>({});
  const [currentValues, setCurrentValues] = useState<{ [key: string]: any }>({});

  useEffect(() => {
    combineLatest({
      stateExtraArguments,
      stateTransformedExtraArguments,
    })
      .pipe(take(1))
      .subscribe((e) => {
        if (!e.stateExtraArguments) {
          return;
        }

        setExtraArguments(e.stateExtraArguments);
        let values = Object.keys(e.stateExtraArguments).reduce((a, key) => {
          return {
            ...a,
            [key]:
              e.stateTransformedExtraArguments[key] !== undefined
                ? e.stateTransformedExtraArguments[key]
                : e.stateExtraArguments[key].default_value,
          };
        }, {} as { [key: string]: any });

        setCurrentValues(values);
      });
  }, [stateExtraArguments, setExtraArguments, stateTransformedExtraArguments]);

  useEffect(() => {
    stateTransformedExtraArguments.subscribe((e) => {
      setCurrentValues(e);
    });
  }, [stateTransformedExtraArguments]);

  return (
    <FieldExtraArgs
      extraArguments={extraArguments}
      values={currentValues}
      onChanges={(key, e) => doSetTransformedExtraArguments({ ...currentValues, [key]: e })}
    />
  );
};
