import React, { useContext } from 'react';
import { AppRootProps } from '@grafana/data';
import { EmbeddedDashboard, PluginPage } from '@grafana/runtime';
import { PLUGIN_ID } from 'shared/constants';
import { DashboardContext } from 'contexts/dashboard';
import { css } from '@emotion/css';

export function Main(props: AppRootProps) {
  const dashboardConfig = useContext(DashboardContext);

  return (
    <PluginPage
      pageNav={{
        text: 'Grafana Detect',
        subTitle: 'Observability for security incidents',
        img: `public/plugins/${PLUGIN_ID}/img/logo.svg`,
        hideFromBreadcrumbs: true,
      }}
    >
      {dashboardConfig?.alertingDBUid ? (
        <div
          className={css`
            display: flex;
            height: 100%;
          `}
        >
          <EmbeddedDashboard uid={dashboardConfig.alertingDBUid} />
        </div>
      ) : (
        <p>Grafana Detect is an app to enable the use of Grafana for observing and responding to security incidents.</p>
      )}
    </PluginPage>
  );
}
