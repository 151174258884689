import { css } from '@emotion/css';
import { Menu, MenuGroup, MenuItem } from '@grafana/ui';
import { useQueryParams } from 'hooks/useQueryParams';
import React, { useContext, useEffect, useState } from 'react';
import { PipelineList } from 'shared/requests/pipelines';
import { PipelineContext } from 'stores/pipelines';

interface StatefulPipelineEditorMenuProps {
  shadow?: boolean;
}

interface PipelineEditorMenuProps extends StatefulPipelineEditorMenuProps {
  pipelines: PipelineList;
  scenes?: boolean;

  onSelect(id: string): void;

  onCreate(): void;
}

export const PipelineEditorMenu = (props: PipelineEditorMenuProps) => {
  const { pipelines, onCreate, onSelect } = props;

  return (
    <Menu
      style={{
        maxWidth: !props.scenes ? '250px' : undefined,
        width: !props.scenes ? '15%' : '100%',
        height: '100%',
        margin: '10px',
        boxShadow: props.shadow ? undefined : 'none',
      }}
      title="Editor Menu"
    >
      <MenuGroup label="Actions">
        <MenuItem
          active={true}
          label="New Pipeline"
          icon="plus"
          onClick={() => {
            onCreate();
          }}
        />
      </MenuGroup>

      <MenuGroup label="Library">
        {pipelines &&
          pipelines.data &&
          pipelines.data.map((e) => (
            <MenuItem
              key={`pipeline-${e.id}`}
              label={e.name}
              className={css`
                overflow: hidden;
              `}
              onClick={() => {
                onSelect(e.id);
              }}
            />
          ))}
      </MenuGroup>
    </Menu>
  );
};

export const StatefulPipelineEditorMenu = (props: StatefulPipelineEditorMenuProps) => {
  const {
    data: { list: stateList },
  } = useContext(PipelineContext);

  const [pipelines, setPipelines] = useState<PipelineList>({
    count: 0,
    data: [],
    page: 0,
    pageSize: 0,
    returned: 0,
  });

  const [qs, setQS] = useQueryParams();

  useEffect(() => {
    stateList?.subscribe(setPipelines);
  }, [stateList]);

  return (
    <PipelineEditorMenu
      {...props}
      pipelines={pipelines}
      onSelect={(id) => {
        qs.delete('create');
        qs.set('pipeline', id);
        setQS(qs);
      }}
      onCreate={() => {
        qs.delete('pipeline');
        qs.set('create', '');
        setQS(qs);
      }}
    />
  );
};
