import { RuntimeDataSource } from '@grafana/scenes';
import { BackendDataSourceResponse, getTemplateSrv, TemplateSrv } from '@grafana/runtime';
import { dataFrameFromJSON, DataQueryRequest, DataQueryResponse, FieldType, LoadingState } from '@grafana/data';
import { Observable } from 'rxjs';
import { fetchRemoteData, fetchResponseToBackendResponse, mapResponseToDataFrames, reduceFrameToStat } from './helper';
import { map } from 'rxjs/operators';
import { SigmaRuleQuery } from './index';

export class SigmaRuleMetricDataSource extends RuntimeDataSource {
  constructor(
    public pluginId: string,
    public uid: string,
    public readonly templateSrv: TemplateSrv = getTemplateSrv()
  ) {
    super(pluginId, uid);
    this.templateSrv = templateSrv;
  }

  query(request: DataQueryRequest<SigmaRuleQuery>): Promise<DataQueryResponse> | Observable<DataQueryResponse> {
    return fetchRemoteData(request).pipe(
      fetchResponseToBackendResponse,
      map<BackendDataSourceResponse, DataQueryResponse>((result) => {
        let keys = Object.keys(result.results);

        return {
          data: keys.map((key) => {
            const dataFrame = mapResponseToDataFrames(dataFrameFromJSON(result.results[key]?.frames?.at(0) ?? {}));

            return {
              fields: [
                {
                  name: 'count',
                  type: FieldType.number,
                  values: [reduceFrameToStat(dataFrame, request.targets.filter((x) => x.refId === key)[0].JSON.prefix)],
                  config: {},
                },
              ],
              length: 1,
              refId: key,
            };
          }),
          state: LoadingState.Done,
        };
      })
    );
  }

  testDatasource() {
    return Promise.resolve({ status: 'success', message: 'OK' });
  }
}
