import React, { useState } from 'react';
import {
  CustomVariable,
  SceneComponentProps,
  sceneGraph,
  SceneObjectBase,
  SceneObjectState,
  VariableDependencyConfig,
} from '@grafana/scenes';
import { Pagination, PanelChrome, useStyles2 } from '@grafana/ui';
import { DataFrame, GrafanaTheme2, LoadingState } from '@grafana/data';
import { css } from '@emotion/css';
import { Table, TableHeader } from 'components/scenes/logsource/table';
import { DisplayRow } from 'components/scenes/logsource/displayRow';

export interface LogSourceRow {
  id: string;
  name: string;
  source: string;
  config: any;
  uid: string;
  description: string;
}

export interface LogSourceOverviewSceneProps extends SceneObjectState {
  logsource: Array<string>;
}

export class LogSourceOverviewScene extends SceneObjectBase<LogSourceOverviewSceneProps> {
  protected static Component = Renderer;

  protected _variableDependency = new VariableDependencyConfig(this, {
    variableNames: ['logsource'],
    onReferencedVariableValueChanged: (variable) => {
      this.setState({ [variable.state.name]: variable.getValue()?.toString() });
    },
  });

  constructor(props: Partial<LogSourceOverviewSceneProps>) {
    super({
      logsource: [''],

      ...props,
    });

    this.addActivationHandler(() => {
      this.setState({ logsource: sceneGraph.lookupVariable('logsource', this)?.getValue() as Array<string> });
    });
  }

  onSelect(val: string) {
    const logsourceVar = sceneGraph.lookupVariable('logsource', this) as CustomVariable;
    const currentVal = logsourceVar.getValue() as Array<string>;
    if (currentVal.includes(val)) {
      logsourceVar.changeValueTo(currentVal.filter((v) => v !== val));
      return;
    }
    logsourceVar.changeValueTo(Array.from(new Set([...(logsourceVar.getValue() as Array<string>), val])));
  }

  onClear() {
    const logsourceVar = sceneGraph.lookupVariable('logsource', this) as CustomVariable;
    logsourceVar.changeValueTo([]);
  }

  getRows(series?: DataFrame) {
    let rows: Array<LogSourceRow> = [];
    if (!series) {
      return rows;
    }
    for (let index = 0; index < series.length; index++) {
      const name = series.fields.find((field) => field.name === 'name')?.values[index];
      const id = series.fields.find((field) => field.name === 'id')?.values[index];
      const uid = series.fields.find((field) => field.name === 'uid')?.values[index];
      const config = series.fields.find((field) => field.name === 'config')?.values[index];
      const description = series.fields.find((field) => field.name === 'description')?.values[index];
      rows.push({
        id: id,
        name: name?.toString() ?? 'Not Found',
        source: 'Source 1',
        config: config,
        uid: uid,
        description: description,
      });
    }
    return rows;
  }
}

function Renderer({ model }: SceneComponentProps<LogSourceOverviewScene>) {
  const { logsource } = model.useState();
  const styles = useStyles2(getStyles);
  const [page, setPage] = useState(1);

  const dataState = sceneGraph.getData(model).useState();
  const rows = model.getRows(dataState.data?.series[0]);

  return (
    <div className={styles.container}>
      <PanelChrome title="Log Source Configs" loadingState={dataState.data?.state ?? LoadingState.NotStarted}>
        {dataState.data?.state !== LoadingState.Done ? null : (
          <>
            <Table key="table">
              <TableHeader
                key="header"
                onSelect={() => {
                  model.onClear();
                }}
              />
              {rows.slice((page - 1) * 25, (page - 1) * 25 + 25).map((e) => (
                <DisplayRow
                  id={e.id}
                  name={e.name}
                  key={e.id}
                  uid={e.uid}
                  config={e.config}
                  logsource={logsource}
                  description={e.description}
                  onSelect={() => {
                    model.onSelect(e.id);
                  }}
                />
              ))}
            </Table>
            <div key="spacer" style={{ padding: 20 }} />
            <Pagination
              key="paginator"
              currentPage={page}
              numberOfPages={Math.ceil(rows.length / 25)}
              onNavigate={(page) => {
                setPage(page);
              }}
            />
          </>
        )}
      </PanelChrome>
    </div>
  );
}

const getStyles = (theme: GrafanaTheme2) => ({
  container: css({
    flexGrow: 1,
  }),
  headerCheckbox: css({
    padding: theme.spacing.x0_25,
    margin: theme.spacing.x0_25,
  }),
});
