import { useEffect, useState } from 'react';
import { getBackendSrv } from '@grafana/runtime';
import { RulePointer } from './useGitTree';
import { PLUGIN_ID } from 'shared/constants';
import { Rules } from 'shared/requests/conversions';
import { SigmaRuleModel } from 'api/detect-service';

interface RulesContentProperties {
  notify?(rules: Rules): void;
}

export const useRulesContent = (properties: RulesContentProperties) => {
  const [rules, setRules] = useState<Rules>({});
  const [requests, setRequests] = useState<Array<RulePointer>>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (requests.length === 0) {
      return;
    }
    setLoading(true);
    Promise.all(
      requests.map(
        (path) =>
          new Promise<Rules>((resolve, reject) => {
            getBackendSrv()
              /**
               * It's super important here that path.filename is the LAST part of the URL as it possibly contains a # which
               * will be excluded from the fetch as these are normally stripped out from the URLs.
               */
              .get<SigmaRuleModel>(
                `/api/plugins/${PLUGIN_ID}/resources/v1/sources.GetContent?source_id=grafana-detect&file=${path.filename}`
              )
              .then((d) => {
                resolve({
                  // FIXME: path.source always seems to be undefined, even when the rule is a custom one
                  [path.filename!]: { content: d.content, origin: path.source ?? 'github', ruleId: d.id },
                });
              })
              .catch((err) => reject(err));
          })
      )
    )
      .then((d) => {
        const rs: Rules = Object.assign({}, ...d);
        if (properties.notify) {
          properties.notify(rs);
        }
        setLoading(false);
        setRules(rs);
        setRequests([]);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [properties, requests]);

  return [rules, loading, setRequests] as const;
};
