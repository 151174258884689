import React, { createContext } from 'react';
import { SigmaAPIConfig } from 'shared/sigmaAPITypes';

export const SigmaContext = createContext<SigmaAPIConfig | undefined>({});

export interface SigmaProviderProps {
  children?: React.ReactNode;
  sigmaAPIConfig?: SigmaAPIConfig;
}

export const SigmaProvider = (props: SigmaProviderProps) => {
  return <SigmaContext.Provider value={props.sigmaAPIConfig}>{props.children}</SigmaContext.Provider>;
};
