import { BusEvent, BusEventWithPayload } from '@grafana/data';

interface DetectEventPayload extends BusEvent {
  type: DetectEventTypes;
  value?: any;
}

export class DetectEvent extends BusEventWithPayload<DetectEventPayload> {
  static type = 'detect';
}

export enum DetectEventTypes {
  None = 'none',
  EditorUpdateQuery = 'editor.updateQuery',
  EditorFetchLabels = 'editor.fetchLabels',
  EditorCreate = 'editor.create',
  EditorUpdate = 'editor.update',
  NotificationToast = 'notification.toast',
}
