import { RuntimeDataSource, sceneUtils } from '@grafana/scenes';
import { DataQueryRequest, DataQueryResponse, LoadingState } from '@grafana/data';
import { DataQuery } from '@grafana/schema';
import { Observable } from 'rxjs';
import { PLUGIN_ID } from '../../shared/constants';
import { BackendDataSourceResponse, getBackendSrv } from '@grafana/runtime';
import { map } from 'rxjs/operators';
import { backendResponseToDataFrame, fetchResponseToBackendResponse } from './sigmarules/helper';

export class AlertRuleHistory extends RuntimeDataSource {
  constructor(pluginId: string, uid: string) {
    super(pluginId, uid);
  }

  query(request: DataQueryRequest<DataQuery>): Observable<DataQueryResponse> {
    console.log('Running query for alert rule history');

    request.targets = request.targets.map((target) => ({
      ...target,
      TimeRange: request.range,
    }));

    return getBackendSrv()
      .fetch<BackendDataSourceResponse>({
        url: `/api/plugins/${PLUGIN_ID}/resources/v1/datasource/alerts`,
        method: 'POST',
        responseType: 'json',
        data: {
          app: request.app,
          filters: request.filters,
          Queries: request.targets,
          range: request.range,
        },
      })
      .pipe(fetchResponseToBackendResponse, backendResponseToDataFrame);
  }

  testDatasource() {
    return Promise.resolve({ status: 'success', message: 'OK' });
  }
}

try {
  sceneUtils.registerRuntimeDataSource({
    dataSource: new AlertRuleHistory('alert-rule-history', 'alert-rule-history'),
  });
  console.log("Successfully registered 'AlertRuleHistory' datasource");
} catch (e) {
  console.log('Scenes datasource already registered');
}
