import { DetectError, DetectErrorCode } from './generic';

export const CustomRepoMissingFields = (): DetectError => {
  const err = new DetectError(DetectErrorCode.CUSTOM_REPO_MISSING_FIELDS);
  err.message = 'Some required fields are missing';
  err.level = 'error';
  err.stack = undefined;
  return err;
};

export const CustomRepoFetchError = (message: Error): DetectError => {
  const err = new DetectError(DetectErrorCode.CUSTOM_REPO_FETCH_ERROR);
  err.level = 'error';
  err.message = message.message;
  err.stack = message.stack;
  return err;
};

export const MainRepoFetchError = (message: Error): DetectError => {
  const err = new DetectError(DetectErrorCode.MAIN_REPO_FETCH_ERROR);
  err.level = 'error';
  err.message = message.message;
  err.stack = message.stack;
  return err;
};

export const CustomRepoSaved = (): DetectError => {
  const err = new DetectError(DetectErrorCode.CUSTOM_REPO_SAVED);
  err.level = 'success';
  err.stack = undefined;
  return err;
};

export const CustomRepoDeleted = (): DetectError => {
  const err = new DetectError(DetectErrorCode.CUSTOM_REPO_DELETED);
  err.level = 'success';
  err.stack = undefined;
  return err;
};

export const CustomRepoErrorSave = (message: Error): DetectError => {
  const err = new DetectError(DetectErrorCode.CUSTOM_REPO_ERROR_SAVE);
  err.message = message.message;
  err.stack = message.stack;
  err.level = 'error';
  return err;
};

export const CustomRepoErrorDelete = (message: Error): DetectError => {
  const err = new DetectError(DetectErrorCode.CUSTOM_REPO_ERROR_DELETE);
  err.message = message.message;
  err.stack = message.stack;
  err.level = 'error';
  return err;
};

export const ForceFetchRepoError = (message: Error): DetectError => {
  const err = new DetectError(DetectErrorCode.FORCE_FETCH_REPO_ERROR);
  err.message = message.message;
  err.stack = message.stack;
  err.level = 'error';
  return err;
};

export const ForceFetchRepoSuccess = (): DetectError => {
  const err = new DetectError(DetectErrorCode.FORCE_FETCH_REPO_SUCCESS);
  err.stack = undefined;
  err.level = 'success';
  return err;
};
