import { css } from '@emotion/css';
import { Field, QueryField } from '@grafana/ui';
import React, { useContext, useEffect, useState } from 'react';
import { ConversionContext } from 'stores/conversion';

interface DatasourceQueryFieldProps {
  queries?: string;

  onChange(val: string): void;

  // Optional to override the default CSS for this class.
  className?: string;
}

interface StatefulDatasourceQueryFieldProps {
  className?: string;
}

export const DatasourceQueryField = (props: DatasourceQueryFieldProps) => {
  const fieldCSS = css`
    width: 100%;
    max-width: 100%;
    min-width: 400px;
    height: 400px;
    .slate-query-field {
      height: 350px;
    }
  `;

  return (
    <Field
      label="Generated queries"
      description="The query that was generated from the Sigma rule"
      className={props.className ? props.className : fieldCSS}
    >
      <QueryField
        query={props.queries}
        placeholder="Generated queries will appear here..."
        portalOrigin=""
        onChange={(e) => props.onChange(e)}
      />
    </Field>
  );
};

export const StatefulDatasourceQueryField = (props: StatefulDatasourceQueryFieldProps) => {
  const [queries, setQueries] = useState<string>('');

  const {
    data: { queries: stateQueries },
    operations: { setQueries: doSetQueries },
  } = useContext(ConversionContext);

  useEffect(() => {
    stateQueries.subscribe(setQueries);
  }, [stateQueries]);

  return <DatasourceQueryField {...props} queries={queries} onChange={doSetQueries} />;
};
