import React from 'react';
import {
  CustomVariable,
  PanelBuilders,
  SceneComponentProps,
  sceneGraph,
  SceneObjectBase,
  SceneObjectState,
  SplitLayout,
  VariableDependencyConfig,
} from '@grafana/scenes';
import { LogSourceOverviewScene } from './panels/LogSourceOverviewScene';
import { directoryQueryRunner, logsourceQueryRunner } from './queryRunners/directory';
import { LogSourceDisplaySingle } from './panels/logSourceDisplay';

export interface LogSourceLayoutState extends SceneObjectState {
  layout: SplitLayout;
}

const defaults: LogSourceLayoutState = {
  layout: new SplitLayout({
    direction: 'row',
    primary: PanelBuilders.text().build(),
  }),
};

const Renderer = ({ model }: SceneComponentProps<LogSourceLayout>) => {
  const { layout } = model.useState();

  return <layout.Component model={layout} />;
};

export class LogSourceLayout extends SceneObjectBase<LogSourceLayoutState> {
  public static Component = Renderer;

  _variableDependency = new VariableDependencyConfig(this, {
    variableNames: ['logsource'],
    onReferencedVariableValueChanged: (variable) => {
      this.requireSecondary();
    },
  });

  constructor(args: Partial<LogSourceLayoutState>) {
    super({
      ...defaults,
      ...args,
      layout: new SplitLayout({
        direction: 'row',
        primary: new LogSourceOverviewScene({
          $data: directoryQueryRunner(),
        }),
      }),
    });

    this.addActivationHandler(() => {
      this.requireSecondary();
      this.state.layout.state.primary.setState({ $data: directoryQueryRunner() });
    });
  }

  private requireSecondary() {
    const logsource = sceneGraph.lookupVariable('logsource', this) as CustomVariable;

    if (!logsource) {
      return;
    }

    this.state.layout.setState({
      secondary:
        (logsource.getValue() as Array<string>).length !== 1
          ? undefined
          : new LogSourceDisplaySingle({
              currentLogSource: logsource.getValue() as string,
              $data: logsourceQueryRunner(logsource.getValue() as string),
            }),
    });
  }
}
