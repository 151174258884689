import React, { ChangeEvent, useMemo } from 'react';
import {
  CustomVariable,
  SceneComponentProps,
  sceneGraph,
  SceneObjectBase,
  SceneObjectState,
  VariableDependencyConfig,
} from '@grafana/scenes';
import { DetectScenesVariables } from '../../../variables';
import { SigmaServiceApi, V1Target } from '../../../../api/sigma-api';
import { DetectServiceConfig, SigmaAPIConfig } from '../../../../shared/apiConfigs';
import { Argument } from '../../../../shared/types';
import { Field, Input, Switch } from '@grafana/ui';
import { sentenceCase } from '../../../../utils/helpers';
import { DataSourceConfigApi } from '../../../../api/detect-service';

interface ExtraArgsState extends SceneObjectState {}

interface ExtraArgsStateInternal extends ExtraArgsState {
  args: { [name: string]: Argument };
  storedValues?: { [name: string]: any };
  currentValues: { [name: string]: any };
}

export class ExtraArgs extends SceneObjectBase<ExtraArgsStateInternal> {
  private sigmaAPIClient = new SigmaServiceApi(SigmaAPIConfig);
  private datasourceApi = new DataSourceConfigApi(DetectServiceConfig);

  protected static Component = ExtraArgsRenderer;

  _variableDependency = new VariableDependencyConfig(this, {
    variableNames: [DetectScenesVariables.LogSource, DetectScenesVariables.ExtraArgs],
    onReferencedVariableValueChanged: (variable) => {
      switch (variable.state.name) {
        case DetectScenesVariables.LogSource:
          this.fetchValues();
          break;
        case DetectScenesVariables.ExtraArgs:
          this.getValues();
          break;
      }
    },
  });

  constructor(state: Partial<ExtraArgsState>) {
    super({ ...state, args: {}, currentValues: {} });
    this.addActivationHandler(this.activationHandler.bind(this));
  }

  activationHandler() {
    this.getArguments();
    this.fetchValues();
    this.getValues();
  }

  getArguments() {
    this.sigmaAPIClient.listTargets().subscribe((targets) => {
      let target = targets.targets?.reduce((e: V1Target) => {
        if (e.target === 'loki') {
          return e;
        }
        return {};
      }) as V1Target;

      this.setState({ args: target.extraArguments as { [name: string]: Argument } });
    });
  }

  fetchValues() {
    const logsource = sceneGraph.lookupVariable(DetectScenesVariables.LogSource, this)?.getValue() as string;
    this.datasourceApi.readDataSourceConfig({ id: logsource }).subscribe({
      next: (config) => {
        this.setState({
          storedValues: config.configuration?.extraArgs ? JSON.parse(config.configuration.extraArgs) : {},
        });
      },
      error: (err) => {},
    });
  }

  getValues() {
    const extraArgs = sceneGraph.lookupVariable(DetectScenesVariables.ExtraArgs, this)?.getValue() as string;
    let currentValues: { [key: string]: any } = {};
    try {
      currentValues = JSON.parse(extraArgs);
      this.setState({ currentValues });
    } catch (e) {}
  }

  updateArgument(name: string, value: any) {
    const extraArgsVariable = sceneGraph.lookupVariable(DetectScenesVariables.ExtraArgs, this) as CustomVariable;
    const extraArgs = this.state.currentValues;
    extraArgs[name] = value;

    extraArgsVariable.changeValueTo(JSON.stringify(extraArgs));
  }
}

function ExtraArgsRenderer({ model }: SceneComponentProps<ExtraArgs>) {
  const { args, currentValues, storedValues } = model.useState();

  const values = useMemo(() => {
    return Object.entries(args).reduce((a, [key, argument]) => {
      a[key] = argument.default_value;
      if (storedValues && key in storedValues) {
        a[key] = storedValues[key];
      }
      if (key in currentValues) {
        a[key] = currentValues[key];
      }
      return a;
    }, {} as { [key: string]: any });
  }, [args, storedValues, currentValues]);

  return (
    <>
      {Object.entries(args).map(([key, argument]) => {
        const label = sentenceCase(key);
        switch (argument.type) {
          case 'bool' || 'boolean':
            return (
              <Field label={label} key={key}>
                <Switch
                  value={values[key]}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => model.updateArgument(key, e.target.checked)}
                />
              </Field>
            );
          default:
            return (
              <Field label={label} key={key}>
                <Input type="text" value={values[key]} />
              </Field>
            );
        }
      })}
    </>
  );
}
