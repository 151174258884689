import React, { useContext, useEffect, useState } from 'react';
import { SelectableValue } from '@grafana/data';
import { Button } from '@grafana/ui';
import { ConversionContext } from 'stores/conversion';
import { combineLatest } from 'rxjs';

interface QueryEffectButtonProps {
  format: SelectableValue<string>;
  type: 'test-detect' | 'test-explore' | 'create-alert';
  disabled: boolean;
  onTestQuery(event: any): void;
  onCreateAlert(event: any): void;
}

interface StatefulQueryEffectButtonProps {
  type: 'test-detect' | 'test-explore' | 'create-alert';
  onCreateAlert(event: any): void;
}

export const QueryEffectButton = (props: QueryEffectButtonProps) => {
  switch (props.type) {
    case 'create-alert':
      return (
        <Button
          type="button"
          variant="secondary"
          icon="bell"
          disabled={props.disabled}
          onClick={(e) => props.onCreateAlert(e)}
        >
          Create Alert
        </Button>
      );
    default:
      return (
        <Button
          type="button"
          variant="secondary"
          icon="search"
          disabled={props.disabled}
          onClick={(e) => props.onTestQuery(e)}
        >
          {props.type === 'test-explore' ? 'Explore Query' : 'Test Query'}
        </Button>
      );
  }
};

// TODO: refactor - we may no longer require this to be stateful
export const StatefulQueryEffectButton = (props: StatefulQueryEffectButtonProps) => {
  const [format, setFormat] = useState<SelectableValue<string>>({ label: 'Plain Loki queries', value: 'default' });
  const [disabled, setDisabled] = useState<boolean>(true);

  const {
    data: { format: stateFormat, queries: stateQueries, datasource: stateDatasource, queryResults: stateQueryResults },
    operations: { testQuery },
  } = useContext(ConversionContext);

  // Effect for state subscription
  useEffect(() => {
    stateFormat.subscribe(setFormat);
    // Combine latest ensures that all observables provide a value (through next) before emitting
    combineLatest({
      stateQueries,
      stateDatasource,
      stateQueryResults,
    }).subscribe((e) => {
      setDisabled(!e.stateQueries || !e.stateDatasource);
    });
  }, [stateFormat, stateQueries, stateDatasource, stateQueryResults]);

  return (
    <QueryEffectButton
      {...props}
      format={format}
      disabled={disabled}
      onTestQuery={() => {
        testQuery(props.type === 'test-explore');
      }}
    />
  );
};
