import * as React from 'react';
import { AppRootProps } from '@grafana/data';
import { Global, css } from '@emotion/react';
import Converter from '../components/Converter';
import { PluginPage } from '@grafana/runtime';
import { Alert, ErrorBoundary, TabContent } from '@grafana/ui';
import { DetectErrorDisplay } from 'components/DetectErrorDisplay';
import { PLUGIN_ID } from 'shared/constants';

export function Sigma(props: AppRootProps) {
  return (
    <PluginPage
      pageNav={{
        text: 'Sigma Converter',
        img: `public/plugins/${PLUGIN_ID}/img/Sigma_icon.png`,
        subTitle: 'Convert Sigma rules into queries',
        hideFromBreadcrumbs: true,
      }}
    >
      <Global
        styles={css`
          .slate-query-field {
            font-family: Roboto Mono, monospace;
            font-size: 14px;
            overflow: auto;
            word-break: break-word;
          }
        `}
      />
      <TabContent>
        <ErrorBoundary>
          {({ error }) => {
            if (error) {
              return <Alert title={error.message} onRemove={() => window.location.reload()} buttonContent="Reload" />;
            }
            return (
              <>
                <DetectErrorDisplay />
                <Converter />
              </>
            );
          }}
        </ErrorBoundary>
      </TabContent>
    </PluginPage>
  );
}
