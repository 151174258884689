import React from 'react';
import {
  CustomVariable,
  SceneComponentProps,
  sceneGraph,
  SceneObjectBase,
  SceneObjectState,
  VariableDependencyConfig,
} from '@grafana/scenes';
import { Field, MultiSelect } from '@grafana/ui';
import { PipelineApi } from '../../../../api/detect-service';
import { DetectServiceConfig } from '../../../../shared/apiConfigs';
import { AppEvents, SelectableValue } from '@grafana/data';
import { DetectScenesVariables } from '../../../variables';
import { getAppEvents } from '@grafana/runtime';

interface CustomPipelinesState extends SceneObjectState {}

interface CustomPipelinesStateInternal extends CustomPipelinesState {
  options: Array<SelectableValue<string>>;
  value: Array<SelectableValue<string>>;
}

export class CustomPipelines extends SceneObjectBase<CustomPipelinesStateInternal> {
  protected static Component = CustomPipelinesRenderer;

  private datasourceApi = new PipelineApi(DetectServiceConfig);
  protected _variableDependency = new VariableDependencyConfig(this, {
    variableNames: [DetectScenesVariables.CustomPipelines],
    onReferencedVariableValueChanged: (variable) => {
      this.getSelection();
    },
  });

  constructor(state: Partial<CustomPipelinesState>) {
    super({ ...state, options: [], value: [] });
    this.addActivationHandler(this.activationHandler.bind(this));
  }

  activationHandler() {
    this.getSelection();
    this.datasourceApi.listPipeline({ page: 1, itemsPerPage: 100 }).subscribe({
      next: (pipelines) => {
        this.setState({
          options: pipelines.data.map((pipeline) => {
            return {
              value: pipeline.id,
              label: pipeline.name,
            };
          }),
        });
      },
      error: (err) => {
        getAppEvents().publish({
          type: AppEvents.alertError.name,
          payload: [`Error fetching pipelines (${err.code})`],
        });
        this.setState({
          options: [],
        });
      },
    });
  }

  updateSelection(value: Array<SelectableValue<string>>) {
    const pipelinesVar = sceneGraph.lookupVariable(DetectScenesVariables.CustomPipelines, this) as CustomVariable;
    const updateVal = value.filter((e) => !!e.value).map((e) => e.value ?? '');
    pipelinesVar.changeValueTo(updateVal);
  }

  getSelection() {
    const pipelinesVar = sceneGraph.lookupVariable(DetectScenesVariables.CustomPipelines, this) as CustomVariable;
    const value = pipelinesVar.getValue();

    if (Array.isArray(value)) {
      this.setState({
        value: value.filter((e) => e !== '').map((e) => ({ value: e.toString(), label: e.toString() })),
      });
    } else if (value !== '') {
      this.setState({
        value: [{ value: value.toString(), label: value.toString() }],
      });
    } else {
      this.setState({
        value: [],
      });
    }
  }
}

function CustomPipelinesRenderer({ model }: SceneComponentProps<CustomPipelines>) {
  const { options, value } = model.useState();

  return (
    <Field label="Custom Pipelines">
      <MultiSelect onChange={(val) => model.updateSelection(val)} options={options} value={value} />
    </Field>
  );
}
