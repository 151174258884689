// tslint:disable
/**
 * Detect service API.
 * The Detect service exposes an HTTP API to do actions within the plugin like saving pipelines, conversions of rules, and manage external rule sources.
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: security-operations@grafana.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Observable } from 'rxjs';
import type { AjaxResponse } from 'rxjs/ajax';
import { BaseAPI, throwIfNullOrUndefined, encodeURI } from '../runtime';
import type { OperationOpts, HttpHeaders, HttpQuery } from '../runtime';
import type {
  ConversionCreateModel,
  ConversionDataSourceConfigReadModel,
  ConversionReadModel,
  ConversionUpdateModel,
  CreateConversionRequestModel,
  ListConversion200ResponseModel,
  ListConversion400ResponseModel,
  ListConversionCustomPipelines200ResponseModel,
  ListConversionSigmaRules200ResponseModel,
  UpdateConversionRequestModel,
} from '../models';

export interface CreateConversionRequest {
  createConversionRequestModel: CreateConversionRequestModel;
}

export interface DeleteConversionRequest {
  id: string;
}

export interface ListConversionRequest {
  page?: number;
  itemsPerPage?: number;
  startTime?: string;
  endTime?: string;
}

export interface ListConversionCustomPipelinesRequest {
  id: string;
  page?: number;
  itemsPerPage?: number;
  startTime?: string;
  endTime?: string;
}

export interface ListConversionSigmaRulesRequest {
  id: string;
  page?: number;
  itemsPerPage?: number;
  startTime?: string;
  endTime?: string;
}

export interface ReadConversionRequest {
  id: string;
}

export interface ReadConversionDataSourceConfigRequest {
  id: string;
}

export interface UpdateConversionRequest {
  id: string;
  updateConversionRequestModel: UpdateConversionRequestModel;
}

/**
 * no description
 */
export class ConversionApi extends BaseAPI {
  /**
   * Creates a new Conversion and persists it to storage.
   * Create a new Conversion
   */
  createConversion({ createConversionRequestModel }: CreateConversionRequest): Observable<ConversionCreateModel>;
  createConversion(
    { createConversionRequestModel }: CreateConversionRequest,
    opts?: OperationOpts
  ): Observable<AjaxResponse<ConversionCreateModel>>;
  createConversion(
    { createConversionRequestModel }: CreateConversionRequest,
    opts?: OperationOpts
  ): Observable<ConversionCreateModel | AjaxResponse<ConversionCreateModel>> {
    throwIfNullOrUndefined(createConversionRequestModel, 'createConversionRequestModel', 'createConversion');

    const headers: HttpHeaders = {
      'Content-Type': 'application/json',
    };

    return this.request<ConversionCreateModel>(
      {
        url: '/conversions',
        method: 'POST',
        headers,
        body: createConversionRequestModel,
      },
      opts?.responseOpts
    );
  }

  /**
   * Deletes the Conversion with the requested ID.
   * Deletes a Conversion by ID
   */
  deleteConversion({ id }: DeleteConversionRequest): Observable<void>;
  deleteConversion({ id }: DeleteConversionRequest, opts?: OperationOpts): Observable<void | AjaxResponse<void>>;
  deleteConversion({ id }: DeleteConversionRequest, opts?: OperationOpts): Observable<void | AjaxResponse<void>> {
    throwIfNullOrUndefined(id, 'id', 'deleteConversion');

    return this.request<void>(
      {
        url: '/conversions/{id}'.replace('{id}', encodeURI(id)),
        method: 'DELETE',
      },
      opts?.responseOpts
    );
  }

  /**
   * List Conversions.
   * List Conversions
   */
  listConversion({
    page,
    itemsPerPage,
    startTime,
    endTime,
  }: ListConversionRequest): Observable<ListConversion200ResponseModel>;
  listConversion(
    { page, itemsPerPage, startTime, endTime }: ListConversionRequest,
    opts?: OperationOpts
  ): Observable<AjaxResponse<ListConversion200ResponseModel>>;
  listConversion(
    { page, itemsPerPage, startTime, endTime }: ListConversionRequest,
    opts?: OperationOpts
  ): Observable<ListConversion200ResponseModel | AjaxResponse<ListConversion200ResponseModel>> {
    const query: HttpQuery = {};

    if (page != null) {
      query['page'] = page;
    }
    if (itemsPerPage != null) {
      query['itemsPerPage'] = itemsPerPage;
    }
    if (startTime != null) {
      query['startTime'] = (startTime as any).toISOString();
    }
    if (endTime != null) {
      query['endTime'] = (endTime as any).toISOString();
    }

    return this.request<ListConversion200ResponseModel>(
      {
        url: '/conversions',
        method: 'GET',
        query,
      },
      opts?.responseOpts
    );
  }

  /**
   * List attached CustomPipelines.
   * List attached CustomPipelines
   */
  listConversionCustomPipelines({
    id,
    page,
    itemsPerPage,
    startTime,
    endTime,
  }: ListConversionCustomPipelinesRequest): Observable<ListConversionCustomPipelines200ResponseModel>;
  listConversionCustomPipelines(
    { id, page, itemsPerPage, startTime, endTime }: ListConversionCustomPipelinesRequest,
    opts?: OperationOpts
  ): Observable<AjaxResponse<ListConversionCustomPipelines200ResponseModel>>;
  listConversionCustomPipelines(
    { id, page, itemsPerPage, startTime, endTime }: ListConversionCustomPipelinesRequest,
    opts?: OperationOpts
  ): Observable<
    ListConversionCustomPipelines200ResponseModel | AjaxResponse<ListConversionCustomPipelines200ResponseModel>
  > {
    throwIfNullOrUndefined(id, 'id', 'listConversionCustomPipelines');

    const query: HttpQuery = {};

    if (page != null) {
      query['page'] = page;
    }
    if (itemsPerPage != null) {
      query['itemsPerPage'] = itemsPerPage;
    }
    if (startTime != null) {
      query['startTime'] = (startTime as any).toISOString();
    }
    if (endTime != null) {
      query['endTime'] = (endTime as any).toISOString();
    }

    return this.request<ListConversionCustomPipelines200ResponseModel>(
      {
        url: '/conversions/{id}/custom-pipelines'.replace('{id}', encodeURI(id)),
        method: 'GET',
        query,
      },
      opts?.responseOpts
    );
  }

  /**
   * List attached SigmaRules.
   * List attached SigmaRules
   */
  listConversionSigmaRules({
    id,
    page,
    itemsPerPage,
    startTime,
    endTime,
  }: ListConversionSigmaRulesRequest): Observable<ListConversionSigmaRules200ResponseModel>;
  listConversionSigmaRules(
    { id, page, itemsPerPage, startTime, endTime }: ListConversionSigmaRulesRequest,
    opts?: OperationOpts
  ): Observable<AjaxResponse<ListConversionSigmaRules200ResponseModel>>;
  listConversionSigmaRules(
    { id, page, itemsPerPage, startTime, endTime }: ListConversionSigmaRulesRequest,
    opts?: OperationOpts
  ): Observable<ListConversionSigmaRules200ResponseModel | AjaxResponse<ListConversionSigmaRules200ResponseModel>> {
    throwIfNullOrUndefined(id, 'id', 'listConversionSigmaRules');

    const query: HttpQuery = {};

    if (page != null) {
      query['page'] = page;
    }
    if (itemsPerPage != null) {
      query['itemsPerPage'] = itemsPerPage;
    }
    if (startTime != null) {
      query['startTime'] = (startTime as any).toISOString();
    }
    if (endTime != null) {
      query['endTime'] = (endTime as any).toISOString();
    }

    return this.request<ListConversionSigmaRules200ResponseModel>(
      {
        url: '/conversions/{id}/sigma-rules'.replace('{id}', encodeURI(id)),
        method: 'GET',
        query,
      },
      opts?.responseOpts
    );
  }

  /**
   * Finds the Conversion with the requested ID and returns it.
   * Find a Conversion by ID
   */
  readConversion({ id }: ReadConversionRequest): Observable<ConversionReadModel>;
  readConversion({ id }: ReadConversionRequest, opts?: OperationOpts): Observable<AjaxResponse<ConversionReadModel>>;
  readConversion(
    { id }: ReadConversionRequest,
    opts?: OperationOpts
  ): Observable<ConversionReadModel | AjaxResponse<ConversionReadModel>> {
    throwIfNullOrUndefined(id, 'id', 'readConversion');

    return this.request<ConversionReadModel>(
      {
        url: '/conversions/{id}'.replace('{id}', encodeURI(id)),
        method: 'GET',
      },
      opts?.responseOpts
    );
  }

  /**
   * Find the attached DataSourceConfig of the Conversion with the given ID
   * Find the attached DataSourceConfig
   */
  readConversionDataSourceConfig({
    id,
  }: ReadConversionDataSourceConfigRequest): Observable<ConversionDataSourceConfigReadModel>;
  readConversionDataSourceConfig(
    { id }: ReadConversionDataSourceConfigRequest,
    opts?: OperationOpts
  ): Observable<AjaxResponse<ConversionDataSourceConfigReadModel>>;
  readConversionDataSourceConfig(
    { id }: ReadConversionDataSourceConfigRequest,
    opts?: OperationOpts
  ): Observable<ConversionDataSourceConfigReadModel | AjaxResponse<ConversionDataSourceConfigReadModel>> {
    throwIfNullOrUndefined(id, 'id', 'readConversionDataSourceConfig');

    return this.request<ConversionDataSourceConfigReadModel>(
      {
        url: '/conversions/{id}/data-source-config'.replace('{id}', encodeURI(id)),
        method: 'GET',
      },
      opts?.responseOpts
    );
  }

  /**
   * Updates a Conversion and persists changes to storage.
   * Updates a Conversion
   */
  updateConversion({ id, updateConversionRequestModel }: UpdateConversionRequest): Observable<ConversionUpdateModel>;
  updateConversion(
    { id, updateConversionRequestModel }: UpdateConversionRequest,
    opts?: OperationOpts
  ): Observable<AjaxResponse<ConversionUpdateModel>>;
  updateConversion(
    { id, updateConversionRequestModel }: UpdateConversionRequest,
    opts?: OperationOpts
  ): Observable<ConversionUpdateModel | AjaxResponse<ConversionUpdateModel>> {
    throwIfNullOrUndefined(id, 'id', 'updateConversion');
    throwIfNullOrUndefined(updateConversionRequestModel, 'updateConversionRequestModel', 'updateConversion');

    const headers: HttpHeaders = {
      'Content-Type': 'application/json',
    };

    return this.request<ConversionUpdateModel>(
      {
        url: '/conversions/{id}'.replace('{id}', encodeURI(id)),
        method: 'PATCH',
        headers,
        body: updateConversionRequestModel,
      },
      opts?.responseOpts
    );
  }
}
