import React from 'react';
import {
  CustomVariable,
  SceneComponentProps,
  sceneGraph,
  SceneObjectBase,
  SceneObjectState,
  VariableDependencyConfig,
} from '@grafana/scenes';
import { Field, Input } from '@grafana/ui';
import { QueryEditor as QueryEditor2 } from '../../../../components/scenes/logsource/queryEditor';
import { DetectScenesVariables } from '../../../variables';
import { LokiConfiguration } from '../../../../components/scenes/logsource/queries/types';
import { render } from '../../../../components/scenes/logsource/queries/loki';
import { DetectEvent, DetectEventTypes } from '../../../events';
import { getDataSourceSrv } from '@grafana/runtime';

interface QueryEditorState extends SceneObjectState {}

interface QueryEditorStateInternal extends QueryEditorState {
  query: string;
  datasource?: string;
}

export class QueryEditor extends SceneObjectBase<QueryEditorStateInternal> {
  protected static Component = QueryEditorRenderer;

  _variableDependency = new VariableDependencyConfig(this, {
    variableNames: [DetectScenesVariables.LogSourceQuery, DetectScenesVariables.LogSourceDataSource],
    onReferencedVariableValueChanged: (variable) => {
      switch (variable.state.name) {
        case DetectScenesVariables.LogSourceQuery:
          break;
        case DetectScenesVariables.LogSourceDataSource:
          this.datasourceActivationHandler();
          break;
      }
    },
  });

  constructor(state: Partial<QueryEditorState>) {
    super({ ...state, query: '{job=~".+"}' });
    this.addActivationHandler(this.queryActivationHandler.bind(this));
    this.addActivationHandler(this.datasourceActivationHandler.bind(this));
    this.addActivationHandler(() => {
      this.getRoot().subscribeToEvent(DetectEvent, (evt) => {
        switch (evt.payload.type) {
          case DetectEventTypes.EditorUpdateQuery:
            this.setState({ query: render(evt.payload.payload as unknown as LokiConfiguration) });
            this.update(evt.payload.payload as unknown as LokiConfiguration);
            break;
        }
      });
    });
  }

  queryActivationHandler() {
    const queryVariable = sceneGraph.lookupVariable(DetectScenesVariables.LogSourceQuery, this) as CustomVariable;
    if (queryVariable.getValue().toString() !== '') {
      this.setState({ query: queryVariable.getValue().toString() });
    } else {
      queryVariable.changeValueTo(this.state.query);
    }
  }

  datasourceActivationHandler() {
    const datasourceVariable = sceneGraph.lookupVariable(
      DetectScenesVariables.LogSourceDataSource,
      this
    ) as CustomVariable;
    if (datasourceVariable.getValue().toString() !== '') {
      this.setState({ datasource: datasourceVariable.getValue().toString() });
    } else {
      const datasource = getDataSourceSrv().getList({
        type: 'loki',
        pluginId: 'loki',
      });
      if (datasource.length) {
        this.setState({ datasource: datasource[0].uid });
        datasourceVariable.changeValueTo(datasource[0].uid);
      }
      this.setState({ datasource: undefined });
    }
  }

  update(value: LokiConfiguration) {
    const queryVariable = sceneGraph.lookupVariable(DetectScenesVariables.LogSourceQuery, this) as CustomVariable;

    queryVariable.changeValueTo(render(value));
  }
}

function QueryEditorRenderer({ model }: SceneComponentProps<QueryEditor>) {
  const { query, datasource } = model.useState();

  return (
    <Field label="Query Editor">
      <QueryEditor2 embedded query={query} datasource={datasource} onChange={model.update.bind(model)} />
    </Field>
  );
}
