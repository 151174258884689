import React from 'react';
import { LabelValue } from '../../types';
import { useStyles2 } from '@grafana/ui';
import { css } from '@emotion/css';
import { Label } from './Label';

const getStyles = () => {
  return {
    wrapper: css`
      width: 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
    `,
  };
};

export interface LabelListProps {
  labels: Array<LabelValue>;
}

export const LabelList = (props: LabelListProps) => {
  const styles = useStyles2(getStyles);

  return (
    <div className={styles.wrapper}>
      {props.labels
        .filter((l) => parseInt(l.value, 10) > 0)
        .sort((a, b) => (a.label === 'Total' ? -1 : parseInt(b.value, 10) - parseInt(a.value, 10)))
        .map((label) => (
          <Label key={label.label} label={label.label} value={label.value} />
        ))}
    </div>
  );
};
