import React, { useContext, useEffect, useState } from 'react';
import { Button } from '@grafana/ui';
import { SigmaRulesContext } from '../../stores/sigmarules';

interface RuleEditorButtonSaveProps {
  active?: boolean;

  onClick(): void;
}

export const RuleEditorButtonSave = (props: RuleEditorButtonSaveProps) => {
  return (
    <Button icon="save" onClick={props.onClick} disabled={!props.active} variant="primary">
      Save
    </Button>
  );
};

export const StatefulRuleEditorButtonSave = () => {
  const [dirty, setDirty] = useState<boolean>(false);

  const {
    data: { createModel },
    operations: { create },
  } = useContext(SigmaRulesContext);

  /**
   * We use this effect to detect when the model has changed and set the flag to dirty
   * this enables us to know when the user has changed something and the Save button should
   * be enabled.
   */
  useEffect(() => {
    createModel.subscribe((model) => {
      setDirty(model.content !== '' && model.filename !== '');
    });
  }, [createModel]);

  return <RuleEditorButtonSave onClick={create} active={dirty} />;
};
