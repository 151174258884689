import React from 'react';
import { CodeEditor, Field, Input, useStyles2 } from '@grafana/ui';
import { css } from '@emotion/css';
import { FileRow } from '../types';

export interface FilePanelProps {
  row: FileRow;
}

const getStyles = () => ({
  wrapper: css`
    max-height: 200px;
  `,
});

export const FilePanel = (props: FilePanelProps) => {
  const { row } = props;
  const styles = useStyles2(getStyles);

  return (
    <div style={{ overflowY: 'scroll', height: '100%' }}>
      <Field label={'Filename'}>
        <Input value={row[0]} readOnly name="filename" />
      </Field>
      <Field label="Rule Source">
        <Input value={row[1]} readOnly name="source" />
      </Field>
      <Field label="Created">
        <Input value={new Date(row[2]).toLocaleString()} readOnly name="created" />
      </Field>
      <Field label="Last Updated">
        <Input value={new Date(row[3]).toLocaleString()} readOnly name="updated" />
      </Field>
      <Field label="Content">
        <div className={styles.wrapper}>
          <CodeEditor
            value={row[4]}
            onChange={() => {}}
            readOnly
            language={'yaml'}
            height="400px"
            monacoOptions={{
              wordWrap: 'on',
              minimap: {
                enabled: false,
              },
            }}
          />
        </div>
      </Field>
    </div>
  );
};
