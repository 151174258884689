import React, { useContext, useEffect } from 'react';
import { PluginPage } from '@grafana/runtime';
import { Alert, Label, Stack, useStyles2 } from '@grafana/ui';
import { useQueryParams } from 'hooks/useQueryParams';
import { css } from '@emotion/css';
import { GrafanaTheme2 } from '@grafana/data';
import { StatefulPipelineEditorMenu } from 'components/pipelineEditor/PipelineMenu';
import { PipelineFormCreate } from 'components/pipelineEditor/PipelineFormCreate';
import { PipelineFormUpdate } from 'components/pipelineEditor/PipelineFormUpdate';
import { DetectErrorDisplay } from 'components/DetectErrorDisplay';
import { useMonaco } from '@monaco-editor/react';
import { suggestions } from 'utils/autocomplete/pipelines';
import { EnvironmentContext } from 'stores/environment';
import { PipelineContext } from 'stores';

const getTheme = (theme: GrafanaTheme2) => ({
  containerBackground: css`
    background-color: ${theme.colors.background.secondary};
    border-radius: ${theme.shape.radius.default};
    padding: ${theme.spacing(3)};
    width: 100%;
  `,
  label: css`
    color: ${theme.colors.text.secondary};
  `,
});

export const PipelineEditor = () => {
  const [qs, _] = useQueryParams();
  const styles = useStyles2(getTheme);
  const { servicePresent } = useContext(EnvironmentContext);
  const {
    operations: { list: doListPipelines },
  } = useContext(PipelineContext);
  const monaco = useMonaco();

  useEffect(() => {
    if (!monaco) {
      return;
    }
    monaco.languages.registerCompletionItemProvider('yaml', {
      provideCompletionItems: (model, pos) => {
        return {
          suggestions: suggestions(model, pos),
          incomplete: true,
        };
      },
    });
  }, [monaco]);

  useEffect(() => {
    // We set a timeout to prevent the list from being called before the remote is set
    setTimeout(() => {
      doListPipelines(1);
    }, 300);
  }, [doListPipelines]);

  return (
    <PluginPage subTitle={'Configure Pipelines to use with Sigma Rules'}>
      <DetectErrorDisplay />
      {!servicePresent ? (
        <Alert severity="info" title="Missing Backend">
          Pipelines are only saved locally and cannot be shared
        </Alert>
      ) : null}
      <div style={{ display: 'flex' }}>
        <StatefulPipelineEditorMenu />
        <div className={styles.containerBackground}>
          <Stack direction="column">
            {!qs.has('create') && !qs.has('pipeline') ? (
              <div>
                <h3 style={{ width: '100%', marginBottom: '0' }}>Editor</h3>
                <Label className={styles.label}>Select a pipeline to start editing</Label>
              </div>
            ) : null}
            {qs.has('create') ? <PipelineFormCreate /> : null}
            {qs.has('pipeline') ? <PipelineFormUpdate /> : null}
          </Stack>
        </div>
      </div>
    </PluginPage>
  );
};
