import React from 'react';
import { SceneComponentProps, SceneObjectBase, SceneObjectState, sceneGraph } from '@grafana/scenes';
import { textUtil } from '@grafana/data';
import { Button, ButtonVariant } from '@grafana/ui';

interface ButtonState extends SceneObjectState {
  locationTemplate: string;
  text: string;
  variant: ButtonVariant;
  disabled?: boolean;
  tooltip?: string;
  currentTab?: boolean;
}

export class GenericButton extends SceneObjectBase<ButtonState> {
  public constructor(state?: Partial<ButtonState>) {
    super({
      locationTemplate: '',
      text: 'Button',
      variant: 'primary',
      ...state,
    });
  }

  public onClick = () => {
    const search = sceneGraph.interpolate(this, this.state.locationTemplate);
    window.open(textUtil.sanitizeUrl(search), !!this.state.currentTab ? '_self' : '_blank');
  };

  static Component = GenericButtonRenderer;
}

function GenericButtonRenderer({ model }: SceneComponentProps<GenericButton>) {
  const { text, variant, disabled, tooltip } = model.useState();

  return (
    <Button variant={variant} onClick={model.onClick} disabled={disabled} tooltip={tooltip}>
      {text}
    </Button>
  );
}
