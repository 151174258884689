import { load } from 'js-yaml';
import { useCallback } from 'react';
import Ajv from 'ajv';

const pipelineSchema = {
  $id: '/Pipeline',
  type: 'object',
  properties: {
    name: {
      type: 'string',
    },
    priority: {
      type: 'integer',
    },
    transformations: {
      type: 'array',
      items: { $ref: '/Pipeline/Transformation' },
      minItems: 1,
    },
  },
  required: ['transformations'],
};

const transformationSchema = {
  $id: '/Pipeline/Transformation',
  type: 'object',
  properties: {
    id: {
      type: 'string',
    },
    type: {
      type: 'string',
    },
    //eslint-disable-next-line camelcase
    rule_conditions: {
      type: 'array',
    },
  },
  required: ['type'],
  additionalProperties: true,
};

export const useValidate = () => {
  const validate = useCallback((pipeline: string): boolean => {
    const ajv = new Ajv({ schemas: [pipelineSchema, transformationSchema] });
    const p = load(pipeline, {
      json: false,
    });
    let result: boolean;
    try {
      result = ajv.validate('/Pipeline', p);
    } catch (e) {
      result = false;
    }

    return result;
  }, []);

  return [validate] as const;
};
