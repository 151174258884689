import { AppPlugin } from '@grafana/data';
import { App } from './pages/App';
import { AppConfig, DetectSettings } from './pages/AppConfig';
import { i18nInitialize } from 'utils/i18n';

i18nInitialize();

export const plugin = new AppPlugin<DetectSettings>().setRootPage(App).addConfigPage({
  title: 'Configuration',
  icon: 'cog',
  body: AppConfig,
  id: 'configuration',
});
