import { useContext, useEffect, useState } from 'react';
import { GitHubContext } from 'contexts/github';
import { getBackendSrv } from '@grafana/runtime';
import { PLUGIN_ID } from 'shared/constants';

export const useFetchBranches = (filepath: string, sourceId = 'grafana-detect') => {
  const githubConfig = useContext(GitHubContext);
  const [branches, setBranches] = useState<Array<string>>([]);
  useEffect(() => {
    getBackendSrv()
      .get<Array<string>>(
        `/api/plugins/${PLUGIN_ID}/resources/v1/sources.GetVersions?source_id=${sourceId}&file=${filepath}`
      )
      .then((d) => {
        setBranches(d);
      });
  }, [filepath, sourceId, githubConfig]);

  return [branches] as const;
};
